import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './Layout.scss';

const Layout = ({ isWide, children, className }) => {
  const layoutClasses = classNames('Layout', className, {
    'Layout--wide': isWide,
  });

  return <div className={layoutClasses}>{children}</div>;
};

Layout.propTypes = {
  isWide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Layout;
