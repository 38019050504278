const options = {
  segmentsList: [
    'Author',
    'Blogger',
    'Developer',
    'Entrepreneur',
    'Freelancer',
    'Journalist',
    'Lawyer',
    'Marketer',
    'Researcher',
    'Student',
    'Teacher',
    'Other',
  ],
  defaultSegment: 'Journalist',
};

module.exports = options;
