import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InputText } from '@ventureharbour/serene-shared-components';

import theme from 'style/theme';

let timeout = null;

const InviteEmailList = ({
  emails,
  maxEmails,
  handleInputChange,
  handleAddedEmail,
  darkTheme = false,
  isScreen,
}) => {
  const [email, setEmail] = useState(emails.map(email => email));

  useEffect(() => {
    setEmail(emails);
  }, [emails]);

  const temporarilyHandleInput = (e, i) => {
    email[i].value = e.target.value;
    email[i].isValid = true;

    if (timeout) {
      clearTimeout(timeout);
    }

    // Save/validate after 1s of inactivity
    timeout = setTimeout(() => {
      handleInputChange(email, i);
    }, 1000);

    setEmail([...email]);
  };

  const emailListRef = createRef();

  const addEmailField = async () => {
    const { current } = emailListRef;
    const emailLength = emails.length;

    if (emailLength < maxEmails) {
      await handleAddedEmail();

      // scroll to the bottom of the list.
      current.scrollBy({
        top: current.scrollHeight + 100,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const numberInvalidEmails = emails.filter(email => !email.isValid).length;

  return (
    <div className="InviteEmailList">
      <ul ref={emailListRef}>
        {emails.map((email, i) => (
          <li key={i}>
            <InputText
              value={email.value}
              onChange={e => temporarilyHandleInput(e, i)}
              placeholder="name@email.com"
              invalid={!email.isValid && email.value !== ''}
            />
          </li>
        ))}
      </ul>

      <div className="button-holder">
        {emails.length < maxEmails && (
          <div onClick={addEmailField} className="add-more">
            <span>+</span> Add People
          </div>
        )}
      </div>

      {numberInvalidEmails > 0 && (
        <div className="Input__validation-message">
          {numberInvalidEmails} invalid email address
          {numberInvalidEmails > 1 && 'es'}
        </div>
      )}

      <style jsx>
        {`
          .InviteEmailList {
            position: relative;
          }

          ul {
            margin-top: 20px;
            margin-bottom: 15px;
            height: 160px;
            overflow-y: scroll;
          }

          ul::-webkit-scrollbar {
            width: 8px;
          }

          ul::-webkit-scrollbar-track {
            background: none;
          }

          ul::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.25);
          }

          .add-more {
            color: ${darkTheme ? theme.color.bodyColor : theme.color.white};
            font-size: 16px;
            font-weight: bold;
            text-align: left;
          }

          .Input__validation-message {
            font-weight: bold;
            bottom: 0;
            right: 0;
            font-size: 14px;
          }

          .Input__validation-message::before {
            left: 50%;
          }

          .add-more span {
            font-size: 30px;
            font-weight: normal;
            position: relative;
            top: 1px;
            margin-right: 5px;
          }

          .button-holder {
            min-height: 35px;
          }
        `}
      </style>
      <style jsx global>
        {`
          .InviteEmailList .Input {
            background-color: rgba(0, 0, 0, 0.1);
            color: ${darkTheme ? '#2D3846' : theme.color.white};
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .InviteEmailList .Input::placeholder {
            color: ${darkTheme
              ? 'rgba(45, 56, 70, 0.26)'
              : theme.color.placeholderWhite};
          }

          .InviteEmailList .Input:focus,
          .InviteEmailList .Input:hover {
            background-color: rgba(0, 0, 0, 0.18);
            box-shadow: 0 0 11px -2px #eb7070;
          }

          .InviteEmailList .Input,
          .InviteEmailList .Input__wrapper,
          .InviteEmailList .Button {
            width: 100%;
          }

          .InviteEmailList li:nth-last-child(n + 2) .Input__wrapper {
            margin-bottom: 12px;
          }
        `}
      </style>
    </div>
  );
};

InviteEmailList.propTypes = {
  maxEmails: PropTypes.number,
  emails: PropTypes.arrayOf(PropTypes.object),
  handleAddedEmail: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool,
  isScreen: PropTypes.bool,
};

export default InviteEmailList;
