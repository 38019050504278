import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SessionDropTarget from './SessionDropTarget.component';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal.component';
import Modal from 'components/Shared/Modal/Modal.component';

import isElementOverflown from 'helpers/layout.helper';

import './SessionList.scss';

const SessionList = ({
  sessions,
  onEditSession,
  onRemoveSession,
  onReindexSession,
  onStopEditingSession,
  dragDisabled,
}) => {
  const [modal, setModal] = useState({
    isOpen: false,
    sessionToRemove: '',
  });

  const [top, setTop] = useState(true);
  const [bottom, setBottom] = useState(false);
  const [hasOverflow, setOverflow] = useState(false);

  const containerRef = createRef();

  useEffect(() => {
    setOverflow(isElementOverflown(containerRef.current));
  }, [containerRef]);

  function onEditClicked(number) {
    const sessionToEdit = sessions.find(session => session.number === number);

    if (!sessionToEdit) {
      return;
    }

    onEditSession(sessionToEdit);
  }

  function onRemoveClicked(sessionIdToRemove) {
    setModal({ isOpen: true, sessionIdToRemove });
  }

  function handleOkBtn() {
    onRemoveSession(modal.sessionIdToRemove);
    closeModal();
  }

  function closeModal() {
    setModal({ isOpen: false, sessionIdToRemove: '' });
  }

  function reindex(targetNumber, element) {
    onReindexSession(targetNumber, element);
  }

  const handleScroll = e => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    // if scrolled beyond 10px add class SessionList--top to wrapper
    if (scrollTop > 10) {
      setTop(false);
    } else {
      setTop(true);
    }

    // if at bottom add bottom class
    if (scrollHeight - scrollTop === clientHeight) {
      setBottom(true);
    } else {
      setBottom(false);
    }
  };

  const isHoverActive = !!(onEditSession && onRemoveSession);

  const SessionListClass = classnames('SessionList', {
    'SessionList--top': !top,
    'SessionList--bottom': !bottom,
    'SessionList--overflow': hasOverflow,
  });

  return (
    <div
      className={SessionListClass}
      onScroll={handleScroll}
      ref={containerRef}
    >
      <Modal open={modal.isOpen} onCloseModal={closeModal}>
        <ConfirmationModal
          open={modal.isOpen}
          title="Are you sure you want to remove this session?"
          okTitle="Remove session"
          okAction={handleOkBtn}
          cancelAction={closeModal}
        />
      </Modal>

      {sessions.map(session => (
        <SessionDropTarget
          dragDisabled={dragDisabled ? true : session.isFinished}
          key={session.number}
          onEditClicked={onEditClicked}
          onRemoveClicked={onRemoveClicked}
          onStopEditingSession={onStopEditingSession}
          isHoverActive={isHoverActive}
          reindexElement={reindex}
          session={session}
        />
      ))}
    </div>
  );
};

SessionList.propTypes = {
  onEditSession: PropTypes.func,
  onRemoveSession: PropTypes.func,
  onStopEditingSession: PropTypes.func,
  onReindexSession: PropTypes.func,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      startingFrom: PropTypes.string,
      isActive: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  dragDisabled: PropTypes.bool,
};

export default SessionList;
