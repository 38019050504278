import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmail } from 'validator';

import { InputText } from '@ventureharbour/serene-shared-components';

import Analytics from 'helpers/universal-analytics.helper';

import { sendReferralEmail } from '../../helpers/firebase.helper';
import {
  INITIAL,
  SEND_EMAIL,
  PREPARE_EMAIL,
  EMAIL_SENT,
} from 'constants/sendRefEmailStatuses';

import {
  ACT_CLICK,
  CAT_REFERRAL,
  LAB_REFERRAL_SEND_EMAIL_TO_FRIEND,
} from 'constants/analytics';

import { USER_EMAIL } from 'messages/available-settings';

import './SendEmail.scss';

const { ipcRenderer } = window.require('electron');

const { IPC_GET_SETTINGS } = require('messages/ipc-messages');

class SendEmail extends React.Component {
  static propTypes = {
    small: PropTypes.bool,
    onChange: PropTypes.func,
  };

  state = {
    referralEmail: '',
    loading: false,
    sendEmailStatus: INITIAL,
    error: false,
    isEmailValid: false,
    formSubmitted: false,
    code: '',
    senderEmail: ipcRenderer.sendSync(IPC_GET_SETTINGS, USER_EMAIL),
  };

  handleEmailInput = ({ target }) => {
    const referralEmail = target.value;
    const { senderEmail } = this.state;

    this.setState({
      // Is a valid email address and not the email associated with the current user.
      isEmailValid: isEmail(referralEmail) && referralEmail !== senderEmail,
      referralEmail,
    });
  };

  handleKeypress = e => {
    if (e.key === 'Enter') {
      if (this.state.isEmailValid) {
        this.goNextStep();
      } else {
        this.setState({
          formSubmitted: true,
        });
      }
    }
  };

  handleSendEmail = () => {
    const { senderEmail } = this.state;
    this.setState({ loading: true }, async () => {
      let error = false;
      let code = null;
      try {
        code = await sendReferralEmail({
          receiverEmail: this.state.referralEmail,
          senderEmail,
        });
      } catch (e) {
        error = true;
      }

      this.setState({
        loading: false,
        sendEmailStatus: EMAIL_SENT,
        referralEmail: '',
        code: code.data ? code.data : null,
        error,
        isEmailValid: false,
      });

      this.props.onChange(code.data);

      Analytics.trackEvent(
        CAT_REFERRAL,
        ACT_CLICK,
        LAB_REFERRAL_SEND_EMAIL_TO_FRIEND,
      );
    });
  };

  getSendEmailStatus = () => {
    const { sendEmailStatus, error } = this.state;
    if (sendEmailStatus === INITIAL || sendEmailStatus === SEND_EMAIL) {
      return PREPARE_EMAIL;
    }

    if (sendEmailStatus === PREPARE_EMAIL) {
      return SEND_EMAIL;
    }

    return error ? PREPARE_EMAIL : SEND_EMAIL;
  };

  goNextStep = () => {
    const { isEmailValid, sendEmailStatus } = this.state;
    if (sendEmailStatus === PREPARE_EMAIL) {
      if (isEmailValid) {
        this.handleSendEmail();
      } else {
        this.setState({
          formSubmitted: true,
        });
      }
      return;
    }
    this.setState({ sendEmailStatus: this.getSendEmailStatus() });
  };

  render() {
    const {
      sendEmailStatus,
      referralEmail,
      loading,
      error,
      isEmailValid,
      formSubmitted,
    } = this.state;
    const { small } = this.props;

    const classes = {
      'send-email': sendEmailStatus === SEND_EMAIL,
      'prepare-email': sendEmailStatus === PREPARE_EMAIL,
      'email-sent': sendEmailStatus === EMAIL_SENT,
      error,
    };

    const emailClass = classnames({
      'Input--invalid': isEmailValid === false && formSubmitted === true,
    });

    return (
      <div
        className={classnames('SendEmail', {
          'SendEmail--loading': loading,
          'SendEmail--small': small,
        })}
      >
        <div className={classnames('SendEmail__email-input', classes)}>
          <InputText
            id="emailAddress"
            placeholder="Email"
            onChange={this.handleEmailInput}
            onKeyPress={this.handleKeypress}
            value={referralEmail}
            className={emailClass}
          />
        </div>

        <div
          className={classnames('SendEmail__action-btn', classes)}
          onClick={this.goNextStep}
        >
          <div
            className={classnames('action-btn__prepare-email-content', classes)}
          >
            <img alt="email" src="images/email-icon.png" />
            <span>Send email to a friend</span>
          </div>
          <div
            className={classnames('action-btn__send-email-content', classes)}
          >
            Send
          </div>
        </div>

        <div className={classnames('SendEmail__request-status', classes)}>
          <span className={classnames('request-status__sent-icon', classes)} />
          <span className="request-status__sent-text">
            {error ? 'Email failed' : 'Sent'}
          </span>
        </div>

        <div className={classnames('SendEmail__send-another', classes)}>
          <span onClick={this.goNextStep}>
            {error ? 'Try again' : 'Send another'}
          </span>
        </div>
      </div>
    );
  }
}

export default SendEmail;
