import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button } from '@ventureharbour/serene-shared-components';

import ResizableTextArea from 'components/Shared/ResizableTextarea/ResizableTextarea.component';
import TipBox from 'components/TipBox/TipBox.component';
import { LAB_SET_DAY_GOAL_VIEW } from 'constants/analytics';
import { SET_DAY_GOAL_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { NAV_SESSIONS, ONBOARDING_BLOCK_APPS } from 'constants/routingPaths';
import { IPC_SM_UPDATE_DATA, IPC_SM_GET_DATA } from 'messages/ipc-messages';
import { DATE_FORMAT_APP } from 'config';

import Analytics from 'helpers/universal-analytics.helper';
import { diffDates } from 'helpers/date.helper';

import './SetDayGoal.scss';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

class SetDayGoal extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    location: PropTypes.shape({
      state: PropTypes.shape({ fromOnboarding: PropTypes.bool }),
    }).isRequired,
  };

  state = { textareaValue: '', progressBarWidth: 0 };

  componentDidMount() {
    Analytics.trackPageView(NAV_SESSIONS, LAB_SET_DAY_GOAL_VIEW);

    const { date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);

    this.setState({ date: moment(date) });
  }

  submitGoal = () => {
    const { history, location } = this.props;
    const { textareaValue, progressBarWidth } = this.state;
    // scale of goalScore is 1-5
    const goalScore = Math.min(Math.ceil(progressBarWidth / 20), 5);

    ipcRenderer.sendSync(IPC_SM_UPDATE_DATA, {
      goal: textareaValue,
      goalScore,
    });

    log(
      INFO,
      `Submitted goal ${textareaValue} with score ${goalScore} `,
      SET_DAY_GOAL_VIEW,
    );

    if (location.state && location.state.fromOnboarding) {
      history.push(ONBOARDING_BLOCK_APPS);
    } else {
      this.goToNavSession();
    }
  };

  goToNavSession = () => {
    this.props.history.push({
      pathname: NAV_SESSIONS,
      state: { shouldOpenAddSessionView: true },
    });
  };

  onValueChange = textareaValue => {
    const progressBarWidth = (textareaValue.length * 100) / 150;
    this.setState({ textareaValue, progressBarWidth });
  };

  render() {
    const { textareaValue, progressBarWidth, date } = this.state;
    const submitDisabled = textareaValue.length === 0;
    const { isToday, isTomorrow } = diffDates(date);
    let dayText;

    if (isToday) {
      dayText = 'today';
    } else if (isTomorrow) {
      dayText = 'tomorrow';
    } else {
      dayText = date.format(DATE_FORMAT_APP);
    }

    return (
      <div className="SetDayGoal">
        <div className="container">
          <h1 className="SetDayGoal__header">One day, One goal</h1>
          <div className="SetDayGoal__subtitle">
            Set daily goals based on a milestone you&lsquo;ll be proud to
            achieve. Start with {dayText}&lsquo;s goal:
          </div>
          <ResizableTextArea
            className=""
            value={textareaValue}
            rows={2}
            maxRows={10}
            padding={15}
            lineHeight={26 * 1.5}
            placeholder="Complete the new website designs and send off to the development agency by 5pm"
            onValueChange={this.onValueChange}
            onSubmit={this.submitGoal}
            submitDisabled={submitDisabled}
            autofocus
            transparent
          />
          <Button
            onClick={this.submitGoal}
            name="Set this goal"
            className="btn--big SetDayGoal__submit-btn"
            disabled={submitDisabled}
            primary
          />
        </div>
        <TipBox width={`${progressBarWidth}%`} />
      </div>
    );
  }
}

export default SetDayGoal;
