const path = require('path');
const os = require('os');
const dotenv = require('dotenv');

dotenv.config({ path: path.resolve(__dirname, '../.env') });

const config = {
  SOCKET_LS_URL: 'http://localhost:32888',
  SERVER_URL: process.env.REACT_APP_SERVER_URL || 'http://localhost:4000',
  SERENE_DOWNLOAD_LINK:
    process.env.NODE_ENV === 'production'
      ? 'https://sereneapp.com/get-serene'
      : 'https://internal.serene.ventureharbour.dev',
  SERENE_DOWNLOAD_QUERY_PARAM:
    process.env.NODE_ENV === 'production' ? '?ref=' : '',
  SERENE_DOWNLOAD_REF_PREFIX: 'ref_',

  SERENE_SESSIONS_DIR: path.join(os.homedir(), 'Documents', 'Serene Sessions'),

  CHROME_EXTENSION_ID: 'nbealbhmmmaiiiddmianmjoecmipnjij',
  CHROME_TEST_EXTENSION_ID: 'aofiohpbpdiamdjlkiojclnjjifikaih',
  FIREFOX_EXTENSION_ID: 'serenechromeextension@ventureharbour.com',

  GOOGLE_ACCOUNT_ID: 'UA-133013252-1',

  MAX_NR_OF_APPS_TO_BLOCK_FOR_FREE_USER: 3,
  MAX_NR_OF_SITES_TO_BLOCK_FOR_FREE_USER: 3,
  DEFAULT_SESSIONS_NUMBER: 24,
  TOTAL_NUMBER_OF_TRACKS: 6,
  NUMBER_OF_REQUIRED_SESSIONS: 2,
  PADDLE_PRODUCT_ID: 560884,
  PADDLE_PRO_SUBSCRIPTION_ID: 554791,
  PADDLE_TEAM_SUBSCRIPTION_ID: 576364,
  PADDLE_TEAM: 'paddle-team',
  PADDLE_PRO: 'paddle-pro',
  PADDLE_VENDOR_ID: 40914,
  PADDLE_CURRENCY: '£',
  PADDLE_MONTHLY_PRO_RATE: 4,
  ONE_DAY_IN_MS: 24 * 60 * 60 * 1000,
  DATE_FORMAT_FILE: 'DD-MM-YYYY',
  DATE_FORMAT_APP: 'DD/MM/YYYY',

  SMTP_HOST: 'smtp-relay.sendinblue.com',
  SMTP_PORT: 587,
  SMTP_USER: process.env.SMTP_USER,
  SMTP_PASSWORD: process.env.SMTP_PASSWORD,
};

module.exports = config;
