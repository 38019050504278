export const tooltipClasses = {
  END_SESSION_EARLY: 'end-session-early',
  RESTART_SESSION: 'restart-session',
  PAUSE_SESSION: 'pause-session',
  UNPAUSE_SESSION: 'unpause-session',
  MUTE_MUSIC: 'mute-music',
  UNMUTE_MUSIC: 'unmute-music',
};

export const tooltipTexts = {
  'end-session-early': 'End session early',
  'restart-session': 'Restart session',
  'pause-session': 'Pause session',
  'unpause-session': 'Unpause session',
  'mute-music': 'Mute music',
  'unmute-music': 'Unmute music',
};
