import React from 'react';
import PropTypes from 'prop-types';

const ColumnStatus = ({ status, showStatus = true }) => {
  if (showStatus === false) {
    return '';
  }

  return (
    <span className={`${status === 'asc' && 'status--asc'} status`}>
      <style jsx>
        {`
          .status {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #fff transparent transparent transparent;
            transform-origin: 50% 50%;
            display: inline-block;
            position: relative;
            bottom: 2px;
            cursor: pointer;
          }

          .status--asc {
            transform: rotate(180deg);
          }
        `}
      </style>
    </span>
  );
};

ColumnStatus.propTypes = {
  status: PropTypes.string.isRequired,
  showStatus: PropTypes.bool,
};

export default ColumnStatus;
