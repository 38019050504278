import React from 'react';
import PropTypes from 'prop-types';

const ProductivityRadioElement = ({
  value,
  valueText,
  selectedValue,
  onChange,
  symbol,
  isLabelVisible,
}) => (
  <label>
    <input
      type="radio"
      name="session-state"
      value={value}
      checked={selectedValue === value}
      onChange={onChange}
    />
    <div className="ProductivityRadio__box">
      <span aria-label={valueText}>{symbol}</span>
      {isLabelVisible && (
        <div className="ProductivityRadio__text"> {valueText} </div>
      )}
    </div>
  </label>
);

ProductivityRadioElement.propTypes = {
  value: PropTypes.string.isRequired,
  valueText: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  isLabelVisible: PropTypes.bool.isRequired,
};

export default ProductivityRadioElement;
