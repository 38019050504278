import React from 'react';
import PropTypes from 'prop-types';

import { TOTAL_NUMBER_OF_TRACKS } from 'config';
import {
  ACT_CLICK,
  CAT_GONE_SERENE,
  LAB_MUTE_MUSIC,
} from 'constants/analytics';
import { CURRENT_SESSION_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { tooltipClasses } from 'constants/currentSessionTooltip';
import Analytics from 'helpers/universal-analytics.helper';

import { ReactComponent as Music } from 'images/music-icon.svg';
import { ReactComponent as MusicHover } from 'images/music-hover.svg';
import { ReactComponent as MusicMuted } from 'images/music-muted-icon.svg';
import { ReactComponent as MusicMutedHover } from 'images/music-muted-hover.svg';

const { remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

const { MUTE_MUSIC, UNMUTE_MUSIC } = tooltipClasses;

class MusicPlayer extends React.Component {
  static propTypes = {
    sessionNumber: PropTypes.number,
    tooltipType: PropTypes.string,
    handleMusicToggle: PropTypes.func.isRequired,
    isMusicActive: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.isMusicActive) {
      this.playMusic();
    }
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }

  playMusic = () => {
    const trackNumber = this.getTrackNumber();
    this.audio = new Audio(`music/${trackNumber}.mp3`);
    this.audio.loop = true;
    this.audio.play();
  };

  getTrackNumber = () => {
    const { sessionNumber } = this.props;

    if (!sessionNumber) {
      return Math.floor(Math.random() * TOTAL_NUMBER_OF_TRACKS + 1);
    }
    return sessionNumber > TOTAL_NUMBER_OF_TRACKS
      ? sessionNumber % TOTAL_NUMBER_OF_TRACKS
      : sessionNumber;
  };

  toggleMusic = () => {
    const { isMusicActive, handleMusicToggle } = this.props;

    if (this.audio && isMusicActive) {
      this.audio.pause();
      Analytics.trackEvent(CAT_GONE_SERENE, ACT_CLICK, LAB_MUTE_MUSIC);
    }

    if (this.audio && !isMusicActive) {
      this.audio.play();
    }

    if (!this.audio && !isMusicActive) {
      this.playMusic();
    }

    log(
      INFO,
      `${isMusicActive ? 'Pause' : 'Play'} music`,
      CURRENT_SESSION_VIEW,
    );
    handleMusicToggle();
  };

  toggleMusicOnSessionPause = sessionPaused => {
    if (!this.audio) {
      return;
    }

    if (sessionPaused) {
      this.audio.pause();
      return;
    }

    this.props.isMusicActive && this.audio.play();
  };

  render() {
    const { tooltipType, isMusicActive } = this.props;

    const muteMusicIcon =
      tooltipType === MUTE_MUSIC ? <MusicHover /> : <Music />;
    const musicIcon =
      tooltipType === UNMUTE_MUSIC ? <MusicMuted /> : <MusicMutedHover />;

    return (
      <div className="MusicPlayer" onClick={this.toggleMusic}>
        {isMusicActive ? muteMusicIcon : musicIcon}
      </div>
    );
  }
}

export default MusicPlayer;
