import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, InputText } from '@ventureharbour/serene-shared-components';

import Loader from 'components/Shared/Loader/Loader.component';
import AvatarUploader from 'components/AvatarUploader';

import { LAB_TEAM_ONBOARDING_VIEW } from 'constants/analytics';
import { TEAM_ONBOARDING, NAV_TEAM_DASHBOARD } from 'constants/routingPaths';
import Analytics from 'helpers/universal-analytics.helper';

import './TeamOnboarding.scss';

import { createTeam } from '../../helpers/firebase.helper';

class Onboarding extends Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  state = {
    teamName: '',
    teamNameValid: false,
    teamLogo: '',
    teamLogoValid: true,
    teamNameDirty: false,
    isSavingData: false,
    formSubmitted: false,
  };

  componentDidMount() {
    Analytics.trackPageView(TEAM_ONBOARDING, LAB_TEAM_ONBOARDING_VIEW);
  }

  handleFormSubmit = async formSubmitEvent => {
    formSubmitEvent.preventDefault();

    const { teamName, teamLogo } = this.state;

    this.setState({
      formSubmitted: true,
      isSavingData: true,
    });

    const teamCreated = await createTeam(teamName, teamLogo);
    if (teamCreated) {
      this.props.history.push({
        pathname: NAV_TEAM_DASHBOARD,
        state: { fromOnboarding: true },
      });
      return;
    }

    this.setState({
      formSubmitted: false,
      isSavingData: false,
    });
  };

  handleTeamLogoInput = input => {
    this.setState({ teamLogo: input, teamLogoValid: input !== null });
  };

  handleKeyPress = input => {
    this.setState({ teamNameDirty: true });
  };

  handleTeamNameInput = input => {
    const teamName = input.target.value;

    this.setState({
      teamName,
      teamNameValid: teamName.length && teamName.length <= 40,
    });
  };

  render() {
    const {
      teamName,
      teamNameValid,
      formSubmitted,
      isSavingData,
      teamLogoValid,
      teamNameDirty,
    } = this.state;

    const teamNameClass = classNames({
      invalid: teamNameValid === false && teamNameDirty,
    });

    if (isSavingData) {
      return <Loader />;
    }

    return (
      <div className="Team_onboarding">
        <h1 className="onboarding__title">Welcome to the Team plan</h1>
        <h3 className="onboarding__sub-title">
          Add the following details to personalise your Teams dashboard.
        </h3>

        <form onSubmit={this.handleFormSubmit}>
          <div className="onboarding__row">
            <label htmlFor="teamName">Company or team name</label>
            <InputText
              id="teamName"
              type="text"
              placeholder="Your Company or Team Name"
              onChange={this.handleTeamNameInput}
              onKeyPress={this.handleKeyPress}
              className={teamNameClass}
              value={teamName}
            />
          </div>

          <div className="onboarding__row">
            <AvatarUploader
              maxFileSize="500kb"
              allowedContentTypes="jpg, jpeg, gif, png"
              onChange={this.handleTeamLogoInput}
            />
          </div>

          <div className="onboarding__row">
            <Button
              name="Next"
              disabled={!teamLogoValid || !teamNameValid || formSubmitted}
              className="btn--big onboarding__button"
              primary
            />
          </div>
        </form>
      </div>
    );
  }
}

export default Onboarding;
