import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '@ventureharbour/serene-shared-components';

import './ConfirmationModal.scss';

const ConfirmationModal = ({
  title,
  okAction,
  okTitle,
  subTitle,
  cancelAction,
  open,
  loading,
}) => {
  const handleKeyDown = useCallback(
    event => {
      const { key } = event;

      if (open && key === 'Escape') {
        cancelAction();
      }

      if (open && key === 'Enter') {
        okAction();
      }
    },
    [cancelAction, okAction, open],
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleKeyDown]);

  const confirmationModalClasses = classnames('ConfirmationModal', {
    'ConfirmationModal--loading': loading,
  });

  return (
    <div className={confirmationModalClasses}>
      <h2>{title}</h2>
      {subTitle && <p>{subTitle}</p>}
      <div className="ConfirmationModal__buttons">
        <Button className="btn--black btn--medium" onClick={cancelAction}>
          Cancel
        </Button>
        <Button className="btn--medium" onClick={okAction} primary>
          {okTitle}
        </Button>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  okAction: PropTypes.func.isRequired,
  okTitle: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default ConfirmationModal;
