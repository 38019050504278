import React from 'react';
import PropTypes from 'prop-types';

import 'views/Navigation/Settings/SettingsAccount/SettingsAccountConfirmation/SettingsAccountConfirmation.scss';

import ProPlanCard from 'components/UpgradePlan/ProPlanCard.component';
import FreePlanCard from 'components/UpgradePlan/FreePlanCard.component';
import TeamPlanCard from 'components/UpgradePlan/TeamPlanCard.component';

import { SettingsContext } from 'contexts/SettingsProvider';

import { IPC_OPEN_PADDLE_CHECKOUT } from 'messages/ipc-messages';

const {
  PADDLE_MONTHLY_PRO_RATE,
  PADDLE_TEAM,
  PADDLE_PRO,
} = require('../config');

const { ipcRenderer } = window.require('electron');

const onCheckoutClicked = type => {
  ipcRenderer.send(IPC_OPEN_PADDLE_CHECKOUT, type);
};

const PlansModal = ({ highlight, title }) => (
  <React.Fragment>
    <SettingsContext.Consumer>
      {({ state, toggleTeamsModalState }) => {
        const { isOnline, currency, showTeamsModal } = state;

        if (showTeamsModal) {
          return (
            <div className="SettingsAccountConfirmation">
              <div className="SettingsAccountConfirmation__overlay">
                <React.Fragment>
                  <div className="close" onClick={toggleTeamsModalState}>
                    <img src={require('images/close-icon.svg')} />
                  </div>

                  <h1>{title}</h1>
                  <div className="Plans">
                    <FreePlanCard currency={currency} />

                    <ProPlanCard
                      openCheckout={() => onCheckoutClicked(PADDLE_PRO)}
                      currency={currency}
                      monthlyCharge={PADDLE_MONTHLY_PRO_RATE}
                      isOnline={isOnline}
                      isHighlighted={highlight === PADDLE_PRO}
                    />

                    <TeamPlanCard
                      openCheckout={() => onCheckoutClicked(PADDLE_TEAM)}
                      currency={currency}
                      monthlyCharge="Free"
                      isOnline={isOnline}
                      isHighlighted={highlight === PADDLE_TEAM}
                    />
                  </div>
                </React.Fragment>
              </div>
            </div>
          );
        } else {
          return '';
        }
      }}
    </SettingsContext.Consumer>
    <style jsx>
      {`
        h1 {
          margin-top: 1em;
        }

        .Plans {
          width: auto;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 2em;
          margin-top: 3em;
        }
      `}
    </style>
  </React.Fragment>
);

PlansModal.propTypes = {
  highlight: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PlansModal;
