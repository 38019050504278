import axios from 'axios';

import links from 'constants/links';

const getFavicon = async siteUrl => {
  try {
    siteUrl = siteUrl.replace('https://', '').replace('http://', '');

    const split = siteUrl.split('/');

    if (split.length > 0) {
      siteUrl = split[0];
    }

    const url = `${links.FAVICON_GRABBER}/${siteUrl}`;

    const iconSrc = await axios.get(url).then(({ data }) => {
      const extensionRegex = /(.png|.ico)/g;
      const icon = data.icons.find(item => extensionRegex.test(item.src));

      return icon.src;
    });

    const image = await axios.get(iconSrc, {
      responseType: 'arraybuffer',
    });

    const encodedIconSrc = Buffer.from(image.data).toString('base64');

    return encodedIconSrc;
  } catch (err) {
    return; // if there's no favicon to fetch, return nothing
  }
};

export default getFavicon;
