import React from 'react';
import PropTypes from 'prop-types';

import { List } from '@ventureharbour/serene-shared-components';

import { MAX_NR_OF_APPS_TO_BLOCK_FOR_FREE_USER } from 'config';

import {
  ACT_BLOCKED,
  CAT_BLOCKED_APPS,
  LAB_BLOCK_APP,
} from 'constants/analytics';
import { BLOCKED_APPS_LIST } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import Analytics from 'helpers/universal-analytics.helper';
import {
  BLOCKED_APPS,
  IS_USER_PRO,
  IS_UPGRADE_MODAL_VISIBLE,
} from 'messages/available-settings';
import {
  IPC_CHANGE_SETTINGS,
  IPC_GET_SETTINGS,
  IPC_OPEN_BLOCK_APPS_DIALOG,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import './Blockapps.scss';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

class Blockapps extends React.Component {
  static propTypes = {
    settingsView: PropTypes.bool,
  };

  state = {
    listItems: [],
    isUserPro: false,
  };

  componentDidMount() {
    this.defaultBlockedApps();
    ipcRenderer.on(IPC_SETTING_CHANGED, this.handleSettingChange);

    const isUserPro = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_USER_PRO);
    this.setState({ isUserPro });
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SETTING_CHANGED, this.handleSettingChange);
  }

  handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_USER_PRO) {
      this.setState({ newValue });
    }
  };

  defaultBlockedApps = () => {
    const blockedApps = ipcRenderer.sendSync(IPC_GET_SETTINGS, BLOCKED_APPS);

    this.setState({
      listItems: blockedApps || [],
    });
  };

  updateBlockedAppsSetting = async blockApps => {
    this.setState({
      listItems: blockApps,
    });

    await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: BLOCKED_APPS,
      value: blockApps,
    });
  };

  onRemove = item => {
    const updatedListItems = this.state.listItems.filter(
      listItem => listItem.name !== item.name,
    );

    log(
      INFO,
      `Removed application ${item.name} from block apps list`,
      BLOCKED_APPS_LIST,
    );

    this.updateBlockedAppsSetting(updatedListItems);
  };

  showUpgradeModal = () => {
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_UPGRADE_MODAL_VISIBLE,
      value: true,
    });
  };

  onFindAppClick = () => {
    const { listItems } = this.state;

    const appsToBlock = ipcRenderer.sendSync(
      IPC_OPEN_BLOCK_APPS_DIALOG,
      listItems,
    );

    if (!appsToBlock) {
      return;
    }

    const appsNames = appsToBlock.map(app => app.name).join(', ');

    log(
      INFO,
      `Application(s) added to block apps list: ${appsNames}`,
      BLOCKED_APPS_LIST,
    );

    Analytics.trackEvent(
      CAT_BLOCKED_APPS,
      ACT_BLOCKED,
      LAB_BLOCK_APP,
      appsNames,
    );

    this.updateBlockedAppsSetting(appsToBlock);
  };

  renderSearchBar = () => {
    const { isUserPro, listItems } = this.state;
    const { settingsView } = this.props;

    if (isUserPro || listItems.length < MAX_NR_OF_APPS_TO_BLOCK_FOR_FREE_USER) {
      return (
        <React.Fragment>
          <div className="search__btn" onClick={this.onFindAppClick}>
            <span>+</span>
          </div>
          <div className="search__title" onClick={this.onFindAppClick}>
            Block another app
          </div>
        </React.Fragment>
      );
    }

    if (settingsView) {
      return (
        <div className="search__title--upgrade" onClick={this.showUpgradeModal}>
          Upgrade to Pro for <span>unlimited blocked apps</span>
        </div>
      );
    }

    if (!settingsView) {
      return (
        <div className="search__title--limited">
          <span role="img" aria-label="raising-hands">
            🙌{' '}
          </span>
          You are all set
        </div>
      );
    }
  };

  render() {
    const { listItems } = this.state;
    const isListItemsEmpty = listItems.length === 0;

    return (
      <div className="Blockapps">
        <div className="Blockapps-list">
          {!isListItemsEmpty && (
            <List
              onRemoveItem={this.onRemove}
              items={listItems}
              className="List--apps"
            />
          )}
        </div>
        <div className="Blockapps-list__search">{this.renderSearchBar()}</div>
      </div>
    );
  }
}

export default Blockapps;
