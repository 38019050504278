import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { USER_CURRENCY, IS_ONLINE } from 'messages/available-settings';
import { IPC_SETTING_CHANGED, IPC_GET_SETTINGS } from 'messages/ipc-messages';

const { PADDLE_CURRENCY } = require('../config');

const { ipcRenderer } = window.require('electron');

export const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);
  const [currency, setCurrency] = useState(PADDLE_CURRENCY);
  const [showTeamsModal, setShowTeamsModal] = useState(false);

  useEffect(() => {
    ipcRenderer.on(IPC_SETTING_CHANGED, handleSettingChange);

    const isOnline = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_ONLINE);

    setIsOnline(isOnline);
    return () => {
      ipcRenderer.removeListener(IPC_SETTING_CHANGED, handleSettingChange);
    };
  }, []);

  useEffect(() => {
    const countryCode = ipcRenderer.sendSync(IPC_GET_SETTINGS, USER_CURRENCY);
    if (countryCode !== '') {
      setCurrency(countryCode);
    }
  }, []);

  const handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_ONLINE) {
      setIsOnline(newValue);
    }
  };

  const state = {
    currency,
    isOnline,
    showTeamsModal,
  };

  return (
    <SettingsContext.Provider
      value={{
        state,
        toggleTeamsModalState: () => setShowTeamsModal(!showTeamsModal),
        setShowTeamsModal,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SettingsProvider;
