import React from 'react';
import PropTypes from 'prop-types';

import { CURRENT_SESSION } from 'constants/routingPaths';
import {
  BREAK,
  DAY_FINISHED,
  IDLE,
  REVIEW,
} from 'constants/sessionManagerStates';
import {
  IPC_GO_SERENE,
  IPC_HOTKEY_GO_SERENE,
  IPC_HOTKEY_START_OR_END_SESSION,
  IPC_SM_END_BREAK,
  IPC_SM_GET_DATA,
  IPC_SM_GET_SESSIONS,
  IPC_SM_START_DAY,
} from 'messages/ipc-messages';

const { ipcRenderer } = window.require('electron');

const withHotkeys = Component =>
  class WithHotkeys extends React.Component {
    static propTypes = {
      history: PropTypes.shape({ push: PropTypes.func }),
    };

    componentDidMount() {
      ipcRenderer.on(
        IPC_HOTKEY_GO_SERENE,
        this.handleGoSereneHotkey.bind(this),
      );
      ipcRenderer.on(
        IPC_HOTKEY_START_OR_END_SESSION,
        this.handleStartOrEndSessionHotkey.bind(this),
      );
    }

    componentWillUnmount() {
      ipcRenderer.removeAllListeners(IPC_HOTKEY_GO_SERENE);
      ipcRenderer.removeAllListeners(IPC_HOTKEY_START_OR_END_SESSION);
    }

    handleGoSereneHotkey() {
      const { state: sessionManagerState } = ipcRenderer.sendSync(
        IPC_SM_GET_DATA,
      );
      const goSereneAllowed = [IDLE, REVIEW, DAY_FINISHED].includes(
        sessionManagerState,
      );

      if (goSereneAllowed) {
        ipcRenderer.send(IPC_GO_SERENE);
        ipcRenderer.emit(IPC_GO_SERENE);
        this.props.history.push(CURRENT_SESSION);
      }
    }

    handleStartOrEndSessionHotkey() {
      const sessions = ipcRenderer.sendSync(IPC_SM_GET_SESSIONS);
      const { state: sessionManagerState } = ipcRenderer.sendSync(
        IPC_SM_GET_DATA,
      );

      if (sessionManagerState === IDLE && sessions.length > 0) {
        ipcRenderer.send(IPC_SM_START_DAY);
        this.props.history.push(CURRENT_SESSION);
        return;
      }

      if (sessionManagerState === BREAK) {
        ipcRenderer.send(IPC_SM_END_BREAK);
        this.props.history.push(CURRENT_SESSION);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };

export default withHotkeys;
