const ipcMessages = {
  IPC_CHANGE_ROUTE: 'IPC_CHANGE_ROUTE',
  IPC_CHANGE_SETTINGS: 'IPC_CHANGE_SETTINGS',
  IPC_GET_SETTINGS: 'IPC_GET_SETTINGS',
  IPC_SETTING_CHANGED: 'IPC_SETTING_CHANGED',
  IPC_GO_SERENE: 'IPC_GO_SERENE',
  IPC_STOP_SESSION: 'IPC_STOP_SESSION',
  IPC_START_SESSION: 'IPC_START_SESSION',
  IPC_RESTART_SESSION: 'IPC_RESTART_SESSION',
  IPC_PAUSE_SESSION: 'IPC_PAUSE_SESSION',
  IPC_UNPAUSE_SESSION: 'IPC_UNPAUSE_SESSION',

  IPC_SM_ADD_SESSION: 'IPC_SM_ADD_SESSION',
  IPC_SM_EDIT_SESSION: 'IPC_SM_EDIT_SESSION',
  IPC_SM_REMOVE_SESSION: 'IPC_SM_REMOVE_SESSION',
  IPC_SM_REINDEX_SESSION: 'IPC_SM_REINDEX_SESSION',
  IPC_SM_GET_SESSIONS: 'IPC_SM_GET_SESSIONS',
  IPC_SM_SESSIONS_UPDATED: 'IPC_SM_SESSIONS_UPDATED',

  IPC_SM_START_DAY: 'IPC_SM_START_DAY',
  IPC_SM_FINISH_DAY: 'IPC_SM_FINISH_DAY',
  IPC_SM_END_SESSION: 'IPC_SM_END_SESSION',
  IPC_SM_END_BREAK: 'IPC_SM_END_BREAK',
  IPC_SM_UPDATE_DATA: 'IPC_SM_UPDATE_DATA',
  IPC_SM_GET_DATA: 'IPC_SM_GET_DATA',
  IPC_SM_GET_DAY_DATA: 'IPC_SM_GET_DAY_DATA',
  IPC_SM_CHANGE_DAY: 'IPC_SM_CHANGE_DAY',
  IPC_SM_DATE_CHANGED: 'IPC_SM_DATE_CHANGED',
  IPC_SM_RELOAD_DAY: 'IPC_SM_RELOAD_DAY',

  IPC_SM_GENERATE_FILES: 'IPC_SM_GENERATE_FILES',

  IPC_SM_STATE_CHANGED: 'IPC_SM_STATE_CHANGED',

  IPC_SHOW_WINDOW: 'IPC_SHOW_WINDOW',
  IPC_OPEN_BLOCK_APPS_DIALOG: 'IPC_OPEN_BLOCK_APPS_DIALOG',

  IPC_HOTKEY_START_OR_END_SESSION: 'IPC_HOTKEY_START_OR_END_SESSION',
  IPC_HOTKEY_GO_SERENE: 'IPC_HOTKEY_GO_SERENE',
  IPC_CHANGE_GLOBAL_HOTKEY: 'IPC_CHANGE_GLOBAL_HOTKEY',
  IPC_HOTKEY_ERROR: 'IPC_HOTKEY_ERROR',

  IPC_SAVE_DEFAULT_BLOCKED_APPS_AND_WEBSITES:
    'IPC_SAVE_DEFAULT_BLOCKED_APPS_AND_WEBSITES',

  IPC_RESTART_SERENE: 'IPC_RESTART_SERENE',

  IPC_OPEN_PADDLE_CHECKOUT: 'IPC_OPEN_PADDLE_CHECKOUT',
  IPC_OPEN_PADDLE_CANCELLATION: 'IPC_OPEN_PADDLE_CANCELLATION',
  IPC_CHECKOUT_COMPLETED: 'IPC_CHECKOUT_COMPLETED',
};

module.exports = ipcMessages;
