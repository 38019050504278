import React from 'react';
import PropTypes from 'prop-types';

import ConditionalLink from 'components/Shared/ConditionalLink/ConditionalLink.component';

import { ReactComponent as LogoApp } from 'images/logo-app.svg';
import { SHORTCUTS } from 'constants/routingPaths';

import './index.scss';

const isDev = window.require('electron-is-dev');

const Screen = ({
  centered = false,
  children,
  showShortcut,
  showBackground = true,
}) => (
  <div className="Screen">
    <div>
      {isDev && showShortcut && (
        <ConditionalLink to={SHORTCUTS} shouldRenderLink>
          <LogoApp className="header__logo" />
        </ConditionalLink>
      )}
      {children}
    </div>

    <style jsx>
      {`
        .Screen {
          display: grid;
          min-height: 100vh;
          place-items: center;
          ${showBackground === true
            ? `background: url('/images/onboarding-bg.png') no-repeat bottom / cover;`
            : ''}

          ${centered === true ? `text-align: center;` : ''}
        }
      `}
    </style>
  </div>
);

Screen.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showShortcut: PropTypes.bool,
  showBackground: PropTypes.bool,
};

export default Screen;
