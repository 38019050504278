import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';

class Checkbox extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    checked: PropTypes.bool,
    handleCheckboxChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
  };

  state = { isChecked: false };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked !== prevState.isChecked) {
      return {
        isChecked: nextProps.checked,
      };
    }

    return null;
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, id } = this.props;

    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));

    handleCheckboxChange(id);
  };

  render() {
    const { id, className, children } = this.props;

    return (
      <React.Fragment>
        <input
          type="checkbox"
          id={id}
          checked={this.state.isChecked}
          onChange={this.toggleCheckboxChange}
          className={`${className} checkbox__input`}
        />
        {children && (
          <label htmlFor={id} className="checkbox__label">
            {children}
          </label>
        )}
      </React.Fragment>
    );
  }
}

export default Checkbox;
