import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Menu.scss';

const Menu = ({ children, hasNavigation }) => {
  const menuClasses = classnames('Menu', {
    'Menu--without-navigation': !hasNavigation,
  });

  return <div className={menuClasses}>{children}</div>;
};

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasNavigation: PropTypes.bool,
};

export default Menu;
