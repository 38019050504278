import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const FileUploadInput = ({
  onChange,
  gradientBorder = false,
  placeholder = '',
  variation = 'white-round',
  icon = '',
}) => (
  <div className="FileUploadInput">
    <label
      htmlFor="FileUploadInput__file-input"
      className={`${
        gradientBorder ? 'btn--gradient-border' : ''
      } btn--${variation}`}
    >
      <span className={icon} /> {placeholder}
    </label>
    <input
      name="FileUploadInput__file-input"
      accept="image/*"
      type="file"
      onChange={onChange}
    />
  </div>
);

FileUploadInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  gradientBorder: PropTypes.bool,
  placeholder: PropTypes.string,
  variation: PropTypes.string,
  icon: PropTypes.string,
};

export default FileUploadInput;
