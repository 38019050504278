import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Toggle } from '@ventureharbour/serene-shared-components';

import blockSiteCategories from 'constants/blockSiteCategories';
import capitaliseFirstLetter from 'helpers/capitaliseFirstLetter.helper';

import './BlockedSitesToggles.scss';

const {
  adult: adultCat,
  gambling: gamblingCat,
  social: socialCat,
} = blockSiteCategories;

class BlockedSitesToggles extends Component {
  static propTypes = {
    categories: PropTypes.shape({
      social: PropTypes.bool.isRequired,
      adult: PropTypes.bool.isRequired,
      gambling: PropTypes.bool.isRequired,
    }),
    handleCategoryUpdate: PropTypes.func,
    handleSocialSiteUpdate: PropTypes.func,
    socialSites: PropTypes.arrayOf(PropTypes.object),
    handleSocialDrawerOpening: PropTypes.func,
    socialDrawerOpen: PropTypes.bool.isRequired,
  };

  state = {
    social: this.props.categories.social,
    adult: this.props.categories.adult,
    gambling: this.props.categories.gambling,
    socialDrawerOpen: this.props.socialDrawerOpen,
    socialSites: this.props.socialSites,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};

    const {
      adult: nextAdult,
      social: nextSocial,
      gambling: nextGambling,
    } = nextProps.categories;

    const { socialDrawerOpen: nextDrawerOpen } = nextProps;

    const {
      adult: prevAdult,
      social: prevSocial,
      gambling: prevGambling,
      socialDrawerOpen: prevDrawerOpen,
    } = prevState;

    if (prevDrawerOpen !== nextDrawerOpen) {
      state.socialDrawerOpen = nextDrawerOpen;
    }

    if (nextAdult !== prevAdult) {
      state.adult = nextAdult;
    }

    if (nextSocial !== prevSocial) {
      state.social = nextSocial;
    }

    if (nextGambling !== prevGambling) {
      state.gambling = nextGambling;
    }

    const activeLengthNotEqual = (a, b) => {
      const aLength = a.filter(({ active }) => active === true).length;
      const bLength = b.filter(({ active }) => active === true).length;

      return aLength !== bLength;
    };

    if (activeLengthNotEqual(nextProps.socialSites, prevState.socialSites)) {
      state.socialSites = nextProps.socialSites;
    }

    if (Object.keys(state).length) {
      return state;
    }

    return null;
  }

  toggleSocial = () => {
    const nextSocialState = !this.state.social;

    this.props.handleCategoryUpdate(socialCat, nextSocialState);

    this.state.socialSites.map(site =>
      this.props.handleSocialSiteUpdate(site, nextSocialState),
    );
  };

  toggleAdult = () => {
    this.props.handleCategoryUpdate(adultCat, !this.state.adult);
  };

  toggleGambling = value => {
    this.props.handleCategoryUpdate(gamblingCat, !this.state.gambling);
  };

  toggleSocialDrawer = () => {
    this.props.handleSocialDrawerOpening(!this.state.socialDrawerOpen);
  };

  toggleSocialSite = site => {
    this.props.handleSocialSiteUpdate(site);
  };

  render() {
    const {
      adult,
      gambling,
      social,
      socialDrawerOpen,
      socialSites,
    } = this.state;

    const socialList = socialSites.map((site, index) => (
      <li className="BlockedSitesToggles__item" key={index} ref={index}>
        <Toggle
          small
          onChange={() => this.toggleSocialSite(site)} // eslint-disable-line
          active={site.active}
        >
          <span
            className={`list-item__icon--favicon list-item__icon--favicon-${site.name}`}
          />

          <span className="BlockedSitesToggles__item-name">
            {capitaliseFirstLetter(site.name)}
          </span>
        </Toggle>
      </li>
    ));

    const socialDrawerClass = classnames('BlockedSitesToggles__item', {
      'BlockedSitesToggles__item--open': socialDrawerOpen,
    });

    return (
      <ul className="BlockedSitesToggles">
        <li className={socialDrawerClass}>
          <Toggle small onChange={this.toggleSocial} active={social}>
            Social media
          </Toggle>

          <div
            onClick={this.toggleSocialDrawer}
            className="BlockedSitesToggles__social-toggle"
          />
          <ul className="BlockedSitesToggles BlockedSitesToggles--social">
            {socialList}
          </ul>
        </li>

        <li className="BlockedSitesToggles__item">
          <Toggle small onChange={this.toggleAdult} active={adult}>
            Adult
          </Toggle>
        </li>

        <li className="BlockedSitesToggles__item">
          <Toggle small onChange={this.toggleGambling} active={gambling}>
            Gambling
          </Toggle>
        </li>
      </ul>
    );
  }
}

export default BlockedSitesToggles;
