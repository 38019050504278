import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import Blocksites from 'components/Blocksites/Blocksites.component';

import LINKS from 'constants/links';
import { ONBOARDING_SUCCESS } from 'constants/routingPaths';

import './OnboardingBlockSites.scss';

const { shell } = window.require('electron');

const OnboardingBlockSites = props => {
  const nextStep = () => {
    props.history.push({
      pathname: ONBOARDING_SUCCESS,
    });
  };

  const handleClick = e => {
    e.preventDefault();
    shell.openExternal(e.target.href);
  };

  return (
    <div className="OnboardingBlockSites">
      <div className="container">
        <h1 className="OnboardingBlockSites__title">
          What sites should be blocked when you Go Serene?
        </h1>
        <div className="OnboardingBlockSites__caption">
          For this feature to work, you&lsquo;ll need to download the{' '}
          <a onClick={handleClick} href={LINKS.CHROME_EXTENSION}>
            Serene Chrome Extension
          </a>{' '}
          or{' '}
          <a onClick={handleClick} href={LINKS.FIREFOX_EXTENSION}>
            Firefox Add-on
          </a>
          .
        </div>
        <Blocksites />
        <Button
          onClick={nextStep}
          name="Continue"
          className="OnboardingBlockSites__btn"
          primary
        />
      </div>
    </div>
  );
};

OnboardingBlockSites.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default OnboardingBlockSites;
