import React, { useEffect, useState, useContext } from 'react';

import Analytics from 'helpers/universal-analytics.helper';

import { observeUserSubscriptionChanges } from 'helpers/firebase.helper';

import {
  IPC_OPEN_PADDLE_CHECKOUT,
  IPC_GET_SETTINGS,
  IPC_CHECKOUT_COMPLETED,
  IPC_OPEN_PADDLE_CANCELLATION,
  IPC_CHANGE_SETTINGS,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import {
  FIREBASE_UID,
  SUBSCRIPTION_CANCEL_URL,
  IS_UPGRADE_MODAL_VISIBLE,
  IS_ONLINE,
} from 'messages/available-settings';

import { PAYMENTS } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import {
  PATH_CHECKOUT,
  ACT_CLICK,
  LAB_PAYMENT_MODAL_VIEW,
  CAT_PADDLE_UPGRADE,
  CAT_PADDLE_INFO,
  LAB_PADDLE_CLICK_PRO,
  LAB_PADDLE_CHECKOUT_ERROR,
  LAB_PADDLE_CHECKOUT_CLOSE,
  LAB_PADDLE_CHECKOUT_SUCCESS,
} from 'constants/analytics';
import { UserContext } from 'contexts/UserProvider';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

const {
  PADDLE_VENDOR_ID,
  PADDLE_PRO_SUBSCRIPTION_ID,
  PADDLE_TEAM_SUBSCRIPTION_ID,
  PADDLE_TEAM,
} = require('../../config');

console.log(process.env.FIREBASE_PROJECT_ID);

const Paddle = () => {
  let profileObserver = () => ({});
  let subscription = {};
  const [isOnline, setOnline] = useState(true);
  const {
    state: { user },
  } = useContext(UserContext);
  const { firebaseUID } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
    FIREBASE_UID,
  ]);

  const closeProfileObserver = () =>
    new Promise((resolve, reject) => {
      window.Paddle.Spinner.show();
      const hasSubscription = Object.keys(subscription).length;

      setTimeout(
        () => {
          if (hasSubscription) {
            if (typeof profileObserver === 'function') {
              profileObserver();
            }
            resolve();
          } else {
            reject('User subscription details not found');
          }

          window.Paddle.Spinner.hide();
        },
        hasSubscription ? 0 : 10000,
      );
    });

  const loadCallback = () => {
    profileObserver = observeUserSubscriptionChanges(
      firebaseUID,
      subscriptionData => {
        subscription = subscriptionData;
      },
    );
  };

  const successCallback = async () => {
    await closeProfileObserver();
  };

  const openPaddle = type => {
    Analytics.trackPageView(PATH_CHECKOUT, LAB_PAYMENT_MODAL_VIEW);

    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_UPGRADE_MODAL_VISIBLE,
      value: false,
    });

    window.Paddle.Checkout.open({
      product:
        type === PADDLE_TEAM
          ? PADDLE_TEAM_SUBSCRIPTION_ID
          : PADDLE_PRO_SUBSCRIPTION_ID,
      country: 'GB',
      postcode: 'ox10 9ly',
      passthrough: {
        uid: firebaseUID,
        ...(process.env.FIREBASE_PROJECT_ID
          ? { project: process.env.FIREBASE_PROJECT_ID }
          : {}),
      },
      email: user.email,
      loadCallback,
      successCallback,
      closeCallback: async () => {
        Analytics.trackEvent(
          CAT_PADDLE_UPGRADE,
          ACT_CLICK,
          LAB_PADDLE_CHECKOUT_CLOSE,
        );

        await closeProfileObserver();
        ipcRenderer.send(IPC_CHECKOUT_COMPLETED);
      },
    });
  };

  const openPaddleCancellation = async () => {
    const paddleCancelUrl = await ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      SUBSCRIPTION_CANCEL_URL,
    );

    if (paddleCancelUrl !== null) {
      window.Paddle.Checkout.open({
        override: paddleCancelUrl,
        loadCallback,
        successCallback,
        closeCallback: async () => {
          await closeProfileObserver();
          ipcRenderer.send(IPC_CHECKOUT_COMPLETED);
        },
      });
    }
  };

  useEffect(() => {
    ipcRenderer.on(IPC_SETTING_CHANGED, handleSettingChange);

    const isOnline = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_ONLINE);
    setOnline(isOnline);

    return () => {
      ipcRenderer.removeListener(IPC_SETTING_CHANGED, handleSettingChange);
    };
  }, []);

  useEffect(() => {
    ipcRenderer.on(IPC_OPEN_PADDLE_CHECKOUT, (event, type) => {
      openPaddle(type);
    });

    ipcRenderer.on(IPC_OPEN_PADDLE_CANCELLATION, () => {
      openPaddleCancellation();
    });

    return () => {
      ipcRenderer.removeListener(IPC_OPEN_PADDLE_CHECKOUT, openPaddle);
      ipcRenderer.removeListener(
        IPC_OPEN_PADDLE_CANCELLATION,
        openPaddleCancellation,
      );
    };
  });

  const handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_ONLINE) {
      setOnline(newValue);
    }
  };

  if (!isOnline) {
    return null;
  }

  return (
    <React.Fragment>
      {window.Paddle.Setup({
        vendor: PADDLE_VENDOR_ID,
        eventCallback({ event }) {
          log(INFO, `Paddle action has been taken: ${event}`, PAYMENTS);
          if (event === 'Checkout.Error') {
            Analytics.trackEvent(
              CAT_PADDLE_INFO,
              ACT_CLICK,
              LAB_PADDLE_CHECKOUT_ERROR,
            );
          }
          if (event === 'Checkout.PaymentComplete') {
            Analytics.trackEvent(
              CAT_PADDLE_INFO,
              ACT_CLICK,
              LAB_PADDLE_CHECKOUT_SUCCESS,
              event.product.name,
            );
          }

          if (event === 'Checkout.Complete') {
            Analytics.trackEvent(
              CAT_PADDLE_UPGRADE,
              ACT_CLICK,
              LAB_PADDLE_CLICK_PRO,
            );
          }
        },
      })}
    </React.Fragment>
  );
};

export default Paddle;
