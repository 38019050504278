import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './Tooltip.scss';

const Tooltip = ({ isVisible, children, type }) => {
  const tooltipClass = classnames('Tooltip', {
    'Tooltip--visible': isVisible,
    'Tooltip--current-session': !!type,
    [`Tooltip--${type}`]: !!type,
  });
  return <div className={tooltipClass}>{children}</div>;
};

Tooltip.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
};

export default Tooltip;
