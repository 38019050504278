import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { Button } from '@ventureharbour/serene-shared-components';

import Analytics from 'helpers/universal-analytics.helper';

import { CURRENT_SESSION } from 'constants/routingPaths';
import { IPC_GO_SERENE, IPC_SM_START_DAY } from 'messages/ipc-messages';

import { ReactComponent as PlayIcon } from 'images/play-icon.svg';
import { ReactComponent as ButtonBackground } from 'images/red-go-serene-bg-button.svg';

import './GoSereneButton.scss';

const { ipcRenderer } = window.require('electron');

class GoSereneButton extends React.Component {
  static propTypes = {
    callback: PropTypes.func,
    showIcon: PropTypes.bool,
    startDay: PropTypes.bool,
    disabled: PropTypes.bool,
    history: PropTypes.shape({ push: PropTypes.func }),
    buttonClass: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    destination: PropTypes.string,
    analyticsEvent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  onClick = () => {
    const {
      startDay,
      destination,
      history,
      analyticsEvent,
      callback,
    } = this.props;
    if (analyticsEvent) {
      Analytics.trackEvent(...analyticsEvent);
    }

    if (callback) {
      callback();
      return;
    }

    if (destination) {
      history.push(destination);
      return;
    }

    if (startDay) {
      ipcRenderer.send(IPC_SM_START_DAY);
    } else {
      ipcRenderer.send(IPC_GO_SERENE);
      ipcRenderer.emit(IPC_GO_SERENE);
    }

    history.push(CURRENT_SESSION);
  };

  render() {
    const { disabled, showIcon, buttonClass, buttonText } = this.props;

    const isDisabled = classNames({
      GoSereneButton: true,
      'GoSereneButton--disabled': disabled,
    });

    const buttonClassNames = classNames(
      'btn--big',
      {
        'btn--with-icon': showIcon,
      },
      buttonClass,
    );

    const playIcon = showIcon ? (
      <PlayIcon className="GoSereneButton__icon" />
    ) : (
      ''
    );

    return (
      <div className={isDisabled}>
        <div className="GoSereneButton__bg">
          <ButtonBackground />
        </div>
        <div className="GoSereneButton__content">
          <Button
            name="goSerene"
            className={buttonClassNames}
            {...this.props}
            onClick={this.onClick}
            primary
          >
            {playIcon} {buttonText}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(GoSereneButton);
