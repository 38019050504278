import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_SESSIONS_NUMBER } from 'config';
import { LAB_PLAN_SESSIONS_VIEW } from 'constants/analytics';
import { PLAN_SESSIONS_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { NAV_SESSIONS, SET_DAY_GOAL } from 'constants/routingPaths';
import { MIN_30 } from 'constants/sessionTimes';
import Analytics from 'helpers/universal-analytics.helper';
import { SESSION_TIME } from 'messages/available-settings';
import {
  IPC_GET_SETTINGS,
  IPC_SM_EDIT_SESSION,
  IPC_SM_REMOVE_SESSION,
  IPC_SM_REINDEX_SESSION,
  IPC_SM_GET_DAY_DATA,
} from 'messages/ipc-messages';

import AddSessionView from '../PlanView/AddSessionView.component';
import SessionsOverview from './SessionsOverview.component';

const { ipcRenderer } = window.require('electron');

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

class PlanSessions extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    isToday: PropTypes.bool,
    sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
    shouldOpenAddSessionView: PropTypes.bool.isRequired,
  };

  state = {
    isAddSessionViewOpened: this.props.shouldOpenAddSessionView,
    sessionToEdit: null,
    maxSessions: DEFAULT_SESSIONS_NUMBER,
    defaultSessionDuration: MIN_30,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const isAddSessionViewOpened =
      prevState.isAddSessionViewOpened &&
      (nextProps.sessions.length < prevState.maxSessions ||
        prevState.sessionToEdit);

    return {
      isAddSessionViewOpened,
    };
  }

  componentDidMount() {
    const defaultSessionDuration = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      SESSION_TIME,
    );

    this.setState({ defaultSessionDuration });

    Analytics.trackPageView(NAV_SESSIONS, LAB_PLAN_SESSIONS_VIEW);
  }

  onAddSessionClick = () => {
    log(INFO, `Clicked add session button`, PLAN_SESSIONS_VIEW);

    this.setState({
      isAddSessionViewOpened: true,
    });
  };

  editSession = session => {
    log(INFO, `Clicked edit session button - ${session}`, PLAN_SESSIONS_VIEW);

    this.setState({
      isAddSessionViewOpened: true,
      sessionToEdit: session,
    });
  };

  removeSession = id => {
    log(INFO, `Clicked remove session button - ${id}`, PLAN_SESSIONS_VIEW);

    ipcRenderer.send(IPC_SM_REMOVE_SESSION, {
      id,
    });
  };

  reindexSession = (targetNumber, element) => {
    ipcRenderer.send(IPC_SM_REINDEX_SESSION, {
      targetNumber,
      element,
    });
  };

  saveEditedSession = session => {
    log(INFO, `Finished editting session - ${session}`, PLAN_SESSIONS_VIEW);
    this.setState({
      sessionToEdit: null,
    });
    ipcRenderer.send(IPC_SM_EDIT_SESSION, session);
  };

  render() {
    const { isAddSessionViewOpened, sessionToEdit } = this.state;
    const { sessions, isToday, history } = this.props;

    const data = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
    const { goal: currentGoal } = data;

    if (!currentGoal) {
      history.push({
        pathname: SET_DAY_GOAL,
        hash: isToday ? 'planningTomorrow' : '',
      });
    }

    return (
      <div className="PlanSessions">
        {isAddSessionViewOpened ? (
          <AddSessionView
            history={this.props.history}
            sessions={sessions}
            sessionToEdit={sessionToEdit}
            saveEditedSession={this.saveEditedSession}
            removeSession={this.removeSession}
            reindexSession={this.reindexSession}
            isToday={isToday}
            maxSessions={this.state.maxSessions}
            tomorrowGoal={isToday}
            defaultSessionDuration={this.state.defaultSessionDuration}
          />
        ) : (
          <SessionsOverview
            onAddSessionClick={this.onAddSessionClick}
            editSession={this.editSession}
            removeSession={this.removeSession}
            maxSessions={this.state.maxSessions}
            sessions={sessions}
            isToday={isToday}
          />
        )}
      </div>
    );
  }
}

export default PlanSessions;
