const socialSitesList = [
  'facebook',
  'twitter',
  'linkedin',
  'youtube',
  'instagram',
  'pinterest',
];

module.exports = socialSitesList;
