import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import TeamRow from 'components/TeamRow';
import TeamRowPending from 'components/TeamRowPending';
import ColumnStatus from 'components/ColumnStatus';

import {
  nameSort,
  statusSort,
  lastSessionSort,
  sessionsCountSort,
  totalSereneTimeSort,
} from 'helpers/sorting.helper';

import useInterval from 'hooks/useInterval';

import theme from 'style/theme';

const TeamTable = ({
  members,
  isManager,
  invitees,
  handleRemoveTeamMember,
  handleRevokeUser,
  ...props
}) => {
  const [columnToSort, setColumnToSort] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [time, setTime] = useState(new Date().getTime());

  useInterval(() => {
    setTime(new Date().getTime());
  }, 10000);

  const handleHeaderClick = column => {
    let newSortDirection = 'desc';
    if (columnToSort === column) {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      }
    }

    setColumnToSort(column);
    setSortDirection(newSortDirection);
  };

  const sortedMembers = useMemo(() => {
    let sortingType = '';
    switch (columnToSort) {
      case 'profile':
        sortingType = nameSort;
        break;
      case 'status':
        sortingType = statusSort;
        break;
      case 'lastSession':
        sortingType = lastSessionSort;
        break;
      case 'sessionsCount':
        sortingType = sessionsCountSort;
        break;
      case 'totalSereneTime':
        sortingType = totalSereneTimeSort;
        break;
      default:
        sortingType = nameSort;
        break;
    }

    let sortedMembers = members.sort(sortingType);

    if (sortDirection === 'asc') {
      sortedMembers = sortedMembers.reverse();
    }
    return sortedMembers;
  }, [columnToSort, sortDirection, time, members]);

  return (
    <React.Fragment>
      <div className="TeamTable-header">
        <table>
          <thead>
            <tr>
              <th onClick={() => handleHeaderClick('profile')}>
                Profile{' '}
                <ColumnStatus
                  status={sortDirection}
                  showStatus={columnToSort === 'profile'}
                />
              </th>

              <th onClick={() => handleHeaderClick('status')}>
                Status{' '}
                <ColumnStatus
                  status={sortDirection}
                  showStatus={columnToSort === 'status'}
                />
              </th>

              <th onClick={() => handleHeaderClick('lastSession')}>
                Last Session{' '}
                <ColumnStatus
                  status={sortDirection}
                  showStatus={columnToSort === 'lastSession'}
                />
              </th>

              <th onClick={() => handleHeaderClick('sessionsCount')}>
                No. Of Sessions{' '}
                <ColumnStatus
                  status={sortDirection}
                  showStatus={columnToSort === 'sessionsCount'}
                />
              </th>

              <th onClick={() => handleHeaderClick('totalSereneTime')}>
                Total Serene Time{' '}
                <ColumnStatus
                  status={sortDirection}
                  showStatus={columnToSort === 'totalSereneTime'}
                />
              </th>
              <th />
            </tr>
          </thead>
        </table>
      </div>
      {sortedMembers.length ? (
        <div className="TeamTable-body">
          <table>
            <tbody>
              {sortedMembers.map((member, i) => (
                <TeamRow
                  member={member}
                  isManager={isManager}
                  key={i}
                  handleRemoveTeamMember={handleRemoveTeamMember}
                />
              ))}
              {isManager &&
                invitees.map(({ email }, i) => (
                  <TeamRowPending
                    email={email}
                    key={i}
                    handleRevokeUser={handleRevokeUser}
                  />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="TeamTable-body loading">
          Grabbing data{' '}
          <span role="img" aria-label="dionsaur">
            🦕{' '}
          </span>
        </div>
      )}
      <style jsx>
        {`
          .TeamTable-body {
            max-height: 560px;
            overflow-y: auto;
            background: ${theme.color.white};
            box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.12);
            border-radius: ${theme.borderRadii.medium};
          }

          .TeamTable-body,
          .TeamTable-header {
            padding-left: 1em;
            padding-right: 1em;
          }

          .TeamTable-body::-webkit-scrollbar {
            width: 8px;
          }

          .TeamTable-body::-webkit-scrollbar-track {
            background: none;
          }

          .TeamTable-body::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.25);
          }

          .loading {
            line-height: 540px;
            text-align: center;
          }

          table {
            table-layout: fixed;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }

          th {
            color: ${theme.color.white};
            text-transform: uppercase;
            font-size: 12px;
            text-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
            font-weight: bold;
            padding-bottom: 8px;
            cursor: pointer;
          }

          th:nth-child(1) {
            text-align: left;
            width: 200px;
          }
        `}
      </style>
    </React.Fragment>
  );
};

TeamTable.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  invitees: PropTypes.arrayOf(PropTypes.object).isRequired,
  isManager: PropTypes.bool.isRequired,
  handleRemoveTeamMember: PropTypes.func.isRequired,
  handleRevokeUser: PropTypes.func.isRequired,
};

export default TeamTable;
