const sessionTimes = require('./sessionTimes');

const { MIN_20, MIN_30, MIN_45, MIN_60 } = sessionTimes;

const sessionTimesOptions = [
  {
    time: MIN_20,
    text: '20 mins',
  },
  {
    time: MIN_30,
    text: '30 mins',
  },
  {
    time: MIN_45,
    text: '45 mins',
  },
  {
    time: MIN_60,
    text: '60 mins',
  },
];

module.exports = sessionTimesOptions;
