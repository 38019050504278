import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import GoSereneButton from 'components/Shared/GoSereneButton/GoSereneButton.Component';
import SessionList from 'components/Shared/SessionList/SessionList.component';
import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';
import {
  ACT_CLICK,
  CAT_SESSION_OVERVIEW,
  LAB_ENTER_SERENE,
} from 'constants/analytics';
import { BREAK, REVIEW } from 'constants/sessionManagerStates';
import { diffDates } from 'helpers/date.helper';
import { ReactComponent as ArrowIcon } from 'images/left-arrow.svg';
import { IPC_SM_GET_DATA } from 'messages/ipc-messages';

import './SessionsOverview.scss';
import SecondaryNavigationHeader from 'components/SecondaryNavigationHeader';

const { ipcRenderer } = window.require('electron');

class SessionsOverview extends React.Component {
  static propTypes = {
    onAddSessionClick: PropTypes.func.isRequired,
    removeSession: PropTypes.func.isRequired,
    editSession: PropTypes.func.isRequired,
    isToday: PropTypes.bool,
    maxSessions: PropTypes.number.isRequired,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        counterStartTime: PropTypes.number.isRequired,
        number: PropTypes.number.isRequired,
        state: PropTypes.string.isRequired,
        task: PropTypes.string.isRequired,
        startingFrom: PropTypes.string,
        isActive: PropTypes.bool,
        isFinished: PropTypes.bool,
      }).isRequired,
    ).isRequired,
  };

  renderText() {
    const { sessions, isToday } = this.props;
    const numberOfSessions = sessions.length;
    const formattedText = `You now have ${numberOfSessions}${
      numberOfSessions === 1 ? ' session' : ' sessions'
    } planned!`;

    if (numberOfSessions === 0 || !isToday) {
      return (
        <div>
          <div className="content__help-arrow">
            <ArrowIcon />
          </div>
          <h1>Create some sessions</h1>
        </div>
      );
    }

    return (
      <div>
        <div className="content__help-text">{formattedText}</div>
        <h1>Start your session</h1>
      </div>
    );
  }

  render() {
    const {
      sessions,
      onAddSessionClick,
      removeSession,
      editSession,
    } = this.props;

    const { state, date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
    const { isFutureDay } = diffDates(date);

    const goSereneButtonDisabled =
      isFutureDay ||
      sessions.length === 0 ||
      state === REVIEW ||
      state === BREAK;

    const addSessionButtonVisible = sessions.length < this.props.maxSessions;

    return (
      <div className="SessionsOverview">
        <div className="SessionsOverview__menu">
          <div className="SessionsOverview__menu-top">
            <div className="SessionsOverview__menu-day-plan">
              <SecondaryNavigationHeader>
                Your day plan
              </SecondaryNavigationHeader>
              <TodayGoal isEditable />
            </div>
            <h3>PLANNED SESSIONS</h3>
            {addSessionButtonVisible ? (
              <Button
                className="session-list__btn"
                name="addSession"
                onClick={onAddSessionClick}
                secondary
              >
                <div className="plus-sign">+</div> Add new session
              </Button>
            ) : null}
          </div>
          <SessionList
            dragDisabled
            sessions={sessions}
            onRemoveSession={removeSession}
            onEditSession={editSession}
          />
        </div>
        <div className="SessionsOverview__content">
          <div className="content">
            {this.renderText()}
            <div className="content__btn">
              <GoSereneButton
                startDay
                disabled={goSereneButtonDisabled}
                buttonText="Go Serene"
                analyticsEvent={[
                  CAT_SESSION_OVERVIEW,
                  ACT_CLICK,
                  LAB_ENTER_SERENE,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SessionsOverview;
