import React from 'react';
import PropTypes from 'prop-types';

const SecondaryNavigationHeader = ({ children, ...props }) => (
  <h1 {...props}>
    {children}
    <style jsx>
      {`
        h1 {
          display: block;
          margin: 33px 0 0;
          color: #fff;
          font-weight: bold;
          font-size: 50px;
          line-height: 60px;
        }
      `}
    </style>
  </h1>
);

SecondaryNavigationHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SecondaryNavigationHeader;
