import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import ConditionalLink from 'components/Shared/ConditionalLink/ConditionalLink.component';

import './NavItem.scss';
import BadgePill from 'components/Shared/BadgePill';

const NavItem = ({
  children,
  caption,
  link,
  pathname,
  additionalClass,
  shouldRenderLink,
  badgeText,
}) => {
  const doesPathnameMatchLink = () => pathname && pathname.includes(link);

  const isActive = classnames('NavItem', {
    'NavItem--active': doesPathnameMatchLink(),
    [`NavItem${additionalClass}`]: additionalClass,
  });
  return (
    <ConditionalLink
      to={link}
      className={isActive}
      shouldRenderLink={shouldRenderLink}
    >
      <BadgePill text={badgeText} />
      {children}
      <p className="NavItem__caption">{caption}</p>
    </ConditionalLink>
  );
};

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
  caption: PropTypes.string,
  link: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  additionalClass: PropTypes.string,
  shouldRenderLink: PropTypes.bool,
  badgeText: PropTypes.string,
};

export default NavItem;
