import React from 'react';

import classnames from 'classnames';

import { GO_SERENE, START_OR_END_SESSION } from 'constants/hotkeys';
import hotkeysMapping, {
  checkIfCombinationForbidden,
} from 'constants/hotkeysMapping';
import displayCombination from 'helpers/displayCombination.helper';
import { HOTKEYS, HOTKEY_EDITING } from 'messages/available-settings';
import {
  IPC_CHANGE_GLOBAL_HOTKEY,
  IPC_CHANGE_SETTINGS,
  IPC_GET_SETTINGS,
  IPC_HOTKEY_ERROR,
} from 'messages/ipc-messages';

import SettingsContent from '../SettingsContent.component';

import './SettingsHotkeys.scss';

const { ipcRenderer } = window.require('electron');

class SettingsHotkeys extends React.Component {
  state = {
    hotkeys: {
      goSerene: [],
      startOrEndSession: [],
    },
    hotkeyError: null,
    hotkeyForbidden: false,
  };

  componentDidMount() {
    ipcRenderer.on(IPC_HOTKEY_ERROR, (e, name) => {
      this.setState({ hotkeyError: name });
    });

    const hotkeys = ipcRenderer.sendSync(IPC_GET_SETTINGS, HOTKEYS);
    this.setState({ hotkeys });
  }

  componentWillUnmount() {
    ipcRenderer.removeAllListeners(IPC_HOTKEY_ERROR);
  }

  startEditingHotkey = async ({ target }) => {
    const clickedHotkeyId =
      target.tagName === 'SPAN' ? target.parentElement.id : target.id;
    await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: HOTKEY_EDITING,
      value: {
        name: clickedHotkeyId,
        combination: this.state.hotkeys[clickedHotkeyId],
      },
    });
  };

  detectPressedButton = async event => {
    this.setState({ hotkeyForbidden: false, hotkeyError: null });

    const { metaKey, altKey, ctrlKey, target, keyCode } = event;
    const atLeastOneModifier = metaKey || altKey || ctrlKey;

    const combination = this.createCombination(event);
    const hotkeyForbidden = checkIfCombinationForbidden(combination);

    const eventTarget = event.target.id;

    if (hotkeyForbidden) {
      this.setState({ hotkeyForbidden: eventTarget });
      return;
    }

    const keyNotAllowed = !hotkeysMapping[keyCode];

    if (!atLeastOneModifier || keyNotAllowed) {
      return;
    }

    const name = target.id;

    this.setState(
      prevState => ({
        hotkeys: { ...prevState.hotkeys, [name]: combination },
      }),
      async () => {
        await ipcRenderer.send(IPC_CHANGE_GLOBAL_HOTKEY, {
          name,
          combination,
        });

        await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
          name: HOTKEY_EDITING,
          value: false,
        });
      },
    );

    target.blur();
  };

  stopEditingHotkey = async () => {
    await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: HOTKEY_EDITING,
      value: false,
    });
    this.setState({ hotkeyError: null });
  };

  createCombination = e => {
    const { ctrlKey, altKey, shiftKey, metaKey, keyCode } = e;
    const modifiers = {
      metaKey,
      altKey,
      ctrlKey,
      shiftKey,
    };

    const pressedModifiers = Object.keys(modifiers).reduce((arr, key) => {
      modifiers[key] && arr.push(hotkeysMapping[key]);
      return arr;
    }, []);

    return [...pressedModifiers, hotkeysMapping[keyCode]];
  };

  render() {
    const { goSerene, startOrEndSession } = this.state.hotkeys;
    const { hotkeyError, hotkeyForbidden } = this.state;

    const goSereneClass = classnames('Input Input--hotkey', {
      'Input--invalid':
        hotkeyError === GO_SERENE || hotkeyForbidden === GO_SERENE,
    });

    const startOrEndSessionClass = classnames('Input Input--hotkey', {
      'Input--invalid':
        hotkeyError === START_OR_END_SESSION ||
        hotkeyForbidden === START_OR_END_SESSION,
    });

    return (
      <SettingsContent
        header="Hotkeys"
        tagline={[
          'View the available hotkeys for controlling Serene.',
          `To edit the hotkeys, select the input you want to change
          below and whilst select enter the key combination.`,
        ]}
        className="HotkeysSettings"
      >
        <div className="HotkeysSettings__row">
          <label>Go Serene:</label>
          <div
            className={goSereneClass}
            onKeyDown={this.detectPressedButton}
            onClick={this.startEditingHotkey}
            onBlur={this.stopEditingHotkey}
            tabIndex="1"
            id={GO_SERENE}
          >
            <span>{displayCombination(goSerene)}</span>
          </div>

          <label>Start/End session:</label>
          <div
            className={startOrEndSessionClass}
            onKeyDown={this.detectPressedButton}
            onClick={this.startEditingHotkey}
            onBlur={this.stopEditingHotkey}
            tabIndex="2"
            id={START_OR_END_SESSION}
          >
            <span>{displayCombination(startOrEndSession)}</span>
          </div>
          <div />
          {hotkeyError && (
            <div className="row__hotkey-error">
              This hotkey is already in use. <br /> Please try a different one.
            </div>
          )}
          {hotkeyForbidden && (
            <div className="row__hotkey-error">
              This hotkey is forbidden. <br /> Please try a different one.
            </div>
          )}
        </div>
      </SettingsContent>
    );
  }
}

export default SettingsHotkeys;
