import React from 'react';
import ReactDOM from 'react-dom';

import { RENDERER } from 'constants/loggerGroups';
import { initLogingForAxios } from 'helpers/Logger/axiosLoging.helper';

import App from './App';

import './index.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

window.addEventListener('error', event => {
  log(`error`, event.error.stack, RENDERER);
});

initLogingForAxios();

ReactDOM.render(<App />, document.getElementById('root'));
