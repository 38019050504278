import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import './PlanCard.scss';

const ProPlanCard = ({
  openCheckout,
  currency,
  monthlyCharge,
  isOnline,
  isHighlighted = false,
}) => {
  const className = isHighlighted ? 'Card Card--highlighted' : 'Card';

  return (
    <div className={className}>
      <div className="Card__header Card__header-pro" />
      <div className="Card__content">
        <h1 className="content__title">Pro</h1>
        <div className="content__price">
          <span className="price__currency">{currency}</span>
          <span className="price__value">{monthlyCharge}</span>
          <span className="price__period"> /mo</span>
          <div className="price__info">paid annually</div>
        </div>
        <div className="content__benefits">
          <ul className="benefits__list">
            <li className="list__item">Unlimited blocked websites</li>
            <li className="list__item">Unlimited blocked apps</li>
            <li className="list__item">Block mobile & more</li>
            <li className="list__item">Plan tomorrow/week</li>
          </ul>
        </div>
        <div className="content__cta">
          <Button
            onClick={openCheckout}
            disabled={!isOnline}
            name="Upgrade to Pro"
            primary
          />
        </div>
      </div>
    </div>
  );
};

ProPlanCard.propTypes = {
  openCheckout: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  monthlyCharge: PropTypes.number.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool,
};

export default ProPlanCard;
