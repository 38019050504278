import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import sessionTimesOptions from 'constants/sessionTimesOptions';
import { ReactComponent as ClockIcon } from 'images/clock-icon.svg';
import { ReactComponent as Arrow } from 'images/down-chevron-icon.svg';

import './TimeDropdown.scss';
import '../Select/Select.scss';

class TimeDropdown extends Component {
  static propTypes = {
    handleTimeUpdate: PropTypes.func.isRequired,
    default: PropTypes.number.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const time = sessionTimesOptions.find(
      ({ time }) => time === nextProps.default,
    );
    return {
      ...prevState,
      time,
    };
  }

  state = {
    modalOpen: false,
    time: sessionTimesOptions[1],
  };

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClick, false);

    const time = sessionTimesOptions.find(
      option => option.time === this.props.default,
    );

    this.setState({
      time,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClick, false);
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  handleOptionClick = e => {
    const time = sessionTimesOptions[e.target.id];
    this.setState({
      time,
    });

    this.closeModal();

    this.props.handleTimeUpdate(time.time);
  };

  handleClick = e => {
    // click is inside component
    if (this.node.contains(e.target)) {
      return;
    }
    this.state.modalOpen && this.closeModal();
  };

  render() {
    const modalClasses = classNames('TimeDropdown__modal', {
      'TimeDropdown__modal--open': this.state.modalOpen,
    });

    return (
      <div className="TimeDropdown" ref={node => (this.node = node)}>
        <div className="TimeDropdown__text" onClick={this.toggleModal}>
          <ClockIcon className="TimeDropdown__clock" />
          {this.state.time.text}
          <Arrow className="TimeDropdown__down-arrow" />
        </div>
        <div className={modalClasses}>
          {sessionTimesOptions.map((option, i) => {
            const timeDropdownItemClass = classNames('TimeDropdown__item', {
              'TimeDropdown__item--selected':
                this.state.time.value === option.time,
            });

            return (
              <div
                key={i}
                onClick={this.handleOptionClick}
                id={i}
                className={timeDropdownItemClass}
              >
                {option.text}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default TimeDropdown;
