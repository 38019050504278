import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import classnames from 'classnames';

import GoSereneButton from 'components/Shared/GoSereneButton/GoSereneButton.Component';
import InstallBrowserExtensionButton from 'components/Shared/InstallBrowserExtensionButton/InstallBrowserExtensionButton.component';

import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';
import NotificationCard from 'components/Shared/NotificationCard/NotificationCard.component';
import SendEmail from 'components/SendEmail/SendEmail.component';
import WeekPlanner from 'components/WeekPlanner/WeekPlanner.component';
import GreatWork from './GreatWork.component';

import {
  SERENE_DOWNLOAD_LINK,
  SERENE_DOWNLOAD_QUERY_PARAM,
  SERENE_DOWNLOAD_REF_PREFIX,
  DATE_FORMAT_APP,
} from 'config';

import {
  ACT_CLICK,
  CAT_DASHBOARD,
  CAT_REFERRAL,
  LAB_CLICKS_BLOCK_DISTRACTIONS,
  LAB_CONTINUE_PLANNING,
  LAB_CONTINUE_SESSION,
  LAB_DASHBOARD_VIEW,
  LAB_REFERRAL_COPY_INVITE_LINK,
  LAB_START_PLANNING,
} from 'constants/analytics';

import {
  MORE_THAN_NORMAL_PRODUCTIVE,
  VERY_PRODUCTIVE,
} from 'constants/productivityStates';

import {
  CURRENT_SESSION,
  NAV_DASHBOARD,
  NAV_GO_SERENE,
  NAV_SESSIONS,
} from 'constants/routingPaths';
import { DAY_FINISHED, IDLE } from 'constants/sessionManagerStates';
import BROWSERS from 'constants/browsers';

import { secondsToHours, secondsToMinutes } from 'helpers/time.helper';
import Analytics from 'helpers/universal-analytics.helper';
import { diffDates } from 'helpers/date.helper';

import { ReactComponent as ArrowIcon } from 'images/left-arrow.svg';

import {
  IS_EXTENSION_INSTALLED,
  USER_EMAIL,
  USER_SEGMENT,
  IS_UPGRADE_MODAL_VISIBLE,
  IS_USER_PRO,
} from 'messages/available-settings';

import {
  IPC_GET_SETTINGS,
  IPC_SETTING_CHANGED,
  IPC_SM_GET_DATA,
  IPC_SM_SESSIONS_UPDATED,
  IPC_SM_STATE_CHANGED,
  IPC_SM_GET_DAY_DATA,
  IPC_SM_DATE_CHANGED,
  IPC_SM_CHANGE_DAY,
  IPC_SM_RELOAD_DAY,
  IPC_CHANGE_SETTINGS,
} from 'messages/ipc-messages';

import './Dashboard.scss';
import SecondaryNavigationHeader from 'components/SecondaryNavigationHeader';
import { UserContext } from 'contexts/UserProvider';

const { ipcRenderer, clipboard } = window.require('electron');

const getCorrectString = (todayText, tomorrowText, date) => {
  const { isToday, isTomorrow } = diffDates(date);

  if (isToday) {
    return todayText;
  }

  if (isTomorrow) {
    return tomorrowText;
  }

  return moment(date).format(DATE_FORMAT_APP);
};

const checkTodayProductive = productivity =>
  [VERY_PRODUCTIVE.id, MORE_THAN_NORMAL_PRODUCTIVE.id].includes(productivity);

class Dashboard extends Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  state = {
    email: '',
    date: moment(),
    sendEmailLoaded: false,
    isExtensionInstalled: false,
    sessionManagerState: '',
    goal: '',
    tomorrowGoal: '',
    sessionsLength: 0,
    finishedSessions: 0,
    time: {
      hours: 0,
      minutes: 0,
    },
    userSegment: '',
    copyLinkClicked: false,
    wasProductiveDay: false,
    productivity: '',
    inviteCode: '',
  };

  componentDidMount() {
    const {
      state: sessionManagerState,
      date,
      currentDayData,
    } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
    const { productivity } = currentDayData;

    const { goal, sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);

    ipcRenderer.on(IPC_SM_DATE_CHANGED, (event, date) => {
      const { goal, sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
      const { currentDayData } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
      const { productivity } = currentDayData;

      this.setState({
        goal,
        productivity,
        sessions,
        date: moment(date),
        sessionsLength: sessions.length,
      });
    });

    ipcRenderer.on(IPC_SM_RELOAD_DAY, () => {
      const { sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);

      this.setState({
        sessionsLength: sessions.length,
        finishedSessions: 0,
        time: {
          hours: 0,
          minutes: 0,
        },
        wasProductiveDay: false,
        productivity: '',
      });
    });

    const finishedSessions = sessions.filter(session => session.isFinished);
    let time = 0;

    Analytics.trackPageView(NAV_DASHBOARD, LAB_DASHBOARD_VIEW);

    if (finishedSessions.length > 0) {
      time = finishedSessions
        .map(session => session.counterStartTime)
        .reduce((acc, curr) => acc + curr);
    }

    const {
      isExtensionInstalled,
      userSegment,
      userEmail,
    } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      IS_EXTENSION_INSTALLED,
      USER_SEGMENT,
      USER_EMAIL,
    ]);

    this.setState({
      sessionManagerState,
      isExtensionInstalled,
      goal,
      date: moment(date),
      sessionsLength: sessions.length,
      finishedSessions: finishedSessions.length,
      time: {
        hours: secondsToHours(time, false),
        minutes: secondsToMinutes(time),
      },
      userSegment,
      userEmail,
      wasProductiveDay: checkTodayProductive(productivity),
      productivity,
    });

    ipcRenderer.on(IPC_SM_SESSIONS_UPDATED, (event, sessions) => {
      this.setState({ sessionsLength: sessions.length });
    });

    ipcRenderer.on(IPC_SM_STATE_CHANGED, (event, newState) => {
      this.setState({ sessionManagerState: newState });
    });

    ipcRenderer.on(IPC_SETTING_CHANGED, this.handleSettingChange);

    this.getNumberOfAvailableInvitations(userEmail);
  }

  componentWillUnmount() {
    ipcRenderer.removeAllListeners(IPC_SM_SESSIONS_UPDATED);
    ipcRenderer.removeAllListeners(IPC_SM_STATE_CHANGED);
    ipcRenderer.removeListener(IPC_SETTING_CHANGED, this.handleSettingChange);
    clearTimeout(this.timeout);
  }

  handleSendEmail = value => {
    this.setState({ invitationCode: value });
  };

  handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_EXTENSION_INSTALLED) {
      this.setState({ isExtensionInstalled: newValue });
    }
  };

  handleCopyLink = async () => {
    const referralUrl = `${SERENE_DOWNLOAD_LINK}/${SERENE_DOWNLOAD_QUERY_PARAM}${SERENE_DOWNLOAD_REF_PREFIX}${this.state.invitationCode}`;

    clipboard.writeText(referralUrl);
    this.onCopyLinkClicked();

    Analytics.trackEvent(
      CAT_REFERRAL,
      ACT_CLICK,
      LAB_REFERRAL_COPY_INVITE_LINK,
    );
  };

  onCopyLinkClicked = () => {
    this.setState({ copyLinkClicked: true });
    this.timeout = setTimeout(
      () => this.setState({ copyLinkClicked: false }),
      3000,
    );
  };

  getContentCTA = () => {
    let buttonText = '';
    let buttonEventLabel = '';
    let destination = CURRENT_SESSION;
    const { sessionManagerState, sessionsLength, date } = this.state;

    const { isToday } = diffDates(date);

    if (
      !isToday ||
      sessionManagerState === IDLE ||
      sessionManagerState === DAY_FINISHED
    ) {
      destination = NAV_SESSIONS;

      if (sessionsLength === 0) {
        buttonText = 'Start planning';
        buttonEventLabel = LAB_START_PLANNING;
      } else {
        buttonText = 'Continue planning';
        buttonEventLabel = LAB_CONTINUE_PLANNING;
      }
    } else {
      destination = NAV_GO_SERENE;
      buttonText = 'Continue session';
      buttonEventLabel = LAB_CONTINUE_SESSION;
    }

    return { buttonText, destination, buttonEventLabel };
  };

  handleTomorrowClick = () => {
    const isUserPro = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_USER_PRO);

    if (!isUserPro) {
      ipcRenderer.send(IPC_CHANGE_SETTINGS, {
        name: IS_UPGRADE_MODAL_VISIBLE,
        value: true,
      });
      return;
    }

    const momentDate = moment()
      .add(1, 'days')
      .startOf('day');

    ipcRenderer.send(IPC_SM_CHANGE_DAY, { date: momentDate.valueOf() });
    this.props.history.push(NAV_SESSIONS);
  };

  render() {
    const {
      date,
      goal,
      finishedSessions,
      time: { hours, minutes },
      isExtensionInstalled,
      userSegment,
      invitationCode,
      copyLinkClicked,
      wasProductiveDay,
      productivity,
      sessionsLength,
    } = this.state;

    const momentDate = moment(date);

    const { isToday } = diffDates(momentDate);
    const { buttonText, destination, buttonEventLabel } = this.getContentCTA();

    const dashboardContent = () => {
      if (wasProductiveDay && isToday) {
        return (
          <div className="Dashboard__great-work">
            <div className="great-work__heading">
              <h1>
                <span role="img" aria-label="raising-hands">
                  🙌
                </span>
              </h1>
              <h1 className="heading__title">Great work!</h1>
              <h2 className="heading__subtitle">
                You were more productive than usual today
              </h2>
            </div>
            <UserContext.Consumer>
              {context => {
                const { user } = context.state;
                return user.invites > 0 ? (
                  <GreatWork
                    numberOfInvitations={user.invites}
                    handleCopyLink={this.handleCopyLink}
                    copyLinkClicked={copyLinkClicked}
                    userSegment={userSegment}
                  />
                ) : null;
              }}
            </UserContext.Consumer>
            <div className="greak-work__plan-tomorrow">
              <button onClick={this.handleTomorrowClick}>Plan tomorrow</button>
            </div>
          </div>
        );
      }

      if (isToday && productivity && checkTodayProductive(productivity)) {
        return (
          <div className="Dashboard__goal">
            <h1> Start preparing tomorrow sessions </h1>
            <GoSereneButton
              showIcon={false}
              buttonText="Plan tomorrow"
              callback={this.handleTomorrowClick}
              analyticsEvent={[CAT_DASHBOARD, ACT_CLICK, 'PlanTomorrow']}
            />
          </div>
        );
      }

      const goalTitleContent = () => {
        let content = '';
        if (goal) {
          if (isToday) {
            content =
              sessionsLength > 0
                ? 'Start your session'
                : 'Start planning todays session';
          } else {
            content = 'Start preparing sessions';
          }
        } else {
          content = `Set ${getCorrectString(
            `today\u0027s`,
            `tomorrow\u0027s`,
            momentDate,
          )} goal`;
        }

        const classes = classnames('Dashboard__goal-title', {
          'Dashboard__goal-title--no-goal': !goal,
        });

        return <h1 className={classes}>{content}</h1>;
      };

      return (
        <React.Fragment>
          <div className="Dashboard__goal">
            {!!goal && (
              <React.Fragment>
                <p className="Dashboard__goal-intro">
                  {getCorrectString(
                    'Today\u0027s',
                    'Tomorrow\u0027s',
                    momentDate,
                  )}{' '}
                  Goal
                </p>
                <h2 className="Dashboard__goal-text">{goal}</h2>
              </React.Fragment>
            )}

            {goalTitleContent()}

            <div className="Dashboard__help-arrow">
              <ArrowIcon />
              <GoSereneButton
                showIcon={false}
                buttonText={buttonText}
                destination={destination}
                analyticsEvent={[CAT_DASHBOARD, ACT_CLICK, buttonEventLabel]}
              />
            </div>
          </div>

          {!isExtensionInstalled && (
            <div className="Dashboard__footer">
              <h2>
                Block distracting websites with the Serene Chrome Extension
              </h2>

              <InstallBrowserExtensionButton browser={BROWSERS.CHROME} />
            </div>
          )}
        </React.Fragment>
      );
    };

    return (
      <Layout className="Layout--super-wide">
        <Menu hasNavigation>
          <div className="Dashboard">
            <SecondaryNavigationHeader>Dashboard</SecondaryNavigationHeader>
            <div className="Dashboard__blocks Dashboard__blocks--halfs">
              <div className="Dashboard__block">
                <div className="Dashboard__block-value">{finishedSessions}</div>
                <div className="Dashboard__block-title">Sessions</div>
              </div>
              <div className="Dashboard__block">
                <div className="Dashboard__block-value">
                  {hours}:{minutes}
                </div>
                <div className="Dashboard__block-title">Serene Hours</div>
              </div>
            </div>

            <div className="Dashboard__blocks">
              <div className="Dashboard__block Dashboard__block--graph">
                <WeekPlanner date={date} />
              </div>
            </div>

            <div className="Dashboard__block-distractions">
              <h2>Just want to block distractions?</h2>

              <p>
                Stop emails, social media, notifications, etc from taking your
                focus.
              </p>

              <GoSereneButton
                showIcon={false}
                buttonClass="btn--double-gradient"
                buttonText="Go Serene"
                analyticsEvent={[
                  CAT_DASHBOARD,
                  ACT_CLICK,
                  LAB_CLICKS_BLOCK_DISTRACTIONS,
                ]}
              />
            </div>
          </div>
        </Menu>

        <Content>
          <div className="Dashboard">{dashboardContent()}</div>
          <UserContext.Consumer>
            {context => {
              const { user } = context.state;
              return user.invites > 0 && !wasProductiveDay ? (
                <NotificationCard
                  heading={
                    <React.Fragment>
                      You have{' '}
                      <span className="heading__number">{user.invites}</span>{' '}
                      invitations
                    </React.Fragment>
                  }
                  content={`Know a ${userSegment} who might like Serene?`}
                >
                  <SendEmail onChange={this.handleSendEmail} small />

                  {invitationCode && (
                    <p className="NotificationCard__copy-link">
                      <span
                        onClick={this.handleCopyLink}
                        className={classnames({ clicked: copyLinkClicked })}
                      >
                        {copyLinkClicked
                          ? 'Copied to clipboard'
                          : 'Copy invite link'}
                      </span>
                    </p>
                  )}
                </NotificationCard>
              ) : null;
            }}
          </UserContext.Consumer>
        </Content>
      </Layout>
    );
  }
}

export default Dashboard;
