import React, { PureComponent } from 'react';

import socket from '../../socket';

import './Footer.scss';

class Footer extends PureComponent {
  state = {
    isSocketConnected: false,
  };

  componentDidMount() {
    if (socket.connected) {
      this.setState({ isSocketConnected: true });
    }

    socket.on('connect', () => {
      this.setState({ isSocketConnected: true });
    });

    socket.on('connect_error', () => {
      this.setState({ isSocketConnected: false });
    });

    socket.on('reconnect', () => {
      this.setState({ isSocketConnected: true });
    });
  }

  componentWillUnmount() {
    socket.off('connect');
    socket.off('connect_error');
    socket.off('reconnect');
  }

  render() {
    const { isSocketConnected } = this.state;

    if (isSocketConnected) {
      return null;
    }

    return <div className="Footer">Disconnected</div>;
  }
}

export default Footer;
