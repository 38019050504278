import React from 'react';

const Loader = () => (
  <div className="container">
    <div className="loader">
      <span />
      <span />
      <span />
      <span />
    </div>
    <style jsx>
      {`
        .container {
          position: relative;
          height: 100vh;
        }

        .loader {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 40px;
          height: 70px;
          margin: auto;
        }

        span {
          position: relative;
          display: block;
          width: 27px;
          height: 7px;
          background-image: url('./images/loader-path.png');
          background-size: cover;
          opacity: 0;

          animation: changePosition ease 2.4s infinite;
        }
        span:nth-child(odd) {
          margin-left: 10px;
        }

        span:not(:last-child) {
          margin-bottom: 4px;
        }

        span:nth-child(1) {
          animation-delay: 800ms;
        }

        span:nth-child(2) {
          animation-delay: 600ms;
        }

        span:nth-child(3) {
          animation-delay: 400ms;
        }

        span:nth-child(4) {
          animation-delay: 200ms;
        }

        @keyframes changePosition {
          from {
            top: -50px;
            opacity: 0;
          }

          10% {
            top: 0;
            opacity: 1;
          }

          40% {
            top: 0;
            opacity: 1;
          }

          50%,
          100% {
            top: 50px;
            opacity: 0;
          }
        }
      `}
    </style>
  </div>
);

export default Loader;
