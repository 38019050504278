import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const SettingsContent = ({ children, header, tagline, className }) => {
  const classNames = classnames('SettingsContent', className);
  tagline = Array.isArray(tagline)
    ? tagline.map((el, i) => <p key={i}>{el}</p>)
    : tagline;
  return (
    <div className={classNames}>
      <h1 className="Settings__header">{header}</h1>
      <div className="Settings__tagline">{tagline}</div>
      <div className="SettingsContent__wrapper">{children}</div>
    </div>
  );
};

SettingsContent.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  tagline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  className: PropTypes.string,
};

export default SettingsContent;
