import React from 'react';
import PropTypes from 'prop-types';
import ShadowDom from 'react-shadow';
import classnames from 'classnames';

import { DONE, HALFDONE, NOTDONE } from 'constants/sessionStates';
import { ReactComponent as DoneIconDeselected } from 'images/done-deselected-icon.svg';
import { ReactComponent as DoneIcon } from 'images/done-icon.svg';
import { ReactComponent as HalfDoneIcon } from 'images/half-done-icon.svg';
import { ReactComponent as HalfDoneIconDeselected } from 'images/half-done-inactive-icon.svg';
import { ReactComponent as NotDoneIconDeselected } from 'images/not-done-deselected-icon.svg';
import { ReactComponent as NotDoneIcon } from 'images/not-done-selected-icon.svg';

const StateRadioElement = ({
  value,
  valueText,
  selectedValue,
  onChange,
  isLabelVisible,
}) => {
  let RenderIcon;

  const boxClass = classnames('StateRadio__box', {
    'StateRadio__box--done': selectedValue === value && value === DONE,
    'StateRadio__box--half-done': selectedValue === value && value === HALFDONE,
    'StateRadio__box--not-done': selectedValue === value && value === NOTDONE,
  });

  if (selectedValue === value) {
    switch (value) {
      case DONE:
        RenderIcon = DoneIcon;
        break;
      case HALFDONE:
        RenderIcon = HalfDoneIcon;
        break;
      case NOTDONE:
        RenderIcon = NotDoneIcon;
        break;
      default:
        break;
    }
  } else {
    switch (value) {
      case DONE:
        RenderIcon = DoneIconDeselected;
        break;
      case HALFDONE:
        RenderIcon = HalfDoneIconDeselected;
        break;
      case NOTDONE:
        RenderIcon = NotDoneIconDeselected;
        break;
      default:
        break;
    }
  }

  const svgStyle = isLabelVisible
    ? `
      svg {
        width: 40px;
        height: 40px;
      }
  `
    : `
      svg {
        margin-top: 5px;
        width: 50px;
        height: 50px;
      }
  `;

  return (
    <label>
      <input
        type="radio"
        name="session-state"
        value={value}
        checked={selectedValue === value}
        onChange={onChange}
        onClick={onChange}
      />
      <div className={boxClass}>
        <ShadowDom>
          <div>
            <RenderIcon />
            <style type="text/css">{svgStyle}</style>
          </div>
        </ShadowDom>
        {isLabelVisible && (
          <div className="StateRadio__text"> {valueText} </div>
        )}
      </div>
    </label>
  );
};

StateRadioElement.propTypes = {
  value: PropTypes.string.isRequired,
  valueText: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLabelVisible: PropTypes.bool.isRequired,
};

export default StateRadioElement;
