import moment from 'moment';

export const _getFirstThreeWeeksFromGraph = (
  visibleWeekFirstDayIndex,
  points,
) => {
  const THREE_WEEKS = 21;

  const firstIndex = 7;
  const lastIndex = visibleWeekFirstDayIndex + THREE_WEEKS;

  return points.slice(firstIndex, lastIndex);
};

export const _getPointsAroundCurrentlyVisibleWeek = (
  visibleWeekFirstDayIndex,
  points,
) => {
  const ONE_WEEK = 7;
  const TWO_WEEKS = 14;

  const firstIndex = visibleWeekFirstDayIndex - ONE_WEEK;
  const lastIndex = visibleWeekFirstDayIndex + TWO_WEEKS;

  return points.slice(firstIndex, lastIndex);
};

export const newSubarrayOfVisiblePoints = (
  currentlySelectedPointIndex,
  points,
) => {
  let selectedDateWeekDay =
    moment(points[currentlySelectedPointIndex].date).day() - 1;

  if (selectedDateWeekDay < 0) {
    selectedDateWeekDay = 6;
  }

  const visibleWeekFirstDayIndex =
    currentlySelectedPointIndex - selectedDateWeekDay;

  const isOnBeginningOfGraph = visibleWeekFirstDayIndex < 7;

  if (isOnBeginningOfGraph) {
    return _getFirstThreeWeeksFromGraph(visibleWeekFirstDayIndex, points);
  }

  return _getPointsAroundCurrentlyVisibleWeek(visibleWeekFirstDayIndex, points);
};
