// Page Views
// // Paths
export const PATH_ASSESSING = '/assessing';
export const PATH_BREAK = '/break';
export const PATH_ADD_SESSION = '/add-session';
export const PATH_EDIT_SESSION = '/edit-session';
export const PATH_CHECKOUT = '/checkout';
export const PATH_TEAMS = '/teams';

// // Labels
export const LAB_SET_DAY_GOAL_VIEW = 'Set Day Goal View';
export const LAB_CURRENT_SESSION_VIEW = 'Current Session View';
export const LAB_DASHBOARD_VIEW = 'Dashboard View';
export const LAB_ASSESSING_SESSION_VIEW = 'Assessing Session View';
export const LAB_BREAK_VIEW = 'Break View';
export const LAB_GO_SERENE_VIEW = 'Go Serene View';
export const LAB_PLAN_SESSIONS_VIEW = 'Plan Sessions View';
export const LAB_EDIT_SESSION_VIEW = 'Edit Session View';
export const LAB_ADD_SESSION_VIEW = 'Add Session View';
export const LAB_BLOCK_APPS_SETTINGS = 'Block Apps Settings View';
export const LAB_BLOCK_SITES_SETTINGS = 'Block Sites Settings View';
export const LAB_MUSIC_SETTINGS_VIEW = 'Music Settings View';
export const LAB_WEBHOOK_SETTINGS_VIEW = 'Webhook Settings View';
export const LAB_SESSION_SETTINGS_VIEW = 'Session Settings View';
export const LAB_ONBOARDING_VIEW = 'Onboarding View';
export const LAB_PAYMENT_MODAL_VIEW = 'Payment Modal View';
export const LAB_TEAM_WELCOME_VIEW = 'Team Welcome View';
export const LAB_TEAM_INVITES_VIEW = 'Team Invites View';
export const LAB_TEAM_MEMBER_WELCOME_VIEW = 'Team Member Welcome View';
export const LAB_TEAM_DASHBOARD_VIEW = 'Team Dashboard View';
export const LAB_TEAM_ONBOARDING_VIEW = 'Team Onboarding View';

// Events
// // Categories
export const CAT_BLOCKED_APPS = 'Blocked Apps';
export const CAT_BLOCKED_SITES = 'Blocked Websites';
export const CAT_GONE_SERENE = 'Gone Serene';
export const CAT_ONBOARDING = 'Onboarding';
export const CAT_DASHBOARD = 'Dashboard';
export const CAT_BREAK = 'Break';
export const CAT_SETTINGS = 'Settings';
export const CAT_SESSION_LIST = 'Session List';
export const CAT_ADD_SESSION = 'Add Session';
export const CAT_ASSESSING = 'Assessing';
export const CAT_SESSION_OVERVIEW = 'Session Overview';
export const CAT_REFERRAL = 'Referral';
export const CAT_PADDLE_UPGRADE = 'Upgrade';
export const CAT_PADDLE_INFO = 'Paddle Info';

// // Actions
export const ACT_CLICK = 'Click';
export const ACT_BLOCKED = 'Blocked';
export const ACT_UPDATE_SESSION_NUMBER = 'update session number';
export const ACT_UPDATE_SESSION_TIME = 'update session time';
export const ACT_SAVE_SESSION = 'Save Session';
export const ACT_END_BREAK = 'End Break';
export const ACT_SUBMIT_ONBOARDING_FORM = 'Onboarding Segment';

// // Labels
export const LAB_INSTALL_CHROME_EXTENSION = 'install-chrome-extension';
export const LAB_INSTALL_FIREFOX_EXTENSION = 'install-firefox-extension';
export const LAB_ENTER_SERENE = 'enter-serene-mode';
export const LAB_CHOOSE_GO_SERENE = 'chooses-go-serene';
export const LAB_CHOOSE_DAY_PLAN = 'chooses-day-plan';
export const LAB_JOIN_MAILING_LIST = 'joins-mailing-list';
export const LAB_SESSIONS_NUMBER = 'sessions-number';
export const LAB_SESSION_TIME = 'session-time';
export const LAB_EDITED_SESSION = 'edited-session';
export const LAB_ADDED_SESSION = 'added-session';
export const LAB_SESSION_TASK = 'session-task';
export const LAB_BREAK_TIME_REMAINING = 'break-time-remaining';
export const LAB_DISTRACTIONS = 'distractions';
export const LAB_SESSION_STATE = 'session-state';
export const LAB_CLOSES_CARD = 'closes-card';
export const LAB_CARD_LINK = 'clicks-card-link';
export const LAB_START_PLANNING = 'start-planning';
export const LAB_CONTINUE_PLANNING = 'continue-planning';
export const LAB_CONTINUE_SESSION = 'continue-session';
export const LAB_EXIT_SERENE_MODE = 'exit-serene-mode';
export const LAB_MUTE_MUSIC = 'mute-music';
export const LAB_BLOCK_SITE = 'block-site';
export const LAB_BLOCK_APP = 'block-app';
export const LAB_CLICKS_BLOCK_DISTRACTIONS = 'clicks-block-distractions';
export const LAB_REFERRAL_SEND_EMAIL_TO_FRIEND =
  'referral-great-work-send-email-to-a-friend';
export const LAB_REFERRAL_COPY_INVITE_LINK =
  'referral-great-work-copy-invite-link';
export const LAB_PADDLE_CLICK_PRO = 'paddle-click-pro';
export const LAB_PADDLE_CHECKOUT_ERROR = 'paddle-error';
export const LAB_PADDLE_CHECKOUT_CLOSE = 'paddle-close';
export const LAB_PADDLE_CHECKOUT_SUCCESS = 'paddle-checkout-success';
