import React from 'react';
import PropTypes from 'prop-types';

import { getInitials } from 'helpers/text.helper';

import theme from 'style/theme';

const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

const borderWidth = '2';

const Avatar = ({ name, image = false, status }) => {
  const color = stringToHslColor(name, 30, 80);

  return (
    <div className={`Avatar-wrapper Avatar-wrapper--${status}`}>
      {image ? (
        <img src={image} alt="" className="Avatar" />
      ) : (
        <div className="Avatar">{getInitials(name).toUpperCase()}</div>
      )}
      <style jsx>
        {`
          .Avatar {
            width: 30px;
            height: 30px;
            display: inline-block;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
            border-radius: 50%;
            display: inline-grid;
            margin-right: 0.5em;
            place-content: center;
            position: relative;
            z-index: 2;
            margin: ${borderWidth}px;
            margin-right: 0.5em;
            font-size: 12px;
          }

          .Avatar::before,
          .Avatar::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 18px;
            content: '';
          }

          .Avatar::before {
            content: '';
            display: block;
            position: absolute;
            top: -${borderWidth}px;
            left: -${borderWidth}px;
            width: calc(100% + ${borderWidth * 2}px);
            height: calc(100% + ${borderWidth * 2}px);
            background: linear-gradient(
              ${theme.color.bgGradientDark},
              ${theme.color.bgGradientLight}
            );
            border-radius: 20px;
          }

          .Avatar::after {
            background-color: ${color};
          }

          .Avatar-wrapper--online .Avatar::before {
            background: linear-gradient(#4dccae, #2ddd9d);
          }

          .Avatar-wrapper--offline .Avatar::before {
            background: ${theme.color.white};
          }

          .Avatar-wrapper {
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  status: PropTypes.oneOf(['online', 'offline', 'serene']),
};

export default Avatar;
