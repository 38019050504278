import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { NAV_DASHBOARD, ONBOARDING } from 'constants/routingPaths';
import {
  FIREBASE_UID,
  IS_FIRST_VISIT_DONE,
  USER_EMAIL,
  USER_SEGMENT,
  USER_WEEKLY,
} from 'messages/available-settings';
import { IPC_GET_SETTINGS } from 'messages/ipc-messages';

import {
  checkIfUserDataSavedOnServer,
  saveOnboardingDataOnServer,
  saveServerStatusToSettings,
} from 'helpers/saveOnboardingData.helper';

const { ipcRenderer } = window.require('electron');

const saveDataOnServer = async () => {
  const {
    userEmail: email,
    userSegment: segment,
    userWeekly: weekly,
  } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
    USER_EMAIL,
    USER_SEGMENT,
    USER_WEEKLY,
  ]);

  const dataSavedSuccessfully = await saveOnboardingDataOnServer(
    {
      email,
      segment,
      weekly,
    },
    {
      showSuccessToast: true,
      showFailureToast: false,
    },
  );

  saveServerStatusToSettings(dataSavedSuccessfully);
};

const FirstVisitRoute = memo(props => {
  const { isFirstVisitDone, firebaseUID } = ipcRenderer.sendSync(
    IPC_GET_SETTINGS,
    [IS_FIRST_VISIT_DONE, FIREBASE_UID],
  );

  const isUserDataSavedOnServer = checkIfUserDataSavedOnServer();

  if (!isUserDataSavedOnServer && isFirstVisitDone && firebaseUID === '') {
    saveDataOnServer();
  }

  const pathname = isFirstVisitDone ? NAV_DASHBOARD : ONBOARDING;

  return <Route {...props} render={() => <Redirect to={{ pathname }} />} />;
});

export default FirstVisitRoute;
