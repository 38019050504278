import React, { useState, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import isEqual from 'lodash/fp/isEqual';

import { updateProfile, createAccount } from 'helpers/firebase.helper';
import {
  FIREBASE_UID,
  USER_SEGMENT,
  USER_WEEKLY,
  USER_EMAIL,
} from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS, IPC_GET_SETTINGS } from 'messages/ipc-messages';

import { auth, firestore } from '../firebase';
import SettingsAccountConfirmation from 'views/Navigation/Settings/SettingsAccount/SettingsAccountConfirmation/SettingsAccountConfirmation.component';

const { ipcRenderer } = window.require('electron');

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [userData, profileLoading, error] = useDocumentData(
    user ? firestore.collection('profiles').doc(user.uid) : null,
  );
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [lockConfirmation, setLockConfirmation] = useState(false);

  useMemo(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useMemo(() => {
    if (user) {
      ipcRenderer.send(IPC_CHANGE_SETTINGS, [
        {
          name: FIREBASE_UID,
          value: user.uid,
        },
      ]);
    }
  }, [user]);

  useMemo(async () => {
    if (userData) {
      const { userSegment, userWeekly } = ipcRenderer.sendSync(
        IPC_GET_SETTINGS,
        [USER_SEGMENT, USER_WEEKLY],
      );

      const electronSettingsProfile = {
        segment: userSegment || false,
        weekly: userWeekly || false,
      };
      // If settings are not the same, push the electronSettings.
      if (!isEqual(userData, electronSettingsProfile)) {
        updateProfile(user.uid, electronSettingsProfile);
      }
    }
  }, [userData]);

  const showConfirmation = ({ email = false, locked = false }) => {
    if (!email) {
      email = ipcRenderer.sendSync(IPC_GET_SETTINGS, USER_EMAIL);
    }
    setLockConfirmation(locked);
    setConfirmationEmail(email);
    setDisplayConfirmation(true);
  };

  const closeConfirmation = () => {
    setDisplayConfirmation(false);
  };

  useMemo(async () => {
    if (!loading && !user) {
      const settingsEmail = ipcRenderer.sendSync(IPC_GET_SETTINGS, USER_EMAIL);
      if (settingsEmail) {
        try {
          await createAccount(settingsEmail);
        } catch (error) {
          // The likelyhood is an error happens here, and we need to confirm the account.
          if (error.message === 'User-login') {
            showConfirmation({ email: settingsEmail, locked: true });
          }
          console.log(error);
        }
      }
    }
  }, [user, loading]);

  return (
    <UserContext.Provider
      value={{
        state: {
          user: { firebaseUserId: user ? user.uid : null, ...userData },
        },
        showConfirmation,
        loading: loading || profileLoading,
      }}
    >
      <SettingsAccountConfirmation
        show={displayConfirmation}
        locked={lockConfirmation}
        user={{ email: confirmationEmail }}
        onClose={closeConfirmation}
      />

      {children}
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default UserProvider;
