import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { diffDates } from 'helpers/date.helper';
import Graph from './Graph.component';

import { DAY_DEBRIEF } from 'constants/routingPaths';
import { GRAPH_DATA } from 'messages/available-settings';
import { IPC_SM_CHANGE_DAY, IPC_GET_SETTINGS } from 'messages/ipc-messages';

import './WeekPlanner.scss';

const { ipcRenderer } = window.require('electron');

const { getSelectedDayData } = window
  .require('electron')
  .remote.require('../src/helpers/Graph/getSelectedDay.helper');

const prepareDataForView = data =>
  data.sessions.map(session => ({
    ...session,
    startingFrom: ' ',
    number: session.number - 1,
    isFinished: true,
    counterStartTime: 0,
    isActive: false,
  }));

const fixPreviousDashes = (plannerData, index) => {
  plannerData.forEach(element => (element.fixDashes = false));

  const element = plannerData[index - 1];
  if (!element) {
    return;
  }

  element.fixDashes = true;
};

const WeekPlanner = ({ history, date }) => {
  const plannerData = ipcRenderer.sendSync(IPC_GET_SETTINGS, GRAPH_DATA);

  plannerData.map((point, index) => {
    point.isActive = date.startOf('day').isSame(point.date);

    if (point.isActive && !point.isToday) {
      fixPreviousDashes(plannerData, index);
    }

    return point;
  });

  const onPointClick = async (pointDate, index) => {
    const { isPastDay } = diffDates(pointDate);

    if (isPastDay) {
      const selectedPastData = await getSelectedDayData(pointDate);
      selectedPastData.sessions = prepareDataForView(selectedPastData);

      history.push({
        pathname: DAY_DEBRIEF,
        state: {
          data: selectedPastData,
        },
      });

      return;
    }

    ipcRenderer.send(IPC_SM_CHANGE_DAY, { date: pointDate.valueOf() });
  };

  return (
    <div className="WeekPlanner">
      <div className="WeekPlanner__title">Week Planner</div>
      <div className="WeekPlanner__graph">
        <Graph onPointClick={onPointClick} points={plannerData} />
      </div>
    </div>
  );
};

WeekPlanner.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(WeekPlanner);
