import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TipIcon } from 'images/tip-icon.svg';

import './TipBox.scss';

const TipBox = ({ width }) => (
  <div className="TipBox">
    <div className="TipBox__icon">
      <TipIcon />
    </div>
    <div className="TipBox__text">
      <p>
        <strong>Tip: </strong>
        You&apos;re more likely to achieve your goal if you specify a clear,
        measurable criteria for success. <br /> The goal quality meter will give
        you an indication of how objective your goal is.
      </p>
    </div>
    <div className="TipBox__gauge">
      <div className="gauge__title">Goal Quality Meter</div>
      <div className="gauge__progress-box">
        <div className="progress-box__scale">Low</div>
        <div className="progress-box__progess-bar">
          <div className="progess-bar__clip">
            <div className="clip__fill" style={{ width }} />
          </div>
        </div>
        <div className="progress-box__scale">High</div>
      </div>
    </div>
  </div>
);

export default TipBox;

TipBox.propTypes = {
  width: PropTypes.string.isRequired,
};
