import React, { Component } from 'react';
import { Toggle } from '@ventureharbour/serene-shared-components';

import { LAB_MUSIC_SETTINGS_VIEW } from 'constants/analytics';
import { NAV_SETTINGS_MUSIC } from 'constants/routingPaths';
import Analytics from 'helpers/universal-analytics.helper';
import { IS_MUSIC_ACTIVE } from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS, IPC_GET_SETTINGS } from 'messages/ipc-messages';

import SettingsContent from '../SettingsContent.component';

const { ipcRenderer } = window.require('electron');

class SettingsMusic extends Component {
  state = {
    isMusicActive: false,
  };

  componentDidMount() {
    const { isMusicActive } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      IS_MUSIC_ACTIVE,
    ]);

    this.setState({
      isMusicActive,
    });

    Analytics.trackPageView(NAV_SETTINGS_MUSIC, LAB_MUSIC_SETTINGS_VIEW);
  }

  handleToggle = value => {
    this.setState({ isMusicActive: value });
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_MUSIC_ACTIVE,
      value,
    });
  };

  render() {
    const { isMusicActive } = this.state;

    return (
      <SettingsContent header="Music" tagline="Configure your music settings.">
        <Toggle small active={isMusicActive} onChange={this.handleToggle}>
          Play concentration music
        </Toggle>
      </SettingsContent>
    );
  }
}

export default SettingsMusic;
