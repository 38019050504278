import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import Distractions from 'components/Distractions/Distractions.component';
import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';
import CompletedSessionList from 'components/Shared/SessionList/CompletedSessionList.component';
import CompletedSessionStack from 'components/Shared/SessionList/CompletedSessionStack.component';
import SessionItem from 'components/Shared/SessionList/SessionItem.component';
import NextSessionList from 'components/Shared/SessionList/NextSessionList.component';
import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';
import StateRadioGroup from 'components/StateRadioGroup/StateRadioGroup.component';
import {
  ACT_CLICK,
  CAT_ASSESSING,
  LAB_ASSESSING_SESSION_VIEW,
  LAB_DISTRACTIONS,
  LAB_SESSION_STATE,
  PATH_ASSESSING,
} from 'constants/analytics';
import { REVIEW_SESSION_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { DONE, HALFDONE, NOTDONE } from 'constants/sessionStates';
import Analytics from 'helpers/universal-analytics.helper';

import './AssessingView.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

class AssessingView extends React.Component {
  static propTypes = {
    sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isDayFinished: PropTypes.bool,
    onButtonClick: PropTypes.func.isRequired,
    onSessionStateChange: PropTypes.func,
    sessionState: PropTypes.string,
    latestSession: PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      isFinished: PropTypes.bool,
      startingFrom: PropTypes.string,
    }).isRequired,
  };

  state = {
    distractions: [],
    sessionState: '',
    showStateRadio: true,
  };

  componentDidMount() {
    Analytics.trackPageView(PATH_ASSESSING, LAB_ASSESSING_SESSION_VIEW);
  }

  onDistractionChange = distractions => {
    this.setState({
      distractions,
    });
  };

  onSessionStateChange = sessionState => {
    this.setState(
      {
        sessionState,
        // showStateRadio: false,
      },
      () => {
        this.props.onSessionStateChange(this.state.sessionState);
      },
    );
  };

  onClickSessionStateButton = () => {
    this.setState({
      showStateRadio: false,
    });
  };

  onClick = ({ detail }) => {
    /* fix for weird bug with double click button. When user clicks on button twice but first event is focusing serene app
    (on the button), second event actually clicks (time between both events 1-2s) on button onClick handler launches twice */
    if (detail === 1) {
      const { distractions, sessionState } = this.state;

      log(
        INFO,
        `Clicked ${
          this.props.isDayFinished ? 'reviewYourGoal' : 'prepareForNextSession'
        } button`,
        REVIEW_SESSION_VIEW,
      );

      this.props.onButtonClick(distractions);

      Analytics.trackEvent(
        CAT_ASSESSING,
        ACT_CLICK,
        LAB_DISTRACTIONS,
        distractions,
      );
      Analytics.trackEvent(
        CAT_ASSESSING,
        ACT_CLICK,
        LAB_SESSION_STATE,
        sessionState,
      );
    }
  };

  render() {
    const { showStateRadio } = this.state;
    const { sessions, isDayFinished, sessionState, latestSession } = this.props;

    return (
      <Layout>
        <Menu hasNavigation>
          <div className="AssessingView__navigation">
            {isDayFinished ? (
              <React.Fragment>
                <div>
                  <TodayGoal />
                  <h3>COMPLETE</h3>
                </div>
                <CompletedSessionList hasHeading sessions={sessions} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  <TodayGoal />
                  <h3>NEXT SESSION</h3>
                </div>
                <NextSessionList dragDisabled sessions={sessions} />
                <CompletedSessionStack hasHeading sessions={sessions} />
              </React.Fragment>
            )}
          </div>
        </Menu>
        <Content>
          <div className="AssessingView">
            {latestSession && (
              <div className="AssessingView__session-stack-container">
                <div className="AssessingView__session-stack">
                  <SessionItem
                    dragDisabled
                    key={latestSession.number}
                    isHoverActive={false}
                    {...latestSession}
                  />
                </div>
              </div>
            )}

            {showStateRadio ? (
              <div className="AssessingView__session-state">
                <h2 className="AssessingView__title">
                  Did you complete your task?
                </h2>
                <StateRadioGroup
                  isLabelVisible
                  availableOptions={[DONE, HALFDONE, NOTDONE]}
                  onChange={this.onSessionStateChange}
                />

                <Button
                  name="prepareForNextSession"
                  className="btn--big"
                  onClick={this.onClickSessionStateButton}
                  primary
                  disabled={sessionState === ''}
                >
                  Continue
                </Button>
              </div>
            ) : (
              <React.Fragment>
                <div className="AssessingView__distractions">
                  <h2 className="AssessingView__title">
                    Run into any distractions?
                  </h2>
                  <Distractions onChange={this.onDistractionChange} />
                </div>
                {isDayFinished ? (
                  <Button
                    name="reviewYourGoal"
                    className="btn--big"
                    onClick={this.onClick}
                    primary
                  >
                    Review your goal
                  </Button>
                ) : (
                  <Button
                    name="prepareForNextSession"
                    className="btn--big"
                    onClick={this.onClick}
                    primary
                  >
                    Prepare for next session
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}

export default AssessingView;
