import React from 'react';
import PropTypes from 'prop-types';

import { STATE_RADIO_GROUP } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { DONE, HALFDONE, NOTDONE } from 'constants/sessionStates';

import StateRadioElement from './StateRadioElement.component';

import './StateRadioGroup.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

const checkStates = visibleOptions => ({
  isDoneVisible: visibleOptions.includes(DONE),
  isHalfDoneVisible: visibleOptions.includes(HALFDONE),
  isNotDoneVisible: visibleOptions.includes(NOTDONE),
});

class StateRadioGroup extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    availableOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLabelVisible: PropTypes.bool,
    sessionState: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { sessionState } = props;

    this.state = {
      selected: sessionState ? sessionState : '',
    };
  }

  onChange = ({ target }) => {
    this.setState({
      selected: target.value,
    });

    log(INFO, `Selected value ${target.value}`, STATE_RADIO_GROUP);

    this.props.onChange(target.value);
  };

  render() {
    const { isLabelVisible, availableOptions } = this.props;
    const { isDoneVisible, isHalfDoneVisible, isNotDoneVisible } = checkStates(
      availableOptions,
    );

    const { selected } = this.state;
    return (
      <form className="StateRadioGroup">
        {isDoneVisible && (
          <StateRadioElement
            value={DONE}
            valueText={'DONE'}
            selectedValue={selected}
            onChange={this.onChange}
            onClick={this.onChange}
            isLabelVisible={isLabelVisible}
          />
        )}
        {isHalfDoneVisible && (
          <StateRadioElement
            value={HALFDONE}
            valueText={'HALF-DONE'}
            selectedValue={selected}
            onChange={this.onChange}
            onClick={this.onChange}
            isLabelVisible={isLabelVisible}
          />
        )}
        {isNotDoneVisible && (
          <StateRadioElement
            value={NOTDONE}
            valueText={'NOT-DONE'}
            selectedValue={selected}
            onChange={this.onChange}
            onClick={this.onChange}
            isLabelVisible={isLabelVisible}
          />
        )}
      </form>
    );
  }
}

export default StateRadioGroup;
