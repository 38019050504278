import React from 'react';
import PropTypes from 'prop-types';

import './NotificationCard.scss';

const NotificationCard = ({ children, onCardClick, heading, content }) => (
  <div className="NotificationCard" onClick={onCardClick}>
    {heading && <p className="NotificationCard__heading">{heading}</p>}
    {content && <p className="NotificationCard__content">{content}</p>}
    {children}
  </div>
);

NotificationCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  onCardClick: PropTypes.func,
};

export default NotificationCard;
