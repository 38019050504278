import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ConditionalLink extends PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired,
    shouldRenderLink: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { to, shouldRenderLink, children, className } = this.props;

    return shouldRenderLink ? (
      <Link to={to} className={className}>
        {children}
      </Link>
    ) : (
      <div className={className}>{children}</div>
    );
  }
}

export default ConditionalLink;
