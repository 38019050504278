import 'firebase/functions';

import firebase from 'firebase/app';
import 'firebase/auth';

import { FIREBASE_UID } from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS } from 'messages/ipc-messages';

import { auth, firestore } from '../firebase';

const { ipcRenderer } = window.require('electron');

export const observeUserSubscriptionChanges = (userId, callback) => {
  const query = firestore
    .collection('subscriptions')
    .where('owner', '==', userId)
    .limit(1);

  return query.onSnapshot(
    querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(documentSnapshot => {
          if (documentSnapshot.exists) {
            callback(documentSnapshot.data());
          }
        });
      }
    },
    error => {
      console.error(
        `Encountered error during observeUserSubscriptionChanges onSnapshot ${error}`,
      );
    },
  );
};

export const updateUsersTeamProfileData = async (userId, teamId, data) => {
  try {
    await firestore
      .collection(`team/${teamId}/member`)
      .doc(userId)
      .update(data);
  } catch (error) {
    console.error('Error in updateUsersTeamProfileData', error);
  }
};

export const updateProfileData = async (userId, data) => {
  try {
    await firestore
      .collection('profiles')
      .doc(userId)
      .update(data);
  } catch (error) {
    console.error('Error in updateProfileData', error);
  }
};

export const updateProfile = async (userId, { segment, weekly, invitedBy }) => {
  try {
    await updateProfileData(userId, {
      segment,
      weekly,
    });
  } catch (error) {
    console.error('Error in updateProfile', error);
  }
};

export const removeFromTeam = async (userId, teamId) => {
  try {
    const httpsOnRemoveUser = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsRemoveUser');
    await httpsOnRemoveUser({ userId, teamId });
    return true;
  } catch (error) {
    console.error('Error in removeFromTeam', error);
  }
};

export const createAccount = async email => {
  try {
    const httpsOnCreateAccount = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsOnCreateAccount');
    const { data } = await httpsOnCreateAccount({ email });
    const user = await auth.signInWithCustomToken(data);
    await ipcRenderer.send(IPC_CHANGE_SETTINGS, [
      {
        name: FIREBASE_UID,
        value: user.uid,
      },
    ]);

    return user;
  } catch (error) {
    if (error.code === 'already-exists') {
      throw new Error('User-login');
    } else {
      console.error(error);
    }
  }
};

export const logout = async () => {
  try {
    await firebase.auth().signOut();
  } catch (e) {
    console.error(e);
  }
};

export const requestLoginCode = async email => {
  try {
    const httpsOnLoginRequest = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsOnLoginRequest');
    await httpsOnLoginRequest({ email });
    return true;
  } catch (e) {
    console.error(`Error in requestLoginCode`, e);
    throw e;
  }
};

export const redeemClaim = async (email, code) => {
  try {
    const httpsOnLoginConfirm = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsOnLoginConfirm');
    const { data } = await httpsOnLoginConfirm({ email, code });
    await firebase.auth().signInWithCustomToken(data);
    return true;
  } catch (e) {
    console.error('redeemClaim', e);
    throw e;
  }
};

export const sendReferralEmail = async emails => {
  const httpsOnReferral = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('httpsOnReferral');
  return await httpsOnReferral({ emails });
};

export const inviteUsers = async (teamId, emails) => {
  try {
    const httpsInviteUsers = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsInviteUsers');
    await httpsInviteUsers({ teamId, emails });
  } catch (error) {
    console.error('inviteUsers', error);
  }
};

export const createTeam = async (teamName, teamLogo) => {
  try {
    const httpsOnCreateTeam = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('httpsOnCreateTeam');
    await httpsOnCreateTeam({ teamName, teamLogo });
    return true;
  } catch (error) {
    if (error.code === 'unauthenticated') {
      throw new Error('User-login');
    }
    if (error.code === 'invalid-argument') {
      // Commence validation fun..
      return false;
    }
  }
};

export const revokeTeamUser = async (email, teamId) => {
  try {
    await firestore
      .collection(`teams/${teamId}/invites`)
      .doc(email)
      .delete();
  } catch (error) {
    console.error('there was an error in revokeTeamUser function', error);
  }
};
