import React from 'react';
import PropTypes from 'prop-types';

import './DistractionItem.scss';

const DistractionItem = ({ index, distraction, onRemoveClick }) => (
  <div className="DistractionItem">
    <div className="DistractionItem__text">{distraction}</div>
    <div
      className="DistractionItem__remove"
      onClick={() => {
        onRemoveClick(index);
      }}
    >
      &#10005;
    </div>
  </div>
);

DistractionItem.propTypes = {
  index: PropTypes.number.isRequired,
  distraction: PropTypes.string.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default DistractionItem;
