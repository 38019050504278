import React from 'react';
import PropTypes from 'prop-types';

import theme from 'style/theme';

import { ReactComponent as PendingIcon } from 'images/avatar-fallback.svg';

const TeamRowPending = ({ email, handleRevokeUser }) => (
  <tr>
    <td className="avatar-cell">
      <PendingIcon />
      <span className="name">{email}</span>
    </td>
    <td>
      <span>Pending invitation</span>
    </td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td className="cta" onClick={() => handleRevokeUser(email)}>
      Revoke
    </td>

    <style jsx>
      {`
        td {
          padding-top: 1.5em;
          padding-bottom: 1.5em;
          border-bottom: 1px solid rgba(151, 151, 151, 0.1);
          font-size: 14px;
          color: #484848;
        }

        .name {
          display: inline-block;
          margin-left: 5px;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .avatar-cell {
          font-weight: bold;
        }

        .avatar-cell :global(svg) + .name {
          position: relative;
          bottom: 5px;
          left: 8px;
        }

        .avatar-cell :global(svg) {
          position: relative;
          top: 1px;
        }

        td:nth-child(1) {
          width: 200px;
        }

        td:nth-child(n + 2) {
          text-align: center;
        }

        .cta {
          color: ${theme.color.coralSerene};
          font-size: 12px;
          cursor: pointer;
        }
      `}
    </style>
  </tr>
);

TeamRowPending.propTypes = {
  email: PropTypes.string.isRequired,
  handleRevokeUser: PropTypes.func.isRequired,
};

export default TeamRowPending;
