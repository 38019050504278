import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import FileInputButton from 'components/Shared/FileInput';
import Tooltip from 'components/Shared/Tooltip/Tooltip.component';
import { Button } from '@ventureharbour/serene-shared-components';

import './index.scss';

class AvatarUploader extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    allowedContentTypes: PropTypes.string,
    maxFileSize: PropTypes.string,
  };

  static defaultProps = {
    allowedContentTypes: 'gif, jpg, jpeg, png',
    maxFileSize: '500kb',
  };

  state = {
    image: null,
    imageIsValid: false,
    croppedImage: null,
    allowZoomOut: false,
    errors: [],
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 10,
    preview: null,
    width: 75,
    height: 75,
    isTooltipVisible: false,
  };

  defaultState = {
    errors: [],
    image: null,
    imageIsValid: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 50,
    preview: null,
    width: 75,
    height: 75,
  };

  getAllowedContentTypesArray = () =>
    this.props.allowedContentTypes.split(',').map(value => value.trim());

  formatAllowedContentTypesForDisplay = () => {
    const contentTypesArray = this.getAllowedContentTypesArray();
    let displayString = '';
    contentTypesArray.forEach((value, index) => {
      displayString +=
        index !== contentTypesArray.length - 1
          ? `.${value}, `
          : ` or .${value}. `;
    });

    return displayString;
  };

  getImageDimensions = file =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () =>
        resolve({ width: image.width, height: image.height });
      image.onerror = reject;
      image.src = window.URL.createObjectURL(file);
    });

  newImageIsValid = async imageResource => {
    const allowedFileMeasurements = ['kb', 'mb', 'gb'];
    const maxFileSizeInfo = this.props.maxFileSize
      .toLowerCase()
      .match(new RegExp(`([0-9]*)(${allowedFileMeasurements.join('|')})`));
    const maxFileSizeMeasurement = maxFileSizeInfo[2];
    const maxFileSizeValue = maxFileSizeInfo[1];
    const maxDimensionInPixels = 1024;
    const minDimensionInPixels = 128;

    if (imageResource.type) {
      const isValidType = this.getAllowedContentTypesArray().find(
        value => imageResource.type.split('/')[1] === value,
      );

      if (!isValidType) {
        this.setState(previousState => {
          previousState.errors.push({
            type: 'Image file format not supported.',
          });
        });
      }
    }

    if (
      !maxFileSizeMeasurement ||
      !allowedFileMeasurements.includes(maxFileSizeMeasurement)
    ) {
      this.setState(previousState => {
        previousState.errors.push({ size: 'Image file format not supported.' });
      });
    }

    const fileDimensions = await this.getImageDimensions(imageResource);
    const dimensionTooLarge =
      fileDimensions.width > maxDimensionInPixels &&
      fileDimensions.height > maxDimensionInPixels;

    const dimensionTooSmall =
      fileDimensions.width < minDimensionInPixels &&
      fileDimensions.height < minDimensionInPixels;

    if (dimensionTooSmall || dimensionTooLarge) {
      this.setState(previousState => {
        previousState.errors.push({
          dimension: `Image dimensions are too ${
            dimensionTooSmall ? 'small' : 'large'
          }.`,
        });
      });
    }

    if (imageResource.size) {
      const maxFileSizeInBytes =
        maxFileSizeValue *
        Math.pow(
          1024,
          allowedFileMeasurements.indexOf(maxFileSizeMeasurement) + 1,
        );

      if (imageResource.size >= maxFileSizeInBytes) {
        this.setState(previousState => {
          previousState.errors.push({ size: 'Image file size too large.' });
        });
      }
    }
    return this.state.errors.length <= 0;
  };

  showTooltip = () => {
    this.setState({ isTooltipVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipVisible: false });
  };

  handleNewImage = async event => {
    event.persist();
    this.setState({ errors: [] });
    const isValid = await this.newImageIsValid(event.target.files[0]);
    if (isValid) {
      this.setState({ image: event.target.files[0], imageIsValid: true });
    } else {
      this.setState({ imageIsValid: false });
    }

    this.props.onChange(null);
  };

  handleLoadFailure = event => true;

  handleLoadSuccess = event => true;

  handleImageReady = event => true;

  handleSaveImage = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();
    this.setState(previousState => ({
      imageIsValid: true,
      preview: {
        img,
        rect,
        scale: previousState.scale,
        width: previousState.width,
        height: previousState.height,
        borderRadius: previousState.borderRadius,
      },
    }));

    this.props.onChange(img);
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleRemoveImage = e => {
    this.setState(previousState => ({
      preview: null,
      image: null,
      croppedImage: null,
    }));

    this.props.onChange('');
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateLeft = e => {
    e.preventDefault();

    this.setState(previousState => ({
      rotate: previousState.rotate - 90,
    }));
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState(previousState => ({
      rotate: previousState.rotate + 90,
    }));
  };

  handleXPosition = e => {
    const x = parseFloat(e.target.value);
    this.setState(previousState => ({
      position: { ...previousState.position, x },
    }));
  };

  handleYPosition = e => {
    const y = parseFloat(e.target.value);
    this.setState(previousState => ({
      position: { ...previousState.position, y },
    }));
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] });
  };

  render() {
    const {
      isTooltipVisible,
      errors,
      preview,
      image,
      scale,
      width,
      height,
      position,
      rotate,
      borderRadius,
    } = this.state;

    return (
      <div className="AvatarUploader">
        {!!image && !preview && (
          <p className="AvatarUploader__label">Drag to reposition photo</p>
        )}
        {((!image && !preview) || (image && preview)) && (
          <p className="AvatarUploader__label">Upload a company or team logo</p>
        )}

        {!!image && !preview && (
          <div className="AvatarUploader__wrapper">
            <div
              onClick={this.handleRemoveImage}
              className="AvatarUploader__remove-image"
            />
            <div className="AvatarUploader__image--crop-target" />
            <AvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(scale)}
              width={width}
              height={height}
              position={position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(rotate)}
              borderRadius={width / (100 / borderRadius)}
              onLoadFailure={this.handleLoadFailure}
              onLoadSuccess={this.handleLoadSuccess}
              onImageReady={this.handleImageReady}
              image={image}
              className="AvatarUploader__canvas"
            />
            <Button
              className="btn--medium"
              onClick={this.handleSaveImage}
              name="Save"
            >
              Save
            </Button>
          </div>
        )}

        {image === null && (
          <div className="AvatarUploader__placeholder">
            <div
              className={`AvatarUploader__image--placeholder ${
                errors.length > 0 ? 'invalid' : ''
              }`}
            />

            {errors.length > 0 && image === null && (
              <Tooltip isVisible={errors[0] !== null}>
                {Object.values(errors[0])}
              </Tooltip>
            )}
          </div>
        )}

        {!!preview && (
          <div className="AvatarUploader__wrapper">
            <div
              onClick={this.handleRemoveImage}
              className="AvatarUploader__remove-image"
            />
            <img
              src={preview.img}
              style={{
                borderRadius: `${(Math.min(preview.height, preview.width) +
                  10) *
                  (preview.borderRadius / 2 / 100)}px`,
              }}
            />
          </div>
        )}

        {image === null && (
          <div className="AvatarUploader__file-upload">
            <FileInputButton
              onChange={this.handleNewImage}
              placeholder="Upload logo"
              gradientBorder
              icon="plus"
              variation="white-round"
            />

            <div
              className="AvatarUploader__tooltip"
              onMouseEnter={this.showTooltip}
              onMouseLeave={this.hideTooltip}
            >
              Recommended file?
            </div>
            <Tooltip isVisible={isTooltipVisible}>
              <ul>
                <li>
                  File format: {this.formatAllowedContentTypesForDisplay()}{' '}
                </li>
                <li>Max file size: {this.props.maxFileSize}.</li>
                <li>Recommended dimensions: 500px by 500px.</li>
              </ul>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

export default AvatarUploader;
