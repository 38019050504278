import React from 'react';
import PropTypes from 'prop-types';

import ScreenTitle from 'components/ScreenTitle';
import Screen from 'components/Screen';

const ProtocolSuccess = ({ location }) => {
  const search = location.search.replace('?', '');

  return (
    <Screen>
      <div className="ProtocolSuccess">
        <ScreenTitle>Protocol Success!</ScreenTitle>
        <p>Serene Code: {search}</p>
        <p>
          URL: <strong>serene://{search}</strong>
        </p>
      </div>
    </Screen>
  );
};

ProtocolSuccess.propTypes = {
  location: PropTypes.string,
};

export default ProtocolSuccess;
