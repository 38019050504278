import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UserContext } from './UserProvider';

import {
  SUBSCRIPTION_CANCEL_URL,
  SUBSCRIPTION_UPDATE_URL,
  IS_USER_PRO,
} from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS } from 'messages/ipc-messages';

import firebase from 'firebase/app';
import 'firebase/firestore';

const { ipcRenderer } = window.require('electron');

export const SubscriptionContext = createContext();

const updateUserSubscription = async (
  updateUrl,
  cancelUrl,
  hasValidSubscription,
) => {
  await ipcRenderer.send(IPC_CHANGE_SETTINGS, [
    {
      name: SUBSCRIPTION_CANCEL_URL,
      value: cancelUrl,
    },
    {
      name: SUBSCRIPTION_UPDATE_URL,
      value: updateUrl,
    },
    {
      name: IS_USER_PRO,
      value: hasValidSubscription,
    },
  ]);
};

const SubscriptionsProvider = ({ children }) => {
  const {
    state: { user },
    loading: userLoading,
  } = useContext(UserContext);
  const [data, loading, error] = useCollectionData(
    user.firebaseUserId
      ? firebase
          .firestore()
          .collection('subscriptions')
          .where('owner', '==', user.firebaseUserId)
      : null,
  );
  const activeSubscription = useMemo(() => {
    if (data) {
      if (data.length) {
        return data.find(subscription =>
          ['active', 'past_due', 'trialing'].includes(subscription.status),
        );
      }
    }
  }, [data]);
  useMemo(async () => {
    if (activeSubscription && !loading) {
      const {
        update_url: updateUrl,
        cancel_url: cancelUrl,
      } = activeSubscription;
      await updateUserSubscription(updateUrl, cancelUrl, true);
    } else {
      await updateUserSubscription('', '', false);
    }
  }, [activeSubscription]);
  return (
    <SubscriptionContext.Provider
      value={{
        activeSubscription,
        data,
        loading: loading || userLoading || !data,
        error,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SubscriptionsProvider;
