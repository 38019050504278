import React from 'react';
import {
  ToastContainer as ToastContainerComponent,
  toast as toastFn,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import NotificationCard from 'components/Shared/NotificationCard/NotificationCard.component';
import './Toast.scss';

export const ToastContainer = () => (
  <ToastContainerComponent
    position="top-right"
    autoClose={5000}
    newestOnTop
    closeOnClick
    draggable={false}
    hideProgressBar
  />
);

export const toast = ({ title, content, toastId = null }) => {
  toastFn(<NotificationCard heading={title} content={content} />, {
    toastId,
  });
};
