const loggerGroups = {
  APP: 'APP',
  AXIOS_REQUEST: 'AXIOS_REQUEST',
  BLOCKED_APPS_LIST: 'BLOCKED_APPS_LIST',
  BLOCKED_PAGES_LIST: 'BLOCKED_PAGES_LIST',
  BREAK_VIEW: 'BREAK_VIEW',
  CURRENT_SESSION_VIEW: 'CURRENT_SESSION_VIEW',
  FILE_MANAGER: 'FILE_MANAGER',
  LOCAL_SERVER: 'LOCAL_SERVER',
  ONBOARDING_VIEW: 'ONBOARDING_VIEW',
  PLAN_SESSIONS_VIEW: 'PLAN_SESSIONS_VIEW',
  RENDERER: 'RENDERER',
  REVIEW_DAY_VIEW: 'REVIEW_DAY_VIEW',
  REVIEW_SESSION_VIEW: 'REVIEW_SESSION_VIEW',
  SESSION_MANAGER: 'SESSION_MANAGER',
  SETTINGS: 'SETTINGS',
  SYNCHRONIZATION: 'SYNCHRONIZATION',
  SET_DAY_GOAL_VIEW: 'SET_DAY_GOAL_VIEW',
  SOCKET_COMMUNICATION: 'SOCKET_COMMUNICATION',
  STATE_MANAGER: 'STATE_MANAGER',
  STATE_RADIO_GROUP: 'STATE_RADIO_GROUP',
  PRODUCTIVITY_RADIO_GROUP: 'PRODUCTIVITY_RADIO_GROUP',
  WARP_ZONE_VIEW: 'WARP_ZONE_VIEW',
  WEBHOOK: 'WEBHOOK',
  PAYMENTS: 'PAYMENTS',
};

module.exports = loggerGroups;
