import React from 'react';
import PropTypes from 'prop-types';

import SessionItem from './SessionItem.component';

import './CompletedSessionStack.scss';

const CompletedSessionStack = ({ hasHeading, sessions }) => {
  const completedSessions = sessions.filter(({ isFinished }) => isFinished);
  const lastElement =
    completedSessions.length < 4 ? completedSessions.length : 4;
  const sessionsStack = completedSessions
    .sort((sessionA, sessionB) => sessionB.number - sessionA.number)
    .slice(0, lastElement);

  return (
    <div className="CompletedSessionStack">
      {hasHeading && <h3> COMPLETE </h3>}
      <ul className="CompletedSessionStack__list">
        {sessionsStack.map(session => (
          <SessionItem
            dragDisabled
            key={session.number}
            isHoverActive={false}
            {...session}
          />
        ))}
      </ul>
    </div>
  );
};

CompletedSessionStack.propTypes = {
  hasHeading: PropTypes.bool,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      isFinished: PropTypes.bool,
      startingFrom: PropTypes.string,
    }).isRequired,
  ),
};

export default CompletedSessionStack;
