import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import { NAV_SESSIONS } from 'constants/routingPaths';

import '../OnboardingSuccess/OnboardingSuccess.scss';

const OnboardingSuccess = props => {
  const nextStep = () => {
    props.history.push({
      pathname: NAV_SESSIONS,
      state: { shouldOpenAddSessionView: true },
    });
  };

  return (
    <div className="OnboardingSuccess">
      <div className="container">
        <h1 className="OnboardingSuccess__title">
          You&rsquo;re all set up{' '}
          <span role="img" aria-label="Thumbs up">
            👍
          </span>
        </h1>
        <div className="OnboardingSuccess__caption">
          You can edit these settings anytime from the settings tab on your
          Serene dashboard
        </div>
        <Button
          onClick={nextStep}
          name="Got It!"
          className="OnboardingSuccess__btn"
          primary
        />
      </div>
    </div>
  );
};

OnboardingSuccess.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default OnboardingSuccess;
