import React, { useEffect, useState, useContext } from 'react';

import { InputText } from '@ventureharbour/serene-shared-components';

import Checkbox from 'components/Shared/Checkbox/Checkbox.component';
import SettingsContent from '../SettingsContent.component';
import ProPlanCard from 'components/UpgradePlan/ProPlanCard.component';

import {
  IPC_GET_SETTINGS,
  IPC_OPEN_PADDLE_CANCELLATION,
  IPC_OPEN_PADDLE_CHECKOUT,
  IPC_SETTING_CHANGED,
  IPC_CHANGE_SETTINGS,
} from 'messages/ipc-messages';
import {
  IS_USER_PRO,
  USER_CURRENCY,
  USER_EMAIL,
  APP_OPENS_AT_LOGIN,
  IS_ONLINE,
} from 'messages/available-settings';

import './SettingsAccount.scss';
import { UserContext } from 'contexts/UserProvider';

const {
  PADDLE_CURRENCY,
  PADDLE_MONTHLY_PRO_RATE,
} = require('../../../../config');

const { ipcRenderer } = window.require('electron');

const SettingsAccount = () => {
  const [isUserPro, setUserPro] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [currency, setCurrency] = useState(PADDLE_CURRENCY);
  const [appOpensAtLogin, setAppOpensAtLogin] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const {
    state: { user },
    showConfirmation,
  } = useContext(UserContext);

  useEffect(() => {
    ipcRenderer.on(IPC_SETTING_CHANGED, handleSettingChange);

    const {
      isUserPro,
      userCurrency,
      userEmail,
      appOpensAtLogin,
      isOnline,
    } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      IS_USER_PRO,
      USER_CURRENCY,
      USER_EMAIL,
      APP_OPENS_AT_LOGIN,
      IS_ONLINE,
    ]);
    setOnline(isOnline);
    setUserPro(isUserPro);
    setAppOpensAtLogin(appOpensAtLogin);
    if (userCurrency !== '') {
      setCurrency(userCurrency);
    }
    setUserEmail(userEmail);
    return () => {
      ipcRenderer.removeListener(IPC_SETTING_CHANGED, handleSettingChange);
    };
  }, []);

  const handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_USER_PRO) {
      setUserPro(newValue);
    }
    if (setting === IS_ONLINE) {
      setOnline(newValue);
    }
  };

  const handleCheckboxChange = () => {
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: APP_OPENS_AT_LOGIN,
      value: !appOpensAtLogin,
    });
    setAppOpensAtLogin(!appOpensAtLogin);
  };

  const showEmailConfirmation = () => {
    showConfirmation({ email: user.email });
  };

  const onCheckoutClicked = () => {
    if (user && user.status === 'confirmed') {
      ipcRenderer.send(IPC_OPEN_PADDLE_CHECKOUT);
    } else {
      showEmailConfirmation();
    }
  };

  const onCancelClicked = () => {
    ipcRenderer.send(IPC_OPEN_PADDLE_CANCELLATION);
  };

  const planName = isUserPro ? 'Pro' : 'Lite plan';
  const actionContent = isUserPro ? (
    <div className="action-content__cancel" onClick={onCancelClicked}>
      Cancel Pro plan
    </div>
  ) : (
    <div className="action-content__upgrade-card">
      <ProPlanCard
        openCheckout={onCheckoutClicked}
        currency={currency}
        monthlyCharge={PADDLE_MONTHLY_PRO_RATE}
        isOnline={isOnline}
      />
    </div>
  );

  return (
    <SettingsContent
      header="Account"
      tagline="Find and edit all your account and plan details."
      className="AccountSettings"
    >
      <div className="AccountSettings__row">
        <label>Email address:</label>
        <InputText
          id="accountEmail"
          type="text"
          onChange={() => false}
          value={userEmail}
          className={'AccountSettings__email-input'}
        />
      </div>
      <div className="AccountSettings__row">
        <label>Email confirmed:</label>
        <div style={{ lineHeight: '1em' }}>
          {user && user.status === 'confirmed' ? (
            '👍'
          ) : (
            <div
              onClick={showEmailConfirmation}
              className="AccountSettings__confirmation link"
            >
              Send confirmation
            </div>
          )}
        </div>
      </div>
      <div className="AccountSettings__row AccountSettings__row-checkbox">
        <Checkbox
          id="appOpensAtLogin"
          checked={appOpensAtLogin}
          handleCheckboxChange={handleCheckboxChange}
          className=""
        >
          Startup:
        </Checkbox>
        <div
          className="AccountSettings__auto-start-cta"
          onClick={handleCheckboxChange}
        >
          Launch Serene at login
        </div>
      </div>

      {isUserPro !== null && (
        <div className="AccountSettings__row">
          <label>Plan:</label>
          <div className="AccountSettings__plan-name">{planName}</div>

          <label>{''}</label>
          <div className="AccountSettings__action-content">{actionContent}</div>
        </div>
      )}
    </SettingsContent>
  );
};

export default SettingsAccount;
