const moment = require('moment');

const diffDates = date => {
  const difference = moment(date)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');

  return {
    isToday: difference === 0,
    isTomorrow: difference === 1,
    isFutureDay: difference > 0,
    isPastDay: difference < 0,
  };
};

module.exports = {
  diffDates,
};
