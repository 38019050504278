import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import HTML5Backend from 'react-dnd-html5-backend';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';

import CurrentSession from 'views/CurrentSession/CurrentSession.component';
import DayDebrief from 'views/DayDebrief/DayDebrief.component';
import Navigation from 'views/Navigation/Navigation.component';
import OnBoarding from 'views/Onboarding/Onboarding.component';
import OnboardingBlockApps from 'views/OnboardingBlockApps/OnboardingBlockApps.component';
import OnboardingSuccess from 'views/OnboardingSuccess/OnboardingSuccess.component';
import SetDayGoal from 'views/SetDayGoal/SetDayGoal.component';
import Shortcuts from 'views/Shortcuts/Shortcuts.component';
import OnboardingBlockSites from './views/OnboardingBlockSites/OnboardingBlockSites.component';
import ProtocolSuccess from 'views/ProtocolSuccess/ProtocolSuccess.component';
import TeamOnboarding from 'views/TeamOnboarding/TeamOnboarding.component';

import TeamsWelcome from 'views/Teams/Welcome';
import TeamsInvite from 'views/Teams/InviteTeam';

import { ToastContainer } from 'components/Shared/Toast/Toast.component';
import AlertModal from 'components/AlertModal/AlertModal.component';
import Modal from 'components/Shared/Modal/Modal.component';
import Footer from 'components/Footer/Footer.component';
import Logger from 'components/Logger/Logger.component';
import Titlebar from 'components/Titlebar/Titlebar.component';
import FirstVisitRoute from 'components/Shared/FirstVisitRoute/FirstVisitRoute';
import Paddle from 'components/Paddle/Paddle.component';
import UpgradePlan from 'components/UpgradePlan/UpgradePlan.component';

import PlansModal from 'views/PlansModal';

import UserProvider from 'contexts/UserProvider';
import SessionsProvider from 'contexts/SessionsProvider';
import SettingsProvider from 'contexts/SettingsProvider';
import SubscriptionProvider from 'contexts/SubscriptionProvider';
import TeamProvider, { TeamContext } from 'contexts/TeamProvider';

import {
  IPC_GET_SETTINGS,
  IPC_RESTART_SERENE,
  IPC_SETTING_CHANGED,
  IPC_CHANGE_SETTINGS,
} from 'messages/ipc-messages';
import {
  IS_LS_FAILURE_MODAL_VISIBLE,
  IS_UPGRADE_MODAL_VISIBLE,
} from 'messages/available-settings';

import {
  CURRENT_SESSION,
  DAY_DEBRIEF,
  HOME,
  NAVIGATION,
  ONBOARDING,
  ONBOARDING_BLOCK_APPS,
  ONBOARDING_BLOCK_SITES,
  ONBOARDING_SUCCESS,
  SET_DAY_GOAL,
  SHORTCUTS,
  SERENE_PROTOCOL_SUCCESS_VIEW,
  TEAM_WELCOME_VIEW,
  TEAM_INVITES_VIEW,
  TEAM_ONBOARDING,
  TEAM_VIEW,
} from 'constants/routingPaths';
import Team from 'views/Navigation/Team/Team.component';

const { PADDLE_TEAM } = require('config');

const { ipcRenderer } = window.require('electron');

const handleRestartSerene = () => {
  ipcRenderer.send(IPC_RESTART_SERENE);
};

const closeUpgradeModal = () => {
  ipcRenderer.send(IPC_CHANGE_SETTINGS, {
    name: IS_UPGRADE_MODAL_VISIBLE,
    value: false,
  });
};

const App = () => {
  const [isFailureLsModalVisible, setisFailureLsModalVisible] = useState(false);
  const [isUpgradeModalVisible, setisUpgradeModalVisible] = useState(false);

  useEffect(() => {
    const {
      isFailureLsModalVisible,
      isUpgradeModalVisible,
    } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      IS_LS_FAILURE_MODAL_VISIBLE,
      IS_UPGRADE_MODAL_VISIBLE,
    ]);

    setisFailureLsModalVisible(isFailureLsModalVisible);
    setisUpgradeModalVisible(isUpgradeModalVisible);

    return () => {
      ipcRenderer.removeListener(IPC_SETTING_CHANGED, handleSettingChange);
    };
  });

  const handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_LS_FAILURE_MODAL_VISIBLE) {
      setisFailureLsModalVisible(newValue);
    }

    if (setting === IS_UPGRADE_MODAL_VISIBLE) {
      setisUpgradeModalVisible(newValue);
    }
  };

  return (
    <UserProvider>
      <TeamProvider>
        <SubscriptionProvider>
          <SettingsProvider>
            <SessionsProvider>
              <Helmet>
                <body data-translucent="false" />
              </Helmet>
              <Paddle />
              <DndProvider backend={HTML5Backend}>
                <Titlebar />
                <div className="app-container">
                  <HashRouter>
                    <Switch>
                      <Logger>
                        <FirstVisitRoute path={HOME} exact />
                        <Route
                          path={SERENE_PROTOCOL_SUCCESS_VIEW}
                          exact
                          component={ProtocolSuccess}
                        />

                        <Route path={TEAM_VIEW} exact component={Team} />
                        <Route
                          path={TEAM_WELCOME_VIEW}
                          exact
                          component={TeamsWelcome}
                        />
                        <Route
                          path={TEAM_INVITES_VIEW}
                          exact
                          render={props => <TeamsInvite isScreen {...props} />}
                        />
                        <Route path={ONBOARDING} component={OnBoarding} />
                        <Route path={DAY_DEBRIEF} component={DayDebrief} />
                        <Route
                          path={TEAM_ONBOARDING}
                          component={TeamOnboarding}
                        />
                        <Route path={SET_DAY_GOAL} component={SetDayGoal} />
                        <Route
                          path={ONBOARDING_BLOCK_APPS}
                          component={OnboardingBlockApps}
                        />
                        <Route
                          path={ONBOARDING_BLOCK_SITES}
                          component={OnboardingBlockSites}
                        />
                        <Route
                          path={ONBOARDING_SUCCESS}
                          component={OnboardingSuccess}
                        />
                        <Route
                          path={CURRENT_SESSION}
                          component={CurrentSession}
                        />
                        <Route path={SHORTCUTS} exact component={Shortcuts} />
                        <Route path={NAVIGATION} component={Navigation} />
                      </Logger>
                    </Switch>
                  </HashRouter>
                </div>
                <Footer />
                {!isFailureLsModalVisible && <ToastContainer />}
                {isFailureLsModalVisible && (
                  <React.Fragment>
                    <Modal open={isFailureLsModalVisible} onCloseModal={false}>
                      <AlertModal
                        open={isFailureLsModalVisible}
                        title="An error has occurred."
                        body="Please close and restart the app. If this issue persists please contact support@sereneapp.com."
                        okTitle="Restart Serene"
                        okAction={handleRestartSerene}
                      />
                    </Modal>
                  </React.Fragment>
                )}

                <PlansModal
                  highlight={PADDLE_TEAM}
                  title="Invite your Team today! 🙌"
                />

                <Modal
                  noWhitebox
                  open={isUpgradeModalVisible}
                  title="Go Pro today! 🙌"
                  onCloseModal={closeUpgradeModal}
                >
                  <UpgradePlan onCloseModal={closeUpgradeModal} />
                </Modal>

                <TeamContext.Consumer>
                  {({ state, toggleInviteModal }) => (
                    <Modal
                      noWhitebox
                      open={state.showInviteModal}
                      onCloseModal={toggleInviteModal}
                      wide
                    >
                      <TeamsInvite darkTheme showBackground={false} />
                    </Modal>
                  )}
                </TeamContext.Consumer>
              </DndProvider>
            </SessionsProvider>
          </SettingsProvider>
        </SubscriptionProvider>
      </TeamProvider>
    </UserProvider>
  );
};

export default App;
