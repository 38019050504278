import React from 'react';
import PropTypes from 'prop-types';
import ShadowDom from 'react-shadow';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';

import { DONE, HALFDONE, NOTDONE } from 'constants/sessionStates';
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg';
import { ReactComponent as DoneIcon } from 'images/done-icon.svg';
import { ReactComponent as EditIcon } from 'images/edit-grey-icon.svg';
import { ReactComponent as HalfDoneIcon } from 'images/half-done-icon.svg';
import { ReactComponent as NotDoneIcon } from 'images/not-done-selected-icon.svg';

import './SessionItem.scss';
import {
  setGlobalCursor,
  resetGlobalCursor,
} from 'helpers/setGlobalCursor.helper';

const SessionItem = ({
  number,
  counterStartTime,
  task,
  state,
  isFinished,
  isHoverActive,
  onEditClicked,
  onRemoveClicked,
  onStopEditingSession,
  dragDisabled,
  isOver,
  draggedElementNumber,
}) => {
  const minutes = counterStartTime ? counterStartTime / 60 : null;

  const svgStyle = `
    svg {
      width: 17px;
      height: 17px;
    }
  `;

  const handleEditClick = () => {
    onEditClicked(number);
  };

  const handleDeleteClick = () => {
    onRemoveClicked(number);
  };

  const taskClasses = classNames('SessionItem__description', {
    'SessionItem__description--finished': isFinished,
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'SESSION_ITEM',
      number,
      task,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    begin: () => {
      onStopEditingSession && onStopEditingSession();
      setGlobalCursor('-webkit-grabbing');
    },
    end: () => resetGlobalCursor(),
    canDrag: !dragDisabled,
  });

  const sessionItemClasses = classNames('SessionItem', {
    'SessionItem--hoverable': isHoverActive,
    'SessionItem--dragged': isDragging,
    'SessionItem--draggable': !dragDisabled,
  });

  const shouldShowDropTarget = isOver && !isDragging && !isFinished;

  const canShowBeforeDropTarget =
    draggedElementNumber > number && shouldShowDropTarget;

  const canShowAfterDropTarget =
    draggedElementNumber < number && shouldShowDropTarget;

  return (
    <React.Fragment>
      {canShowBeforeDropTarget && <div className="SessionItem__drop-target" />}
      <div className={sessionItemClasses} ref={drag}>
        <div className="SessionItem__header">
          <div className="header__state">
            <ShadowDom>
              <div>
                {state === DONE && <DoneIcon />}
                {state === HALFDONE && <HalfDoneIcon />}
                {state === NOTDONE && <NotDoneIcon />}

                <style>{svgStyle}</style>
              </div>
            </ShadowDom>
          </div>
          <div className="header__session-number"> SESSION {number + 1} </div>
          {!isFinished && (
            <React.Fragment>
              {minutes && (
                <div className="header__minutes"> {minutes} MINS </div>
              )}
              <div className="header__right-menu">
                <EditIcon className="header__icon" onClick={handleEditClick} />
                <DeleteIcon
                  className="header__icon"
                  onClick={handleDeleteClick}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={taskClasses}> {task} </div>
      </div>
      {isDragging && <div className="SessionItem__background" />}
      {canShowAfterDropTarget && <div className="SessionItem__drop-target" />}
    </React.Fragment>
  );
};

SessionItem.propTypes = {
  number: PropTypes.number.isRequired,
  counterStartTime: PropTypes.number.isRequired,
  task: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  isHoverActive: PropTypes.bool.isRequired,
  onEditClicked: PropTypes.func,
  onRemoveClicked: PropTypes.func,
  onStopEditingSession: PropTypes.func,
  dragDisabled: PropTypes.bool,
  isOver: PropTypes.bool,
  isFinished: PropTypes.bool,
  draggedElementNumber: PropTypes.number,
};

export default SessionItem;
