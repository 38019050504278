export const nameSort = (a, b) => {
  const joinedA = a.name
    .split(' ')
    .reverse()
    .join('');
  const joinedB = b.name
    .split(' ')
    .reverse()
    .join('');
  if (joinedA > joinedB) {
    return -1;
  }
  if (joinedB > joinedA) {
    return 1;
  }
  return 0;
};

const statusToString = ({ sereneMode, online }) => {
  let string = 'c';
  if (sereneMode) {
    string = 'a';
  } else if (online) {
    string = 'b';
  }
  return string;
};

export const statusSort = (a, b) => {
  const aStatus = statusToString(a.status);
  const bStatus = statusToString(b.status);

  if (aStatus < bStatus) {
    return -1;
  }

  if (aStatus > bStatus) {
    return 1;
  }

  return 0;
};

export const sessionsCountSort = (a, b) => b.sessionsCount - a.sessionsCount;

export const lastSessionSort = (a, b) => {
  if (!a.lastSession || !b.lastSession) {
    if (!a.lastSession) {
      return -1;
    } else if (!b.lastSession) {
      return 1;
    } else {
      return 0;
    }
  }

  const dateA = new Date(a.lastSession.toDate()).getTime();
  const dateB = new Date(b.lastSession.toDate()).getTime();

  if (dateA < dateB) {
    return -1;
  }

  if (dateA > dateB) {
    return 1;
  }

  return 0;
};

export const totalSereneTimeSort = (a, b) =>
  b.totalSereneTime - a.totalSereneTime;
