module.exports = {
  SESSION_PAUSE: 'SESSION_PAUSE',
  SESSION_PAUSED: 'SESSION_PAUSED',
  SESSION_RESTART: 'SESSION_RESTART',
  SESSION_RESTARTED: 'SESSION_RESTARTED',
  SESSION_START: 'SESSION_START',
  SESSION_STARTED: 'SESSION_STARTED',
  SESSION_STOP: 'SESSION_STOP',
  SESSION_STOPPED: 'SESSION_STOPPED',
  SESSION_STATUS: 'SESSION_STATUS',
  SESSION_UNPAUSE: 'SESSION_UNPAUSE',
  SESSION_UNPAUSED: 'SESSION_UNPAUSED',
  SESSION_UPDATED: 'SESSION_UPDATED',

  SOCKET_OPEN_APPS: 'OPEN-APPS',

  IS_EXTENSION_LAUNCHED: 'IS_EXTENSION_LAUNCHED',
  EXTENSION_IS_LAUNCHED: 'EXTENSION_IS_LAUNCHED',
  SYNC_SETTINGS_REQ: 'SYNC_SETTINGS_REQ',
  SYNC_SETTINGS_RES: 'SYNC_SETTINGS_RES',
  EXTENSION_INSTALLED: 'EXTENSION_INSTALLED',
};
