import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { diffDates } from 'helpers/date.helper';

import { IPC_CHANGE_SETTINGS, IPC_GET_SETTINGS } from 'messages/ipc-messages';
import {
  IS_UPGRADE_MODAL_VISIBLE,
  IS_USER_PRO,
} from 'messages/available-settings';

import './GraphPoint.scss';

const { ipcRenderer } = window.require('electron');

const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const getCorrectClassName = (
  dayState,
  isToday,
  isActive,
  isDashed,
  fixDashes,
  isMonday,
) => {
  const classes = classnames(
    `GraphPoint__status--${dayState.toLowerCase()}`,
    'GraphPoint__status',
    {
      'GraphPoint__status--dashed': isDashed,
      'GraphPoint__status--today': isToday,
      'GraphPoint__status--active': isActive,
      'GraphPoint__status--dashed-fix': fixDashes,
      'GraphPoint__status--remove-dash': isMonday,
    },
  );

  return `${classes}`;
};

const GraphPoint = ({
  index,
  date,
  achievedGoal,
  dayState,
  onClick,
  isToday,
  isActive,
  isDashed,
  fixDashes,
  translate,
}) => {
  const graphPointDate = new Date(date);
  const [pointClasses, setPointClasses] = useState('GraphPoint');

  const onPointClick = event => {
    if (dayState.toLowerCase() === 'notfinished') {
      event.preventDefault();
      return;
    }

    const isUserPro = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_USER_PRO);
    const { isFutureDay } = diffDates(date);
    const isFuturePlanningDisabled = !isUserPro && isFutureDay;

    if (isFuturePlanningDisabled) {
      ipcRenderer.send(IPC_CHANGE_SETTINGS, {
        name: IS_UPGRADE_MODAL_VISIBLE,
        value: true,
      });
      return;
    }

    onClick(date, index);
  };

  const day = graphPointDate.getDay();
  const classes = getCorrectClassName(
    dayState,
    isToday,
    isActive,
    isDashed,
    fixDashes,
    day === 0,
  );

  const graphPointRef = useRef();
  const statusRef = useRef();

  useEffect(() => {
    const newPointClasses = classnames('GraphPoint', {
      'GraphPoint--left': translate === -1,
      'GraphPoint--right': translate === 1,
    });
    setPointClasses(newPointClasses);
  }, [translate]);

  return (
    <div ref={graphPointRef} className={pointClasses}>
      <div ref={statusRef} onClick={onPointClick} className={classes} />
      <div className="GraphPoint__day">{weekDays[day]}</div>
    </div>
  );
};

GraphPoint.propTypes = {
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  achievedGoal: PropTypes.string.isRequired,
  dayState: PropTypes.string,
  onClick: PropTypes.func,
  isToday: PropTypes.bool,
  isActive: PropTypes.bool,
  isDashed: PropTypes.bool,
  fixDashes: PropTypes.bool,
  translate: PropTypes.number,
};

export default GraphPoint;
