import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import theme from 'style/theme';

import Avatar from 'components/Avatar';

import { secondsToHours, secondsToMinutes } from 'helpers/time.helper';

const TeamRow = ({ member, isManager, handleRemoveTeamMember }) => {
  const { name, status, lastSession, sessionCount, totalSereneTime } = member;

  const statusSwitch = () => {
    let statusMessage = '';
    let avatarStatus = '';

    switch (true) {
      case name === '':
        statusMessage = <span>Pending invitation</span>;
        avatarStatus = 'pending';
        break;
      case status.sereneMode:
        statusMessage = (
          <span className="sereneMode">
            In Serene Mode
            <style jsx>
              {`
                span {
                  background: linear-gradient(
                    90deg,
                    ${theme.color.bgGradientDark},
                    ${theme.color.bgGradientLight}
                  );
                  font-weight: bold;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              `}
            </style>
          </span>
        );
        avatarStatus = 'serene';
        break;
      case status.online:
        statusMessage = <span>Online</span>;
        avatarStatus = 'online';
        break;
      case status.online === false:
        statusMessage = <span>Offline</span>;
        avatarStatus = 'offline';
        break;
      default:
        statusMessage = '';
        break;
    }

    return {
      statusMessage,
      avatarStatus,
    };
  };

  const { avatarStatus, statusMessage } = statusSwitch();

  const hours = secondsToHours(totalSereneTime);
  const minutes = secondsToMinutes(totalSereneTime);

  return (
    <tr>
      <td className="avatar-cell">
        <Avatar name={name} status={avatarStatus} />
        <span className="name">{name}</span>
      </td>
      <td>{statusMessage}</td>
      <td>{lastSession ? moment(lastSession.toDate()).fromNow() : '-'}</td>
      <td>{sessionCount}</td>
      <td>{`${hours}:${minutes}`}</td>
      {isManager ? (
        <td
          className="TeamRow-cell cta"
          onClick={() => handleRemoveTeamMember(member)}
        >
          Remove
        </td>
      ) : (
        <td />
      )}

      <style jsx>
        {`
          td {
            padding-top: 1.5em;
            padding-bottom: 1.5em;
            border-bottom: 1px solid rgba(151, 151, 151, 0.1);
            font-size: 14px;
            color: ${theme.color.bodyColor};
          }

          .name {
            display: inline-block;
            margin-left: 5px;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .avatar-cell {
            font-weight: bold;
          }

          td:nth-child(1) {
            width: 200px;
          }

          td:nth-child(n + 2) {
            text-align: center;
          }

          .cta {
            color: ${theme.color.coralSerene};
            font-size: 12px;
            cursor: pointer;
          }
        `}
      </style>
    </tr>
  );
};

TeamRow.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isManager: PropTypes.bool.isRequired,
    status: PropTypes.shape({
      online: PropTypes.bool.isRequired,
      sereneMode: PropTypes.bool.isRequired,
    }),
    lastSession: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
      .isRequired,
    sessionCount: PropTypes.number.isRequired,
    totalSereneTime: PropTypes.number.isRequired,
  }).isRequired,
  isManager: PropTypes.bool.isRequired,
  handleRemoveTeamMember: PropTypes.func.isRequired,
};

export default TeamRow;
