import React from 'react';
import PropTypes from 'prop-types';

import SessionList from './SessionList.component';

const CompletedSessionList = props => {
  const sessions = props.sessions.sort(
    (sessionA, sessionB) => sessionB.number - sessionA.number,
  );
  return <SessionList dragDisabled {...props} sessions={sessions} />;
};

CompletedSessionList.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      isFinished: PropTypes.bool,
      startingFrom: PropTypes.string,
    }).isRequired,
  ),
};

export default CompletedSessionList;
