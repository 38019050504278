const secondsToHours = (seconds, padStart = true) => {
  let hours = Math.floor(seconds / 3600).toString();

  if (padStart === true) {
    hours = hours.padStart(2, '0');
  }

  return hours;
};

const secondsToMinutes = (seconds, padStart = true, roundDownHours = true) => {
  if (roundDownHours) {
    seconds = seconds % 3600;
  }

  let minutes = Math.floor(seconds / 60).toString();

  if (padStart) {
    minutes = minutes.padStart(2, '0');
  }

  return minutes;
};

const secondsFormatter = seconds =>
  Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');

module.exports = {
  secondsFormatter,
  secondsToHours,
  secondsToMinutes,
};
