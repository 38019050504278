import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import './AlertModal.scss';

const AlertModal = ({ title, body, okAction, okTitle, open }) => {
  const handleKeyDown = useCallback(
    event => {
      const { key } = event;

      if (open && key === 'Enter') {
        okAction();
      }
    },
    [okAction, open],
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleKeyDown]);

  return (
    <div className="AlertModal">
      <h2>{title}</h2>
      <p className="AlertModal__body">{body}</p>
      <div className="AlertModal__buttons">
        <Button className="btn--medium" onClick={okAction} primary>
          {okTitle}
        </Button>
      </div>
    </div>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  okAction: PropTypes.func.isRequired,
  okTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AlertModal;
