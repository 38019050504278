export const HOME = '/';
export const ONBOARDING = '/onboarding';
export const TEAM_ONBOARDING = '/teams/onboarding';
export const SET_DAY_GOAL = '/set-day-goal';
export const ONBOARDING_BLOCK_APPS = '/onboarding-block-apps';
export const ONBOARDING_BLOCK_SITES = '/onboarding-block-sites';
export const ONBOARDING_SUCCESS = '/onboarding-success';
export const CURRENT_SESSION = '/currentSession';
export const DAY_DEBRIEF = '/day-debrief';
export const SHORTCUTS = '/shortcuts';
export const NAVIGATION = '/navigation';
export const PLANS = '/plans';
export const PLANS_TEAM = '/plans/team';

export const NAV_DASHBOARD = `${NAVIGATION}/dashboard`;
export const NAV_GO_SERENE = `${NAVIGATION}/go-serene`;
export const NAV_SESSIONS = `${NAVIGATION}/sessions`;
export const NAV_TRENDS = `${NAVIGATION}/trends`;
export const NAV_TEAM = `${NAVIGATION}/team`;
export const NAV_TEAM_DASHBOARD = `${NAVIGATION}/team/dashboard`;
export const NAV_SETTINGS = `${NAVIGATION}/settings`;
export const NAV_REPORTING = `${NAVIGATION}/reporting`;
export const NAV_SETTINGS_SESSIONS = `${NAVIGATION}/settings/sessions`;
export const NAV_SETTINGS_APPS = `${NAVIGATION}/settings/blocked-apps`;
export const NAV_SETTINGS_SITES = `${NAVIGATION}/settings/blocked-sites`;
export const NAV_SETTINGS_MUSIC = `${NAVIGATION}/settings/music`;
export const NAV_SETTINGS_HOTKEYS = `${NAVIGATION}/settings/hotkeys`;
export const NAV_SETTINGS_WEBHOOK = `${NAVIGATION}/settings/webhook`;
export const NAV_SETTINGS_ACCOUNT = `${NAVIGATION}/settings/account`;
export const NAV_PLANS_TEAM = `${NAVIGATION}/plans/team`;

export const SERENE_PROTOCOL_SUCCESS_VIEW = '/protocol-success';

export const TEAM_WELCOME_VIEW = '/team/welcome';
export const TEAM_INVITES_VIEW = '/team/invites';
export const TEAM_DASHBOARD_VIEW = '/team/dashboard';
export const TEAM_VIEW = '/team';
