import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import { DEFAULT_SESSIONS_NUMBER } from 'config';

import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';
import CompletedSessionStack from 'components/Shared/SessionList/CompletedSessionStack.component';
import NextSessionList from 'components/Shared/SessionList/NextSessionList.component';
import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';
import GoSereneButton from 'components/Shared/GoSereneButton/GoSereneButton.Component';

import { BREAK_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { NAV_SESSIONS } from 'constants/routingPaths';

import {
  IPC_GET_SETTINGS,
  IPC_SM_END_BREAK,
  IPC_SM_REINDEX_SESSION,
} from 'messages/ipc-messages';
import { SESSIONS_NUMBER } from 'messages/available-settings';

import './BreakView.scss';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

class BreakView extends Component {
  static propTypes = {
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        counterStartTime: PropTypes.number.isRequired,
        number: PropTypes.number.isRequired,
        state: PropTypes.string.isRequired,
        task: PropTypes.string.isRequired,
        startingFrom: PropTypes.string,
        isActive: PropTypes.bool,
        isFinished: PropTypes.bool,
      }).isRequired,
    ),
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  state = {
    maxSessions: DEFAULT_SESSIONS_NUMBER,
  };

  componentDidMount() {
    const maxSessions = ipcRenderer.sendSync(IPC_GET_SETTINGS, SESSIONS_NUMBER);
    this.setState({ maxSessions });
  }

  handleNextSessionClick = () => {
    log(INFO, 'Clicked end break button', BREAK_VIEW);
    ipcRenderer.send(IPC_SM_END_BREAK);
  };

  onAddSessionClick = () => {
    log(INFO, 'Clicked Add new session button', BREAK_VIEW);
    this.props.history.push({
      pathname: NAV_SESSIONS,
      state: { shouldOpenAddSessionView: true },
    });
  };

  onReindexSession = (targetNumber, element) => {
    ipcRenderer.send(IPC_SM_REINDEX_SESSION, {
      targetNumber,
      element,
    });
  };

  render() {
    const { sessions } = this.props;
    const { number, task, startingFrom } = sessions.find(
      session => !session.isFinished,
    );
    const addSessionButtonVisible = sessions.length < this.state.maxSessions;

    return (
      <Layout>
        <Menu hasNavigation>
          <div className="BreakView__navigation">
            <div>
              <TodayGoal />
              <h3>PLANNED SESSIONS</h3>
            </div>
            <NextSessionList
              sessions={sessions}
              onReindexSession={this.onReindexSession}
            />
            {addSessionButtonVisible && (
              <Button
                className="BreakView__add-new-session-btn"
                name="addSession"
                onClick={this.onAddSessionClick}
                secondary
              >
                <div className="plus-sign">+</div>
                <div>Add new session</div>
              </Button>
            )}
            <CompletedSessionStack hasHeading sessions={sessions} />
          </div>
        </Menu>
        <Content>
          <div className="BreakView">
            <h1 className="BreakView__title">Take a break!</h1>
            <div className="BreakView__session">
              <div className="BreakView__session-number">
                SESSION {number + 1}
              </div>
              <div className="BreakView__session-title">{task}</div>
              <div className="BreakView__session-starting">{startingFrom}</div>
            </div>

            <GoSereneButton
              callback={this.handleNextSessionClick}
              buttonText="Start next session now"
            />
          </div>
        </Content>
      </Layout>
    );
  }
}
export default BreakView;
