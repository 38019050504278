import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { InputText, Button } from '@ventureharbour/serene-shared-components';

import './SettingsAccountConfirmation.scss';
import { requestLoginCode, redeemClaim } from 'helpers/firebase.helper';
import Loader from 'components/Shared/Loader/Loader.component';

const SettingsAccountConfirmation = ({
  show = false,
  locked = false,
  onClose,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const resend = async () => {
    if (user.email) {
      setLoading(true);
      await requestLoginCode(user.email);
      setLoading(false);
    }
  };
  const codeChange = evt => setCode(evt.target.value);
  const confirm = async () => {
    if (code.length < 4) {
      return setError({ text: 'Make sure you enter a code!' });
    }
    setError(null);
    setLoading(true);
    try {
      await redeemClaim(user.email, code);
      onClose(true);
    } catch (e) {
      setLoading(false);
      setError({ text: 'Invalid confirmation code.', show: true });
    }
  };
  useMemo(async () => {
    if (show && user.email) {
      resend();
    }
  }, [show, user.email]);
  if (show) {
    return (
      <div className="SettingsAccountConfirmation">
        <div className="SettingsAccountConfirmation__overlay">
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {!locked && (
                <div className="close" onClick={onClose}>
                  <img src={require('images/close-icon.svg')} />
                </div>
              )}
              <img src={require('images/bubble-illustration.svg')} />
              <h2>
                <span role="img" aria-label="email-inbox">
                  📨
                </span>{' '}
                Please confirm your
                <br />
                e-mail address
              </h2>
              <p>
                To verify this email address ({user.email}) belongs to you,
                please enter below the code that is in the email we sent you:
              </p>
              <div className={classNames('input__holder', { error })}>
                <div>
                  <InputText
                    placeholder="*****"
                    value={code}
                    onChange={codeChange}
                  />
                </div>
                {error && (
                  <div className="error">
                    {error.text}{' '}
                    {error.show && (
                      <a onClick={resend}>Try resending the email.</a>
                    )}
                  </div>
                )}
              </div>
              <div>
                <Button
                  onClick={confirm}
                  name="Confirm Email Address"
                  className={classNames('SettingsAccountConfirmation__confirm')}
                  primary
                />
              </div>
              <p>
                <a onClick={resend}>Resend Email</a> or{' '}
                <a href="mailto:hello@sereneapp.com">Contact our Support</a>
              </p>
              <footer>
                <small>
                  Please note that it might take a few minutes for the e-mail to
                  arrive. Also please check your spam folder.
                </small>
              </footer>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
  return '';
};

export default SettingsAccountConfirmation;
