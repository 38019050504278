import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@ventureharbour/serene-shared-components';

import './GreatWork.scss';

import SendEmail from 'components/SendEmail/SendEmail.component';

const GreatWork = ({
  numberOfInvitations,
  userSegment,
  copyLinkClicked,
  handleCopyLink,
}) => (
  <div className="GreatWork great-work__invitation">
    <p className="invitation__question">
      Know a {userSegment} who might love Serene?
    </p>
    <p className="invitation__info">
      You have
      <span className="info__number"> {numberOfInvitations} </span>
      invitations left. Invite wisely!
    </p>
    <SendEmail />
    <p className="invitation__or">or</p>
    <Button
      className={classnames('invitation__copy-link-btn', {
        'invitation__copy-link-btn--clicked': copyLinkClicked,
      })}
      onClick={handleCopyLink}
      gradient
    >
      {copyLinkClicked ? 'Copied to clipboard' : 'Copy invite link'}
    </Button>
  </div>
);

GreatWork.propTypes = {
  numberOfInvitations: PropTypes.number.isRequired,
  userSegment: PropTypes.string.isRequired,
  copyLinkClicked: PropTypes.bool.isRequired,
  handleCopyLink: PropTypes.func.isRequired,
};

export default GreatWork;
