import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';
import {
  NAV_SETTINGS,
  NAV_SETTINGS_APPS,
  NAV_SETTINGS_HOTKEYS,
  NAV_SETTINGS_MUSIC,
  NAV_SETTINGS_SESSIONS,
  NAV_SETTINGS_SITES,
  NAV_SETTINGS_WEBHOOK,
  NAV_SETTINGS_ACCOUNT,
} from 'constants/routingPaths';

import SettingsBlockApps from './SettingsBlock/SettingsBlockApps.component';
import SettingsBlockSites from './SettingsBlock/SettingsBlockSites.component';
import SettingsHotkeys from './SettingsHotkeys/SettingsHotkeys.component';
import SettingsMusic from './SettingsMusic/SettingsMusic.component';
import SettingsNavItem from './SettingsNavItem.component';
import SettingsSessions from './SettingsSessions/SettingsSessions.component';
import SettingsWebook from './SettingsWebhook/SettingsWebhook.component';
import SettingsAccount from './SettingsAccount/SettingsAccount.component';

import './Settings.scss';
import SecondaryNavigationHeader from 'components/SecondaryNavigationHeader';

const Settings = ({ location }) => {
  const { pathname } = location;
  return (
    <div className="Settings">
      <Layout>
        <Menu hasNavigation>
          <SecondaryNavigationHeader>Settings</SecondaryNavigationHeader>
          <div className="Settings__nav">
            <div className="Settings__nav-list">
              <SettingsNavItem
                caption="Account"
                link={NAV_SETTINGS_ACCOUNT}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Sessions"
                link={NAV_SETTINGS_SESSIONS}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Hotkeys"
                link={NAV_SETTINGS_HOTKEYS}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Block apps"
                link={NAV_SETTINGS_APPS}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Block websites"
                link={NAV_SETTINGS_SITES}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Music"
                link={NAV_SETTINGS_MUSIC}
                pathname={pathname}
              />
              <SettingsNavItem
                caption="Webhook"
                link={NAV_SETTINGS_WEBHOOK}
                pathname={pathname}
              />
            </div>
          </div>
        </Menu>
        <Content>
          <Redirect from={NAV_SETTINGS} exact to={NAV_SETTINGS_ACCOUNT} />
          <Route
            path={NAV_SETTINGS_ACCOUNT}
            exact
            component={SettingsAccount}
          />
          <Route
            path={NAV_SETTINGS_SESSIONS}
            exact
            component={SettingsSessions}
          />
          <Route
            path={NAV_SETTINGS_HOTKEYS}
            exact
            component={SettingsHotkeys}
          />
          <Route path={NAV_SETTINGS_APPS} component={SettingsBlockApps} />
          <Route
            path={NAV_SETTINGS_SITES}
            exact
            component={SettingsBlockSites}
          />
          <Route path={NAV_SETTINGS_MUSIC} exact component={SettingsMusic} />
          <Route path={NAV_SETTINGS_WEBHOOK} exact component={SettingsWebook} />
        </Content>
      </Layout>
    </div>
  );
};

Settings.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
};

export default Settings;
