import React, { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@ventureharbour/serene-shared-components';
import { SettingsContext } from '../../contexts/SettingsProvider';

import Screen from 'components/Screen';
import { SubscriptionContext } from 'contexts/SubscriptionProvider';
import Loader from 'components/Shared/Loader/Loader.component';
import { TEAM_VIEW } from 'constants/routingPaths';

const TeamPlan = () => {
  const { toggleTeamsModalState, setShowTeamsModal } = useContext(
    SettingsContext,
  );
  const { activeSubscription, loading } = useContext(SubscriptionContext);
  useMemo(() => {
    if (activeSubscription) {
      setShowTeamsModal(false);
    }
  }, [activeSubscription]);
  if (loading) {
    return <Loader />;
  }
  if (activeSubscription) {
    return <Redirect to={TEAM_VIEW} />;
  }
  return (
    <Screen showShortcut centered showBackground={false}>
      <div className="TeamPlan">
        <Button
          name="Subscribe to Teams"
          className="btn--big onboarding__button"
          onClick={toggleTeamsModalState}
          primary
        />
      </div>

      <style jsx>
        {`
          div {
            min-width: 600px;
          }

          :global(.Button) {
            margin-top: 3em;
          }
        `}
      </style>
    </Screen>
  );
};

export default TeamPlan;
