import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { ReactComponent as Arrow } from 'images/down-chevron-icon.svg';

import './Input.scss';

const InputSelect = ({
  options,
  handleSelectChange,
  value,
  small,
  id,
  innerRef,
}) => {
  const optionsList = options.map((option, key) => (
    <option key={key} value={option.toLowerCase()} id={key}>
      {option}
    </option>
  ));

  const classNames = classnames('InputSelect', {
    'InputSelect--small': small,
  });

  return (
    <div className={classNames}>
      <select
        value={value}
        className="InputSelect__select"
        onChange={handleSelectChange}
        id={id}
        ref={innerRef}
      >
        {optionsList}
      </select>
      <Arrow className="InputSelect__icon" />
    </div>
  );
};

InputSelect.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array]),
  handleSelectChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  small: PropTypes.bool,
  innerRef: PropTypes.func,
};

InputSelect.defaultProps = {
  className: '',
};

export default InputSelect;
