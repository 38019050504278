import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  InputText,
} from '@ventureharbour/serene-shared-components';

import { MAX_NR_OF_SITES_TO_BLOCK_FOR_FREE_USER } from 'config';

import BlockedSitesToggles from './BlockedSitesToggles.component';

import getFavicon from 'helpers/getFavicon.helper';
import Analytics from 'helpers/universal-analytics.helper';

import {
  ACT_BLOCKED,
  CAT_BLOCKED_SITES,
  LAB_BLOCK_SITE,
} from 'constants/analytics';
import { BLOCKED_PAGES_LIST } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import socialSites from 'constants/socialSites';
import {
  BLOCKED_CATEGORIES,
  BLOCKED_SOCIAL_SITES,
  BLOCKED_WEBSITES,
  IS_USER_PRO,
  IS_UPGRADE_MODAL_VISIBLE,
} from 'messages/available-settings';
import {
  IPC_CHANGE_SETTINGS,
  IPC_GET_SETTINGS,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import '../Blockapps/Blockapps.scss';
import './BlockSites.scss';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

const updateIconInList = (name, icon) => {
  const blockedSitesList = ipcRenderer.sendSync(
    IPC_GET_SETTINGS,
    BLOCKED_WEBSITES,
  );

  const newList = blockedSitesList.map(item => {
    if (item.name === name) {
      item.icon = icon;
    }

    return item;
  });

  ipcRenderer.send(IPC_CHANGE_SETTINGS, {
    name: BLOCKED_WEBSITES,
    value: newList,
  });
};

const fetchIcon = async name => {
  const fetchedIcon = await getFavicon(name);
  updateIconInList(name, fetchedIcon);

  return fetchedIcon;
};

const siteExists = (newSite, listItems) =>
  listItems.some(site => site.name === newSite);

class Blocksites extends React.Component {
  static propTypes = {
    settingsView: PropTypes.bool,
  };

  state = {
    listItems: [],
    newSite: '',
    duplicatedSite: null,
    categories: {
      social: false,
      adult: false,
      gambling: false,
    },
    socialSites: socialSites.map(site => ({
      name: site,
      active: false,
      forceActive: false,
    })),
    socialDrawerOpen: false,
    isUserPro: false,
  };

  componentDidMount() {
    this.defaultBlockedSites();
    this.defaultBlockedSitesCategories();
    this.defaultSocialSites();
    ipcRenderer.on(IPC_SETTING_CHANGED, this.handleSettingChange);

    const isUserPro = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_USER_PRO);
    this.setState({ isUserPro });
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SETTING_CHANGED, this.handleSettingChange);
  }

  handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_USER_PRO) {
      this.setState({ newValue });
    }
  };

  defaultBlockedSites = async () => {
    const blockedSites = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      BLOCKED_WEBSITES,
    );

    this.updateBlockedSitesSetting(blockedSites);
  };

  defaultSocialSites = async () => {
    const blockedSocialSites = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      BLOCKED_SOCIAL_SITES,
    );

    this.setState({
      socialSites: blockedSocialSites,
    });
  };

  defaultBlockedSitesCategories = async () => {
    const blockedSiteCategories = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      BLOCKED_CATEGORIES,
    );

    let social = false;
    let adult = false;
    let gambling = false;

    if (blockedSiteCategories) {
      ({ social, adult, gambling } = blockedSiteCategories);
    }

    this.setState({
      categories: {
        social,
        adult,
        gambling,
      },
    });
  };

  updateBlockedSitesSetting = async blockSites => {
    const blockedSitesString = blockSites.map(site => site.name).join(' ');

    this.setState(
      prevState => {
        const socialSitesMap = prevState.socialSites.map(site => {
          const pattern = new RegExp(site.name);
          const contains = pattern.test(blockedSitesString);

          if (contains) {
            site.active = true;
          }

          return site;
        });

        return {
          socialSites: socialSitesMap,
          listItems: blockSites,
        };
      },
      async () => {
        await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
          name: BLOCKED_WEBSITES,
          value: blockSites,
        });
      },
    );
  };

  onRemove = item => {
    const updatedListItems = this.state.listItems.filter(
      listItem => listItem.name !== item.name,
    );

    log(
      INFO,
      `Removed page ${item.name} from block pages list`,
      BLOCKED_PAGES_LIST,
    );

    this.updateBlockedSitesSetting(updatedListItems);
  };

  getSocialSiteIndex = input => {
    let socialIndex = null;

    socialSites.forEach((site, index) => {
      const pattern = new RegExp(site);
      const contains = pattern.test(input);

      if (contains) {
        socialIndex = index;
      }
    });

    return socialIndex;
  };

  handleSocialDrawerOpening = state => {
    this.setState({
      socialDrawerOpen: state,
    });
  };

  addWebsite = async () => {
    const { newSite, listItems } = this.state;

    this.setState({ duplicatedSite: null });

    if (newSite === '') {
      return;
    }

    if (siteExists(newSite, listItems)) {
      this.setState({
        duplicatedSite: newSite,
      });
      return;
    }

    const socialSiteIndex = this.getSocialSiteIndex(newSite);

    log(INFO, `Added page ${newSite} to block pages list`, BLOCKED_PAGES_LIST);

    Analytics.trackEvent(
      CAT_BLOCKED_SITES,
      ACT_BLOCKED,
      LAB_BLOCK_SITE,
      newSite,
    );

    if (socialSiteIndex !== null) {
      this.setState(
        prevState => {
          const { socialSites } = prevState;
          socialSites[socialSiteIndex].active = true;
          socialSites[socialSiteIndex].forceActive = true;

          return {
            ...prevState,
            socialSites,
            socialDrawerOpen: true,
          };
        },
        async () => {
          // scroll to this site.
          const wrapper = document.querySelector('.BlockSites__list');
          const targetListItem = document.querySelector(
            `.BlockedSitesToggles--social li:nth-child(${socialSiteIndex + 1})`,
          );

          const top =
            targetListItem.getBoundingClientRect().top -
            wrapper.getBoundingClientRect().top;

          wrapper.scrollBy({ top, left: 0, behavior: 'smooth' });

          // Update social sites list in the settings
          await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
            name: BLOCKED_SOCIAL_SITES,
            value: this.state.socialSites,
          });
        },
      );
    } else {
      this.updateBlockedSitesSetting([{ name: newSite }, ...listItems]);
    }

    this.setState({
      newSite: '',
      duplicatedSite: null,
    });
  };

  updateWebsiteList = e => {
    const value = e.target.value;

    this.setState(prevState => ({
      duplicatedSite: value === '' ? null : prevState.duplicatedSite,
      newSite: value,
    }));
  };

  handleKeypress = e => {
    if (e.key === 'Enter') {
      this.addWebsite();
    }
  };

  handleCategoryUpdate = async (category, active) => {
    log(INFO, `Set category ${category} value: ${active}`, BLOCKED_PAGES_LIST);

    this.setState(
      prevState => {
        const categories = { ...prevState.categories };
        categories[category] = active;
        return {
          categories,
        };
      },
      async () => {
        await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
          name: BLOCKED_CATEGORIES,
          value: this.state.categories,
        });
      },
    );
  };

  handleSocialSiteUpdate = (site, overrideActive = null) => {
    this.setState(
      prevState => {
        const currentSocialIndex = prevState.socialSites.findIndex(
          socialSite => socialSite.name === site.name,
        );

        let nextActive = '';

        if (overrideActive !== null) {
          // If the user has typed in the input box and is social site, keep the active state when the parent has been toggled.
          if (prevState.socialSites[currentSocialIndex].forceActive) {
            nextActive = prevState.socialSites[currentSocialIndex].forceActive;
          } else {
            nextActive = overrideActive;
          }
        } else {
          nextActive = !prevState.socialSites[currentSocialIndex].active;
          prevState.socialSites[currentSocialIndex].forceActive = false;
        }

        prevState.socialSites[currentSocialIndex].active = nextActive;

        log(
          INFO,
          `Set social site ${site.name} value: ${prevState.socialSites[currentSocialIndex].active}`,
          BLOCKED_PAGES_LIST,
        );

        return prevState;
      },
      async () => {
        await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
          name: BLOCKED_SOCIAL_SITES,
          value: this.state.socialSites,
        });
      },
    );
  };

  showUpgradeModal = () => {
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_UPGRADE_MODAL_VISIBLE,
      value: true,
    });
  };

  renderSearchBar = () => {
    const { isUserPro, listItems, newSite, duplicatedSite } = this.state;
    const { settingsView } = this.props;

    if (
      isUserPro ||
      listItems.length < MAX_NR_OF_SITES_TO_BLOCK_FOR_FREE_USER
    ) {
      return (
        <React.Fragment>
          <InputText
            id="blockSites"
            type="text"
            onChange={this.updateWebsiteList}
            placeholder="www."
            onKeyPress={this.handleKeypress}
            value={newSite}
            invalid={duplicatedSite}
            invalidMessage="This URL already has been added"
          />
          <Button
            className="BlockSites__add-btn"
            disabled={!newSite}
            onClick={this.addWebsite}
            primary
            mini
          >
            + Add
          </Button>
        </React.Fragment>
      );
    }

    if (settingsView) {
      return (
        <div className="input-wrapper--upgrade" onClick={this.showUpgradeModal}>
          Upgrade to Pro for <span>unlimited blocked sites</span>
        </div>
      );
    }

    if (!settingsView) {
      return (
        <div className="input-wrapper--limited">
          <span role="img" aria-label="raising-hands">
            🙌{' '}
          </span>
          You are all set
        </div>
      );
    }
  };

  render() {
    const { categories, listItems, socialDrawerOpen, socialSites } = this.state;

    return (
      <div className="BlockSites">
        <div className="BlockSites__list">
          {listItems.length > 0 && (
            <List
              onRemoveItem={this.onRemove}
              items={listItems}
              fetchIcon={fetchIcon}
            />
          )}
          <BlockedSitesToggles
            categories={categories}
            handleCategoryUpdate={this.handleCategoryUpdate}
            socialSites={socialSites}
            handleSocialSiteUpdate={this.handleSocialSiteUpdate}
            handleSocialDrawerOpening={this.handleSocialDrawerOpening}
            socialDrawerOpen={socialDrawerOpen}
            handleSocialDrawer={this.handleSocialDrawer}
          />
        </div>

        <div className="BlockSites__input-wrapper">
          {this.renderSearchBar()}
        </div>
      </div>
    );
  }
}

export default Blocksites;
