import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { NAV_DASHBOARD } from 'constants/routingPaths';
import { DAY_FINISHED, IDLE } from 'constants/sessionManagerStates';
import { DONE } from 'constants/sessionStates';

import {
  IPC_SM_GENERATE_FILES,
  IPC_SM_GET_DATA,
  IPC_SM_SESSIONS_UPDATED,
  IPC_SM_STATE_CHANGED,
  IPC_SM_UPDATE_DATA,
  IPC_SM_GET_DAY_DATA,
  IPC_SM_DATE_CHANGED,
} from 'messages/ipc-messages';

import DaySummary from './DaySummary/DaySummary.component';
import PlanSessions from './PlanSessions/PlanSessions.component';
import { diffDates } from 'helpers/date.helper';

import './Sessions.scss';

const { ipcRenderer } = window.require('electron');

class Sessions extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({ shouldOpenAddSessionView: PropTypes.bool }),
    }).isRequired,
  };

  constructor() {
    super();

    this.state = {
      sessionManagerState: IDLE,
      sessions: [],
      dayReviewed: false,
    };

    this.onSessionUpdated = this.onSessionUpdated.bind(this);
    this.onStateChanged = this.onStateChanged.bind(this);
  }

  componentDidMount() {
    const { state, date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
    const { sessions, achievedGoal } = ipcRenderer.sendSync(
      IPC_SM_GET_DAY_DATA,
    );

    ipcRenderer.on(IPC_SM_SESSIONS_UPDATED, this.onSessionUpdated);
    ipcRenderer.on(IPC_SM_STATE_CHANGED, this.onStateChanged);

    ipcRenderer.on(IPC_SM_DATE_CHANGED, (event, date) => {
      const { sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);

      this.setState({
        date: moment(date),
        sessions,
        sessionsLength: sessions.length,
      });
    });

    const dayReviewed = !!achievedGoal;

    this.setState({
      sessions,
      sessionManagerState: state,
      dayReviewed,
      date: moment(date),
    });
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SM_SESSIONS_UPDATED, this.onSessionUpdated);
    ipcRenderer.removeListener(IPC_SM_STATE_CHANGED, this.onStateChanged);
  }

  onSessionUpdated = (event, sessions) => {
    this.setState({
      sessions,
    });
  };

  onStateChanged = (event, sessionManagerState) => {
    this.setState({
      sessionManagerState,
    });
  };

  onDayReviewed = (achievedGoal, productivity) => {
    this.setState({
      dayReviewed: true,
    });

    ipcRenderer.sendSync(IPC_SM_UPDATE_DATA, {
      achievedGoal: achievedGoal === DONE ? 'Yes' : 'No',
      productivity,
    });

    ipcRenderer.send(IPC_SM_GENERATE_FILES, {
      sessions: this.state.sessions,
    });

    const { sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);

    this.setState({
      sessions,
    });

    this.props.history.push(NAV_DASHBOARD);
  };

  renderView = () => {
    const { dayReviewed, sessionManagerState, sessions, date } = this.state;
    const { state } = this.props.location;
    const shouldOpenAddSessionView = !!(
      state && state.shouldOpenAddSessionView
    );

    const { isToday } = diffDates(date);

    if (sessionManagerState === DAY_FINISHED && isToday) {
      return (
        <React.Fragment>
          {!dayReviewed ? (
            <DaySummary
              sessions={sessions}
              onDayReviewed={this.onDayReviewed}
            />
          ) : (
            <PlanSessions
              sessions={sessions}
              history={this.props.history}
              isToday={isToday}
              shouldOpenAddSessionView={shouldOpenAddSessionView}
            />
          )}
        </React.Fragment>
      );
    }

    return (
      <PlanSessions
        isToday={isToday}
        sessions={sessions}
        history={this.props.history}
        shouldOpenAddSessionView={shouldOpenAddSessionView}
      />
    );
  };

  render() {
    return <div className="Sessions">{this.renderView()}</div>;
  }
}

export default Sessions;
