const productivityStates = {
  NOT_VERY_PRODUCTIVE: {
    id: 'NOT_VERY_PRODUCTIVE',
    text: 'Not Productive',
    symbol: '🙈',
  },
  NORMAL_PRODUCTIVE: {
    id: 'NORMAL_PRODUCTIVE',
    text: 'Normal',
    symbol: '👍',
  },
  MORE_THAN_NORMAL_PRODUCTIVE: {
    id: 'MORE_THAN_NORMAL_PRODUCTIVE',
    text: 'More than Normal',
    symbol: '💪',
  },
  VERY_PRODUCTIVE: {
    id: 'VERY_PRODUCTIVE',
    text: 'Very Productive',
    symbol: '🎉',
  },
};

module.exports = productivityStates;
