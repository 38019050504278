import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import SessionItem from './SessionItem.component';

const DropTarget = ({
  session,
  isHoverActive,
  onEditClicked,
  onRemoveClicked,
  onStopEditingSession,
  reindexElement,
  dragDisabled,
}) => {
  const [{ isOver, element }, drop] = useDrop({
    accept: 'SESSION_ITEM',
    drop: element => {
      reindexElement(session.number, element);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      element: monitor.getItem(),
    }),
  });

  return (
    <div ref={drop}>
      <SessionItem
        key={session.number}
        onEditClicked={onEditClicked}
        onRemoveClicked={onRemoveClicked}
        onStopEditingSession={onStopEditingSession}
        isHoverActive={isHoverActive}
        dragDisabled={dragDisabled}
        isOver={isOver}
        draggedElementNumber={element ? element.number : null}
        {...session}
      />
    </div>
  );
};

DropTarget.propTypes = {
  session: PropTypes.shape({
    counterStartTime: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    task: PropTypes.string.isRequired,
    startingFrom: PropTypes.string,
    isFinished: PropTypes.bool,
    isActive: PropTypes.bool,
  }).isRequired,
  isHoverActive: PropTypes.bool.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onRemoveClicked: PropTypes.func.isRequired,
  onStopEditingSession: PropTypes.func,
  reindexElement: PropTypes.func,
  dragDisabled: PropTypes.bool,
};

export default DropTarget;
