import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PRODUCTIVITY_RADIO_GROUP } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import {
  NOT_VERY_PRODUCTIVE,
  NORMAL_PRODUCTIVE,
  MORE_THAN_NORMAL_PRODUCTIVE,
  VERY_PRODUCTIVE,
} from 'constants/productivityStates';

import ProductivityRadioElement from 'components/ProductivityRadioGroup/ProductivityRadioElement.component';

import './ProductivityRadioGroup.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

const ProductivityRadioGroup = ({ onChange }) => {
  const [selected, setSelected] = useState('');

  const onChangeRadio = ({ target }) => {
    setSelected(target.value);
    log(INFO, `Selected value ${target.value}`, PRODUCTIVITY_RADIO_GROUP);

    onChange(target.value);
  };

  const productivityRadioElements = [
    NOT_VERY_PRODUCTIVE,
    NORMAL_PRODUCTIVE,
    MORE_THAN_NORMAL_PRODUCTIVE,
    VERY_PRODUCTIVE,
  ];

  return (
    <form className="ProductivityRadioGroup">
      {productivityRadioElements.map(element => (
        <ProductivityRadioElement
          value={element.id}
          valueText={element.text}
          selectedValue={selected}
          onChange={onChangeRadio}
          symbol={element.symbol}
          isLabelVisible
          key={element.id}
        />
      ))}
    </form>
  );
};

ProductivityRadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  isLabelVisible: PropTypes.bool,
  sessionState: PropTypes.string,
};

export default ProductivityRadioGroup;
