import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ERROR, INFO } from 'constants/logingLevels';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

class LoggerComponent extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ listen: PropTypes.func }),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  componentDidMount() {
    this.props.history.listen((location, action) => {
      const { pathname, state } = location;

      let message = `${action} to ${pathname}`;
      if (state) {
        message = `${message} with state ${JSON.stringify(state)}`;
      }

      log(INFO, message, 'ROUTER');
    });
  }

  componentDidCatch(error, info) {
    log(ERROR, `Error: ${error}`, 'RENDERER');
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(LoggerComponent);
