import React, { useEffect, useContext, useState } from 'react';
import {
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import PropTypes from 'prop-types';

import { firestore } from '../firebase';

import { UserContext } from './UserProvider';

export const TeamContext = React.createContext();

const TeamProvider = ({ children }) => {
  const {
    state: { user },
  } = useContext(UserContext);

  const [teamValue, teamLoading, teamError] = useDocumentData(
    user && user.team ? firestore.collection('teams').doc(user.team) : null,
  );

  const [invitesValue, invitesLoading, invitesError] = useCollectionData(
    user && user.team
      ? firestore
          .collection('teams')
          .doc(user.team)
          .collection('invites')
          .where('redeemed', '==', false)
      : null,
  );
  const [membersValue, membersLoading, membersError] = useCollectionData(
    user && user.team
      ? firestore
          .collection('teams')
          .doc(user.team)
          .collection('members')
      : null,
    { idField: 'userId' },
  );

  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    if (teamError || invitesError || membersError) {
      console.error({ teamError, invitesError, membersError });
    }
  }, [teamError, invitesError, membersError]);

  return (
    <TeamContext.Provider
      value={{
        state: {
          loading: teamLoading || invitesLoading || membersLoading,
          isManager: teamValue && teamValue.owner === user.firebaseUserId,
          team: teamValue,
          invites: invitesValue,
          members: membersValue,
          showInviteModal,
        },
        toggleInviteModal: () => {
          setShowInviteModal(!showInviteModal);
        },
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
TeamProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default TeamProvider;
