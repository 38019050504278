import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';

import classnames from 'classnames';

import withHotkeys from 'components/HOCs/withHotkeys.component';
import {
  NAV_DASHBOARD,
  NAV_GO_SERENE,
  NAV_SESSIONS,
  NAV_SETTINGS,
  NAV_REPORTING,
  NAV_TEAM,
  NAV_TRENDS,
  SHORTCUTS,
  NAV_TEAM_DASHBOARD,
  NAV_PLANS_TEAM,
  TEAM_VIEW,
} from 'constants/routingPaths';
import { DAY_FINISHED, IDLE, REVIEW } from 'constants/sessionManagerStates';
import { ReactComponent as DashboardIcon } from 'images/dashboard-icon.svg';
import { ReactComponent as GoSereneIcon } from 'images/go-serene-icon.svg';
import { ReactComponent as SessionsIcon } from 'images/plan-session-icon.svg';
import { ReactComponent as LogoIcon } from 'images/serene-header-icon.svg';
import { ReactComponent as SettingsIcon } from 'images/settings-icon.svg';
import { ReactComponent as ReportingIcon } from 'images/reporting-icon.svg';
import { ReactComponent as TeamIcon } from 'images/team-icon.svg';
import {
  IPC_SM_GET_DATA,
  IPC_SM_DATE_CHANGED,
  IPC_SM_GET_DAY_DATA,
  IPC_SM_RELOAD_DAY,
  IPC_SM_STATE_CHANGED,
} from 'messages/ipc-messages';

import { diffDates } from 'helpers/date.helper';

import Dashboard from './Dashboard/Dashboard.component';
import GoSerene from './GoSerene/GoSerene.component';
import NavItem from './NavItem/NavItem.component';
import Reporting from 'views/Reporting';
import Sessions from './Sessions/Sessions.component';
import Settings from './Settings/Settings.component';
import TeamsDashboard from 'views/Teams/Dashboard';
import Team from './Team/Team.component';
import Trends from './Trends/Trends.component';

import { TeamContext } from 'contexts/TeamProvider';

import './Navigation.scss';
import TeamPlan from 'views/Plans/TeamPlan.component';

const isDev = window.require('electron-is-dev');
const { ipcRenderer } = window.require('electron');

const Navigation = ({ location, history }) => {
  const { pathname } = location;
  const [sessionsTabClass, setSessionsTabClass] = useState('');
  const [goSereneTabClass, setGoSereneTabClass] = useState('');
  const [showNavigation, setShowNavigation] = useState(true);

  const {
    state: { team },
  } = useContext(TeamContext);

  useEffect(() => {
    const onSessionManagerStateChange = () => {
      const { state, date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);

      const goSereneTabClassValue = classnames({
        '--disabled': state === IDLE || state === DAY_FINISHED,
      });
      setGoSereneTabClass(goSereneTabClassValue);

      const { goal, achievedGoal } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
      const { isToday } = diffDates(date);
      if (
        (state === REVIEW && goal && isToday) ||
        (state === DAY_FINISHED && achievedGoal === '' && isToday)
      ) {
        setShowNavigation(false);
      } else {
        setShowNavigation(true);
      }
    };

    onSessionManagerStateChange();
    ipcRenderer.on(IPC_SM_STATE_CHANGED, onSessionManagerStateChange);

    return () => {
      ipcRenderer.removeListener(
        IPC_SM_STATE_CHANGED,
        onSessionManagerStateChange,
      );
    };
  }, []);

  useEffect(() => {
    const onSessionManagerDateChanged = () => {
      const { state, date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
      const { goal, achievedGoal } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
      const { isFutureDay, isToday } = diffDates(date);

      const sessionsTabClassValue = classnames({
        '--disabled':
          (state === DAY_FINISHED && achievedGoal && isToday) ||
          (state === IDLE && !goal && isToday) ||
          (isFutureDay && !goal),
      });

      setSessionsTabClass(sessionsTabClassValue);
    };

    onSessionManagerDateChanged();
    ipcRenderer.on(IPC_SM_DATE_CHANGED, onSessionManagerDateChanged);

    return () => {
      ipcRenderer.removeListener(
        IPC_SM_DATE_CHANGED,
        onSessionManagerDateChanged,
      );
    };
  }, [pathname]);

  useEffect(() => {
    const callback = () => {
      history.replace(NAV_DASHBOARD);
    };

    ipcRenderer.on(IPC_SM_RELOAD_DAY, callback);

    return () => {
      ipcRenderer.removeListener(IPC_SM_RELOAD_DAY, callback);
    };
  });

  const navigationViewClass = classnames('Navigation__view', {
    'Navigation__view--has-nav': showNavigation,
    'Navigation__view--has-no-nav': !showNavigation,
  });

  return (
    <div className="Navigation">
      <Helmet>
        <body data-translucent="true" />
      </Helmet>
      {showNavigation && (
        <nav className="Navigation__bar">
          <div className="bar__top">
            <NavItem
              additionalClass="--logo"
              link={isDev ? SHORTCUTS : NAV_DASHBOARD}
              shouldRenderLink
            >
              <LogoIcon />
            </NavItem>

            <NavItem
              link={NAV_DASHBOARD}
              caption="Dashboard"
              pathname={pathname}
              shouldRenderLink
            >
              <DashboardIcon />
            </NavItem>

            <NavItem
              additionalClass={goSereneTabClass}
              link={NAV_GO_SERENE}
              caption="Go Serene"
              pathname={pathname}
              shouldRenderLink
            >
              <GoSereneIcon />
            </NavItem>

            <NavItem
              additionalClass={sessionsTabClass}
              link={NAV_SESSIONS}
              caption="Sessions"
              pathname={pathname}
              shouldRenderLink
            >
              <SessionsIcon />
            </NavItem>
            <NavItem
              link={NAV_REPORTING}
              caption="Reporting"
              pathname={pathname}
              shouldRenderLink
            >
              <ReportingIcon />
            </NavItem>
            <NavItem
              link={TEAM_VIEW}
              caption={team ? 'My Team' : 'Teams'}
              pathname={pathname}
              shouldRenderLink
              badgeText={team ? null : 'Team'}
            >
              <TeamIcon />
            </NavItem>
          </div>
          <div className="bar__middle" />
          <div className="bar__bottom">
            <NavItem
              link={NAV_SETTINGS}
              caption="Settings"
              pathname={pathname}
              shouldRenderLink
            >
              <SettingsIcon />
            </NavItem>
          </div>
        </nav>
      )}

      <main className={navigationViewClass}>
        <Route
          path={NAV_DASHBOARD}
          render={props => {
            setShowNavigation(true);
            return <Dashboard {...props} />;
          }}
        />
        <Route path={NAV_GO_SERENE} component={GoSerene} />

        <Route path={NAV_SESSIONS} component={Sessions} />

        <Route path={NAV_TRENDS} component={Trends} />
        <Route path={NAV_SETTINGS} component={Settings} />
        <Route path={NAV_TEAM} component={Team} exact />
        <Route path={NAV_TEAM_DASHBOARD} component={TeamsDashboard} />
        <Route path={NAV_PLANS_TEAM} exact component={TeamPlan} />
      </main>
    </div>
  );
};

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withHotkeys(Navigation);
