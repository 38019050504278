import React from 'react';
import PropTypes from 'prop-types';
import Analytics from 'helpers/universal-analytics.helper';

import { Button } from '@ventureharbour/serene-shared-components';

import LINKS from 'constants/links';
import {
  ACT_CLICK,
  CAT_ONBOARDING,
  LAB_INSTALL_CHROME_EXTENSION,
  LAB_INSTALL_FIREFOX_EXTENSION,
} from 'constants/analytics';
import BROWSERS from 'constants/browsers';

const { shell } = window.require('electron');

const handleBlockedSitesCTAClick = (browser, browserUrl, analyticsLabel) => {
  shell.openExternal(browserUrl);
  Analytics.trackEvent(CAT_ONBOARDING, ACT_CLICK, analyticsLabel);
};

const InstallBrowserExtensionButton = ({ browser }) => {
  let installText = '';
  let browserUrl = '';
  let analyticsLabel = '';

  if (browser === BROWSERS.CHROME) {
    installText = 'Install the Chrome Extension';
    browserUrl = LINKS.CHROME_EXTENSION;
    analyticsLabel = LAB_INSTALL_CHROME_EXTENSION;
  } else if (browser === BROWSERS.FIREFOX) {
    installText = 'Install the Firefox Add-on';
    browserUrl = LINKS.FIREFOX_EXTENSION;
    analyticsLabel = LAB_INSTALL_FIREFOX_EXTENSION;
  }

  return (
    <Button
      onClick={() =>
        handleBlockedSitesCTAClick(browser, browserUrl, analyticsLabel)
      }
      gradient
      className="btn--gradient--chrome"
    >
      <img
        src={`images/${browser.toLowerCase()}-icon.png`}
        height="34"
        width="34"
        alt={`${browser} Icon`}
      />
      {installText}
    </Button>
  );
};

InstallBrowserExtensionButton.propTypes = {
  browser: PropTypes.string.isRequired,
};
export default InstallBrowserExtensionButton;
