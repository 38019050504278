import React from 'react';
import PropTypes from 'prop-types';

import theme from 'style/theme';

const ScreenSubtitle = ({ children, darkTheme = false }) => (
  <h2>
    {children}
    <style jsx>
      {`
        h2 {
          color: ${darkTheme ? theme.color.bodyColor : theme.color.white};
          font-size: ${theme.fontSize.subTitle};
          line-height: ${(24 / 20) * 1};
          font-weight: normal;
        }
      `}
    </style>
  </h2>
);

ScreenSubtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  darkTheme: PropTypes.bool,
};

export default ScreenSubtitle;
