import React, { Component } from 'react';
import { Toggle } from '@ventureharbour/serene-shared-components';

import BlockSites from 'components/Blocksites/Blocksites.component';

import InstallBrowserExtensionButton from 'components/Shared/InstallBrowserExtensionButton/InstallBrowserExtensionButton.component';

import SettingsContent from '../SettingsContent.component';

import {
  ACT_CLICK,
  CAT_SETTINGS,
  LAB_BLOCK_SITES_SETTINGS,
  LAB_INSTALL_CHROME_EXTENSION,
  LAB_INSTALL_FIREFOX_EXTENSION,
} from 'constants/analytics';
import LINKS from 'constants/links';
import { NAV_SETTINGS_SITES } from 'constants/routingPaths';
import BROWSERS from 'constants/browsers';

import Analytics from 'helpers/universal-analytics.helper';
import {
  IS_BLOCKING_PAGES,
  IS_EXTENSION_INSTALLED,
} from 'messages/available-settings';
import {
  IPC_CHANGE_SETTINGS,
  IPC_GET_SETTINGS,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import './Block.scss';

const { ipcRenderer, shell } = window.require('electron');

class SettingsBlockSites extends Component {
  state = {
    isBlockingPages: false,
    isExtensionInstalled: false,
  };

  componentDidMount() {
    const { isBlockingPages, isExtensionInstalled } = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      [IS_BLOCKING_PAGES, IS_EXTENSION_INSTALLED],
    );

    this.setState({
      isBlockingPages,
      isExtensionInstalled,
    });

    ipcRenderer.on(IPC_SETTING_CHANGED, this.handleSettingChange);

    Analytics.trackPageView(NAV_SETTINGS_SITES, LAB_BLOCK_SITES_SETTINGS);
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SETTING_CHANGED, this.handleSettingChange);
  }

  handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_EXTENSION_INSTALLED) {
      this.setState({ isExtensionInstalled: newValue });
    }
  };

  handleToggle = value => {
    this.setState({ isBlockingPages: value });
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_BLOCKING_PAGES,
      value,
    });
  };

  handleBlockedSitesCTAClick = e => {
    const { href } = e.target;

    e.preventDefault();
    if (href === LINKS.CHROME_EXTENSION) {
      Analytics.trackEvent(
        CAT_SETTINGS,
        ACT_CLICK,
        LAB_INSTALL_CHROME_EXTENSION,
      );
    } else if (href === LINKS.FIREFOX_EXTENSION) {
      Analytics.trackEvent(
        CAT_SETTINGS,
        ACT_CLICK,
        LAB_INSTALL_FIREFOX_EXTENSION,
      );
    }
    shell.openExternal(href);
  };

  render() {
    const { isBlockingPages } = this.state;

    return (
      <SettingsContent
        className="SettingsBlocks SettingsBlocks--sites"
        header="Block sites"
        tagline="Configure what websites you want to block when in a session."
      >
        <Toggle small active={isBlockingPages} onChange={this.handleToggle}>
          Block distracting sites
        </Toggle>
        <div className="SettingsBlocks__content">
          <div className="SettingsBlocks__white-box">
            <BlockSites settingsView />
          </div>
        </div>
        <p className="Settings__tagline">
          For websites to be blocked, it is required to install a browser
          extension.
        </p>
        <InstallBrowserExtensionButton browser={BROWSERS.CHROME} />
        <InstallBrowserExtensionButton browser={BROWSERS.FIREFOX} />
      </SettingsContent>
    );
  }
}

export default SettingsBlockSites;
