import React from 'react';

import SelectComponent from 'components/Shared/Input/InputSelect.component';
import {
  ACT_UPDATE_SESSION_TIME,
  CAT_SETTINGS,
  LAB_SESSION_SETTINGS_VIEW,
  LAB_SESSION_TIME,
} from 'constants/analytics';
import { NAV_SETTINGS_SESSIONS } from 'constants/routingPaths';
import sessionTimesOptions from 'constants/sessionTimesOptions';
import Analytics from 'helpers/universal-analytics.helper';
import { SESSIONS_NUMBER, SESSION_TIME } from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS, IPC_GET_SETTINGS } from 'messages/ipc-messages';

import SettingsContent from '../SettingsContent.component';

import './SettingsSessions.scss';

const { ipcRenderer } = window.require('electron');

class SettingsSession extends React.Component {
  state = {
    sessionTime: sessionTimesOptions[1].time,
  };

  componentDidMount() {
    Analytics.trackPageView(NAV_SETTINGS_SESSIONS, LAB_SESSION_SETTINGS_VIEW);

    const { sessionTime, sessionsNumber } = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      [SESSION_TIME, SESSIONS_NUMBER],
    );

    this.setState({
      sessionTime: sessionTimesOptions.find(
        option => option.time === sessionTime,
      ).text,
      sessionsNumber,
    });
  }

  handleSelectTimeChange = e => {
    const sessionTime = sessionTimesOptions[e.target.selectedIndex].time;

    this.setState({
      sessionTime: sessionTimesOptions.find(
        option => option.time === sessionTime,
      ).text,
    });

    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: SESSION_TIME,
      value: sessionTime,
    });

    Analytics.trackEvent(
      CAT_SETTINGS,
      ACT_UPDATE_SESSION_TIME,
      LAB_SESSION_TIME,
      sessionTime,
    );
  };

  render() {
    const { sessionTime } = this.state;
    const timeSelectOptions = sessionTimesOptions.map(option => option.text);

    return (
      <SettingsContent
        header="Session"
        tagline="Configure the defaults for your sessions."
        className="SessionsSettings"
      >
        <div className="SessionsSettings__row">
          <label htmlFor="sessionTime">Default session duration:</label>
          <div className="">
            <SelectComponent
              options={timeSelectOptions}
              handleSelectChange={this.handleSelectTimeChange}
              value={sessionTime.toString()}
              id="sessionTime"
              small
            />
          </div>
        </div>
      </SettingsContent>
    );
  }
}

export default SettingsSession;
