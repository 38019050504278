import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import TeamTable from 'components/TeamTable';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal.component';
import Modal from 'components/Shared/Modal/Modal.component';

import { TeamContext } from 'contexts/TeamProvider';
import { UserContext } from 'contexts/UserProvider';

import { removeFromTeam, revokeTeamUser } from 'helpers/firebase.helper';
import { NAV_DASHBOARD } from 'constants/routingPaths';
import { PATH_TEAMS, LAB_TEAM_DASHBOARD_VIEW } from 'constants/analytics';

import Analytics from 'helpers/universal-analytics.helper';

import theme from 'style/theme';

let members = [];
let invitees = [];

const onlineMembers = members =>
  members.filter(member => member.status.online === true).length;

const TeamDashboard = ({ ...props }) => {
  const {
    state: { team, members: teamMembers, invites: teamInvites, isManager },
    toggleInviteModal,
  } = useContext(TeamContext);

  const {
    state: { user: userState },
  } = useContext(UserContext);

  const [isRemoveMemberModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRemoveSelfModalOpen, setIsRemoveSelfModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState('');

  const [isRevokeUserModalOpen, setIsRevokeUserModalOpen] = useState(false);
  const [revokeEmail, setRevokeEmail] = useState('');

  useEffect(() => {
    Analytics.trackPageView(PATH_TEAMS, LAB_TEAM_DASHBOARD_VIEW);
  }, []);

  const openInviteModal = () => {
    toggleInviteModal();
  };

  const handleRemoveTeamMember = member => {
    setUserToRemove(member);
    setIsRemoveModalOpen(true);
  };

  const removeTeamMember = async () => {
    const { team } = userState;
    await removeFromTeam(userToRemove.userId, team);
    setIsRemoveSelfModalOpen(true);
  };

  const handleMemberLeaveTeam = () => {
    setIsRemoveSelfModalOpen(true);
  };

  const removeSelf = async () => {
    const { team, firebaseUserId } = userState;

    await removeFromTeam(firebaseUserId, team);

    setIsRemoveSelfModalOpen(false);

    // redirect user to the regular dashboard
    props.history.push(NAV_DASHBOARD);
  };

  const handleRevokeUser = email => {
    setRevokeEmail(email);
    setIsRevokeUserModalOpen(true);
  };

  const revokeUser = async () => {
    const { team } = userState;
    await revokeTeamUser(revokeEmail, team);

    setIsRevokeUserModalOpen(false);
  };

  let teamLogo = '';
  let teamName = '';

  if (team && team.logo) {
    teamLogo = team.logo;
  }

  if (team && team.name) {
    teamName = team.name;
  }

  if (teamMembers) {
    members = Object.values(teamMembers);
  }

  if (teamInvites) {
    invitees = Object.values(teamInvites);
  }

  return (
    <div className="TeamDashboard">
      <div className="TeamDashboard-header">
        {teamLogo ? <img src={teamLogo} alt={`${teamName} logo`} /> : <div />}

        <div className="team-text">
          <h2>
            {teamName}{' '}
            {!isManager && (
              <span onClick={handleMemberLeaveTeam} className="leave-team">
                (Leave team)
              </span>
            )}
          </h2>
          <p>
            {onlineMembers(members)} out of {members.length} members online
          </p>
        </div>

        {isManager && (
          <Button gradient big onClick={openInviteModal}>
            <div className="plus-sign">+</div> Invite new member
          </Button>
        )}
      </div>
      {team && (
        <TeamTable
          members={members}
          isManager={isManager}
          invitees={invitees}
          handleRemoveTeamMember={handleRemoveTeamMember}
          handleRevokeUser={handleRevokeUser}
        />
      )}

      <style jsx>
        {`
          img {
            width: 34px;
            height: 34px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
            border: 2px solid #ffffff;
            border-radius: 4px;
            margin-right: 0.5em;
          }

          h2 {
            font-weight: bold;
            font-size: 26px;
            line-height: 31px;
          }

          h2 span {
            font-size: 14px;
            color: ${theme.color.white};
            text-decoration: underline;
            font-weight: normal;
          }

          h2,
          p {
            color: ${theme.color.white};
            text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
          }

          p {
            font-size: 14px;
          }

          .leave-team {
            display: inline-block;
          }

          .TeamDashboard-header {
            margin-bottom: 2em;
            display: grid;
            grid-template-columns: max-content max-content auto;
          }

          .TeamDashboard-header :global(.Button) {
            margin-top: 0;
            width: 200px;
            justify-self: end;
            font-size: 14px;
          }

          .plus-sign {
            display: inline-block;
            font-weight: normal;
            margin-right: 5px;
            font-size: 28px;
            line-height: 0;
            transform: translateY(2px);
          }

          .TeamDashboard {
            padding: 34px;
            display: grid;
            grid-template-rows: max-content max-content auto;
            height: 100vh;
          }
        `}
      </style>
      <Modal
        open={isRemoveMemberModalOpen}
        onCloseModal={() => setIsRemoveModalOpen(false)}
      >
        <ConfirmationModal
          open={isRemoveMemberModalOpen}
          title="Are you sure you want to remove this member?"
          subTitle={userToRemove.name}
          okTitle="Remove member"
          okAction={removeTeamMember}
          cancelAction={() => setIsRemoveModalOpen(false)}
        />
      </Modal>
      <Modal
        open={isRemoveSelfModalOpen}
        onCloseModal={() => setIsRemoveSelfModalOpen(false)}
      >
        <ConfirmationModal
          open={isRemoveSelfModalOpen}
          title={`Are you sure you want to leave the ${teamName} Team?`}
          okTitle="Leave Team"
          okAction={removeSelf}
          cancelAction={() => setIsRemoveSelfModalOpen(false)}
        />
      </Modal>
      <Modal
        open={isRevokeUserModalOpen}
        onCloseModal={() => setIsRevokeUserModalOpen(false)}
      >
        <ConfirmationModal
          open={isRevokeUserModalOpen}
          title="Are you sure you want to revoke this member's invite?"
          subTitle={revokeEmail}
          okTitle="Revoke member"
          okAction={revokeUser}
          cancelAction={() => setIsRevokeUserModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

TeamDashboard.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default TeamDashboard;
