const settings = {
  IS_FIRST_VISIT_DONE: 'isFirstVisitDone',
  IS_EXTENSION_INSTALLED: 'isExtensionInstalled',
  DEFAULT_BROWSER: 'defaultBrowser',
  IS_CHROME_INSTALLED: 'isChromeInstalled',
  IS_FIREFOX_INSTALLED: 'isFirefoxInstalled',
  IS_MUSIC_ACTIVE: 'isMusicActive',
  REOPEN_BLOCKED_APPS: 'reopenBlockedApps',
  IS_BLOCKING_APPS: 'isBlockingApps',
  IS_BLOCKING_PAGES: 'isBlockingPages',
  BLOCKED_APPS: 'blockedApplications',
  BLOCKED_WEBSITES: 'blockedWebsites',
  BLOCKED_CATEGORIES: 'blockedCategories',
  BLOCKED_SOCIAL_SITES: 'blockedSocialSites',
  SESSION_TIME: 'sessionTime',
  SESSIONS_NUMBER: 'sessionsNumber',
  USER_SEGMENT: 'userSegment',
  USER_EMAIL: 'userEmail',
  USER_WEEKLY: 'userWeekly',
  UUID: 'uid',
  HOTKEYS: 'hotkeys',
  HOTKEY_EDITING: 'shortcutEditing',
  WEBHOOK_URL: 'webhookUrl',
  IS_WEBHOOK_ACTIVE: 'isWebhookActive',
  EXTENSION_DATA: 'extensionData',
  APPLICATION_STATE: 'applicationState',
  IS_DATA_SENT_AFTER_FIRST_VISIT: 'isDataSentAfterFirstVisit',
  GRAPH_DATA: 'graphData',
  IS_LS_FAILURE_MODAL_VISIBLE: 'isLsFailureModalVisible',
  SUBSCRIPTION_UPDATE_URL: 'subscriptionUpdateUrl',
  SUBSCRIPTION_CANCEL_URL: 'subscriptionCancelUrl',
  IS_UPGRADE_MODAL_VISIBLE: 'isUpgradeModalVisible',
  IS_USER_PRO: 'isUserPro',
  USER_CURRENCY: 'userCurrency',
  APP_OPENS_AT_LOGIN: 'appOpensAtLogin',
  FIREBASE_UID: 'firebaseUID',
  IS_ONLINE: 'isOnline',
  TEAM_NAME: 'teamName',
  IS_TEAM_FIRST_VISIT_DONE: 'isTeamFirstVisitDone',
};

module.exports = settings;
