import React, { useEffect, useState, useContext } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import PropTypes from 'prop-types';
import { firestore } from '../firebase';
import moment from 'moment';
import { UserContext } from './UserProvider';

import {
  IPC_SM_GET_SESSIONS,
  IPC_SM_GET_DATA,
  IPC_SM_FINISH_DAY,
  IPC_SM_DATE_CHANGED,
  IPC_SM_CHANGE_DAY,
  IPC_SM_END_SESSION,
  IPC_SM_START_DAY,
  IPC_SM_SESSIONS_UPDATED,
  IPC_GO_SERENE,
  IPC_STOP_SESSION,
  IPC_SM_STATE_CHANGED,
} from 'messages/ipc-messages';

import { IDLE } from 'constants/sessionManagerStates';

import { updateProfileData } from 'helpers/firebase.helper';

export const SessionsContext = React.createContext();

const { ipcRenderer } = window.require('electron');

const SessionsProvider = ({ children }) => {
  const today = moment.utc().startOf('day');
  const [sessions, setSessions] = useState([]);
  const [sessionManagerState, setSessionManagerState] = useState(IDLE);
  const [isSerene, setIsSerene] = useState(false);
  const [currentDayId, setCurrentDayId] = useState(null);
  const {
    state: { user },
  } = useContext(UserContext);

  const updateCurrentDayInFirebase = async data => {
    await firestore
      .collection(`profiles/${user.firebaseUserId}/days`)
      .doc(currentDayId)
      .update({
        achievedGoal: data.achievedGoal,
        productivity: data.productivity,
      });
  };

  const addSessionInFirebase = async data => {
    await firestore.collection(`sessions`).add({
      owner: user.firebaseUserId,
      day: currentDayId,
      created: new Date(data.startTime),
      distractions: data.distractions,
      duration: data.counterStartTime,
      completionState: data.state,
    });
  };

  const onDayEvent = async (event, data) => {
    await updateCurrentDayInFirebase(data);
  };

  const onSessionEnd = async (event, data) => {
    await addSessionInFirebase(data);
  };

  const onSessionManagerStateChanged = (event, sessionManagerState) => {
    setSessionManagerState(sessionManagerState);
  };

  const onSessionUpdated = (event, sessions) => {
    setSessions(sessions);
  };

  const [value, loading, error] = useCollection(
    user && user.firebaseUserId
      ? firestore
          .collection(`profiles/${user.firebaseUserId}/days`)
          .where('date', '==', new Date(today))
          .limit(1)
      : null,
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  // This ensures that there is always a 'day' at firebase, sessions always need a dayId when stored.
  useEffect(() => {
    if (value && value.docs.length === 0) {
      (async function setCurrentDay() {
        const day = await firestore
          .collection(`profiles/${user.firebaseUserId}/days`)
          .add({
            date: new Date(today),
            achievedGoal: null,
            productivity: null,
          });
        setCurrentDayId(day.id);
      })();
    } else if (value && value.docs.length > 0) {
      setCurrentDayId(value.docs[0].id);
    }
  }, [value]);

  useEffect(() => {
    const { sessions } = ipcRenderer.sendSync(IPC_SM_GET_SESSIONS);
    setSessions(sessions);

    const { state: sessionManagerState } = ipcRenderer.sendSync(
      IPC_SM_GET_DATA,
    );

    setCurrentDayId(currentDayId);

    setSessionManagerState(sessionManagerState);

    const onSereneStart = event => {
      updateProfileData(user.firebaseUserId, { serene: true });
    };

    const onSereneEnd = event => {
      updateProfileData(user.firebaseUserId, { serene: false });
    };

    ipcRenderer.on(IPC_SM_STATE_CHANGED, onSessionManagerStateChanged);
    ipcRenderer.on(IPC_SM_SESSIONS_UPDATED, onSessionUpdated);
    ipcRenderer.on(IPC_SM_END_SESSION, onSessionEnd);
    ipcRenderer.on(IPC_SM_START_DAY, onDayEvent);
    ipcRenderer.on(IPC_SM_FINISH_DAY, onDayEvent);
    ipcRenderer.on(IPC_SM_DATE_CHANGED, onDayEvent);
    ipcRenderer.on(IPC_SM_CHANGE_DAY, onDayEvent);
    ipcRenderer.on(IPC_GO_SERENE, onSereneStart);
    ipcRenderer.on(IPC_STOP_SESSION, onSereneEnd);

    return () => {
      ipcRenderer.removeListener(
        IPC_SM_STATE_CHANGED,
        onSessionManagerStateChanged,
      );
      ipcRenderer.removeListener(IPC_SM_SESSIONS_UPDATED, onSessionUpdated);
      ipcRenderer.removeListener(IPC_SM_END_SESSION, onSessionEnd);
      ipcRenderer.removeListener(IPC_SM_START_DAY, onDayEvent);
      ipcRenderer.removeListener(IPC_SM_FINISH_DAY, onDayEvent);
      ipcRenderer.removeListener(IPC_SM_DATE_CHANGED, onDayEvent);
      ipcRenderer.removeListener(IPC_SM_CHANGE_DAY, onDayEvent);
      ipcRenderer.removeListener(IPC_GO_SERENE, onSereneStart);
      ipcRenderer.removeListener(IPC_STOP_SESSION, onSereneEnd);
    };
  }, [user, currentDayId]);

  return (
    <SessionsContext.Provider
      value={{
        state: {
          loading,
          sessions,
          sessionManagerState,
          currentDayId,
        },
        toggleSereneMode: () => {
          setIsSerene(!isSerene);
        },
      }}
    >
      {children}
    </SessionsContext.Provider>
  );
};
SessionsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default SessionsProvider;
