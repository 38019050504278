import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import { NAV_DASHBOARD } from 'constants/routingPaths';

import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';
import CompletedSessionsList from 'components/Shared/SessionList/CompletedSessionList.component';
import CompletedSessionStack from 'components/Shared/SessionList/CompletedSessionStack.component';
import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';

import './DayDebrief.scss';

const DayDebrief = ({ history, location }) => {
  if (!location.state) {
    history.push({
      pathname: NAV_DASHBOARD,
    });
    return null;
  }

  const onCtaClick = () => {
    history.replace(NAV_DASHBOARD);
  };

  const { sessions, day, goal } = location.state.data;
  return (
    <div>
      <Layout>
        <Menu>
          <TodayGoal />
          <h3> COMPLETED </h3>
          <CompletedSessionsList sessions={sessions} />
        </Menu>
        <Content>
          <div className="DayDebrief">
            <div className="DayDebrief__session-stack-container">
              <div className="DayDebrief__session-stack">
                <CompletedSessionStack sessions={sessions} />
              </div>
            </div>
            <div className="DayDebrief__session-goal-box">
              <div className="DayDebrief__day">
                {day}
                <span className="DayDebrief__day--goal-text">- GOAL</span>
              </div>
              <div className="DayDebrief__goal">{goal}</div>
            </div>
            <div className="DayDebrief__dashboard-cta">
              <Button name="finishYourDay" primary wider onClick={onCtaClick}>
                Go to Dashboard
              </Button>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

DayDebrief.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      date: PropTypes.objectOf({
        day: PropTypes.string,
        goal: PropTypes.string,
        sessions: PropTypes.arrayOf(PropTypes.object),
      }),
      data: PropTypes.objectOf({
        day: PropTypes.string,
        goal: PropTypes.string,
        sessions: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
};

export default DayDebrief;
