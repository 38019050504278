import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { isURL } from 'validator';
import classnames from 'classnames';

import {
  Toggle,
  Button,
  InputText,
} from '@ventureharbour/serene-shared-components';

import SettingsContent from '../SettingsContent.component';
import UpgradePlan from 'components/UpgradePlan/UpgradePlan.component';
import Tooltip from 'components/Shared/Tooltip/Tooltip.component';

import { ReactComponent as AndroidIcon } from 'images/android-icon.svg';
import { ReactComponent as LightIcon } from 'images/light-bulb-icon.svg';
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg';
import { ReactComponent as QuestionIcon } from 'images/help-icon.svg';

import Analytics from 'helpers/universal-analytics.helper';

import { LAB_WEBHOOK_SETTINGS_VIEW } from 'constants/analytics';
import LINKS from 'constants/links';
import { WEBHOOK } from 'constants/loggerGroups';
import { INFO, WARNING } from 'constants/logingLevels';
import { NAV_SETTINGS_WEBHOOK } from 'constants/routingPaths';

import {
  IS_WEBHOOK_ACTIVE,
  WEBHOOK_URL,
  IS_USER_PRO,
} from 'messages/available-settings';
import {
  IPC_GET_SETTINGS,
  IPC_CHANGE_SETTINGS,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import './SettingsWebhook.scss';
import SettingsAccountConfirmation from '../SettingsAccount/SettingsAccountConfirmation/SettingsAccountConfirmation.component';
import { UserContext } from 'contexts/UserProvider';

const { ipcRenderer, remote, shell } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

class SettingsWebhook extends Component {
  state = {
    isWebhookActive: false,
    webhookUrl: '',
    isTooltipVisible: false,
    inputInvalid: false,
    showUpgradeOverlay: false,
  };

  componentDidMount() {
    ipcRenderer.on(IPC_SETTING_CHANGED, this.handleSettingChange);

    const { isWebhookActive, webhookUrl, isUserPro } = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      [IS_WEBHOOK_ACTIVE, WEBHOOK_URL, IS_USER_PRO],
    );

    this.setState({
      isWebhookActive,
      webhookUrl,
      isUserPro,
    });

    Analytics.trackPageView(NAV_SETTINGS_WEBHOOK, LAB_WEBHOOK_SETTINGS_VIEW);
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SETTING_CHANGED, this.handleSettingChange);
  }

  handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_USER_PRO) {
      this.setState(prevState => ({
        newValue,
        showUpgradeOverlay: newValue ? false : prevState.showUpgradeOverlay,
      }));
    }
  };

  timeout = null;

  handleToggle = value => {
    if (!this.state.isUserPro && value) {
      this.setState({ showUpgradeOverlay: true });
      return;
    }

    this.setState({ isWebhookActive: value });
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: IS_WEBHOOK_ACTIVE,
      value,
    });
  };

  handleKeypress = e => {
    if (e.key === 'Enter') {
      this.saveWebhookUrl();
    }
  };

  showTooltip = () => {
    this.setState({ isTooltipVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipVisible: false });
  };

  saveWebhookUrl = async () => {
    const { webhookUrl } = this.state;

    const validatorOptions = {
      protocols: ['http', 'https'],
    };

    // need to validate that it is a URL.
    if (webhookUrl !== '' && !isURL(webhookUrl, validatorOptions)) {
      this.setState({
        inputInvalid: true,
      });

      log(INFO, `Webhook Url ${webhookUrl} is not valid`, WEBHOOK);
      return;
    }

    log(WARNING, `Added webhook url ${webhookUrl} to settings`, WEBHOOK);

    this.setState({
      inputInvalid: false,
    });

    await ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: WEBHOOK_URL,
      value: webhookUrl,
    });
  };

  updateTemporaryHookUrl = e => {
    const webhookUrl = e.target.value;
    this.setState({
      webhookUrl,
    });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // Save/validate after 1s of inactivity
    this.timeout = setTimeout(() => {
      this.saveWebhookUrl();
    }, 1000);
  };

  handleLinkClick = e => {
    e.preventDefault();
    const url = e.target.href;
    shell.openExternal(url);
  };

  handleButtonClick = url => {
    shell.openExternal(url);
  };

  handleCloseUpgradePlan = () => {
    this.setState({ showUpgradeOverlay: false });
  };

  render() {
    const {
      isWebhookActive,
      webhookUrl,
      isTooltipVisible,
      inputInvalid,
      showUpgradeOverlay,
    } = this.state;

    const inputClass = classnames({
      'Input--invalid': inputInvalid,
    });

    const { userConfirmed, user } = this.props;

    let overlay = '';
    if (showUpgradeOverlay && userConfirmed) {
      overlay = (
        <div className="SettingsWebhook__overlay">
          <div className="overlay__title">
            Go Pro today!{' '}
            <span role="img" aria-label="hands-up">
              🙌
            </span>
          </div>
          <UpgradePlan onCloseModal={this.handleCloseUpgradePlan} />
        </div>
      );
    } else if (showUpgradeOverlay) {
      overlay = (
        <SettingsAccountConfirmation
          show
          onClose={this.handleCloseUpgradePlan}
          user={user}
        />
      );
    }

    return (
      <SettingsContent
        header="Webhook"
        tagline="Configure your webhook settings."
        className="SettingsWebhook"
      >
        {overlay}
        <div className="SettingsWebhook__intro">
          Webhooks enable you to automate your workflow by sending a
          notification to a third party app or service when you enter Serene
          mode.{' '}
          <div
            className="SettingsWebhook__intro-question-wrapper"
            onMouseEnter={this.showTooltip}
            onMouseLeave={this.hideTooltip}
          >
            <QuestionIcon className="SettingsWebhook__intro-question" />
            <Tooltip isVisible={isTooltipVisible}>
              <p>
                <strong>An example</strong> would be whenever you go in and out
                of Serene mode in the app, we send a notification to turn on and
                off a Philips Hue light or change your phone to silent and off
                again.
              </p>

              <p>
                <a
                  href={LINKS.WEBHOOK_INFORMATION}
                  onClick={this.handleLinkClick}
                >
                  Learn more about Webhooks
                </a>
                .
              </p>
            </Tooltip>
          </div>
        </div>

        <Toggle small active={isWebhookActive} onChange={this.handleToggle}>
          Enable webhooks when session starts and ends
        </Toggle>

        <div className="SettingsWebhook__input-wrapper">
          <label htmlFor="webhookUrl" className="SettingsWebhook__label">
            Webhook URL
          </label>

          <InputText
            id="webhookUrl"
            type="text"
            onChange={this.updateTemporaryHookUrl}
            placeholder="https://"
            onKeyPress={this.handleKeypress}
            value={webhookUrl}
            className={inputClass}
          />

          {inputInvalid && (
            <div className="SettingsWebhook__input-error">
              Please enter a valid webhook url.
            </div>
          )}

          <p className="SettingsWebhook__helper-text">
            <em>Data being sent</em>: <code>sereneMode: true or false</code>
          </p>
        </div>

        <div className="SettingsWebhook__examples">
          <p>
            To get started, try some of <strong>these examples</strong>.
          </p>

          <Button
            onClick={() => this.handleButtonClick(LINKS.WEBHOOK_DO_NOT_DISTURB)}
            gradient
          >
            <AndroidIcon />
            Turn on &lsquo;Do Not Disturb&rsquo;
          </Button>

          <Button
            onClick={() => this.handleButtonClick(LINKS.WEBHOOK_LIGHTS)}
            gradient
          >
            <LightIcon />
            Control your lights
          </Button>

          <Button
            onClick={() => this.handleButtonClick(LINKS.WEBHOOK_SLACK)}
            gradient
          >
            <SlackIcon />
            Update Slack Status
          </Button>
        </div>
      </SettingsContent>
    );
  }
}

SettingsWebhook.propTypes = {
  userConfirmed: PropTypes.bool,
  user: PropTypes.objectOf({
    email: PropTypes.email,
  }),
};

export default ({ ...props }) => {
  const {
    state: { user },
  } = useContext(UserContext);

  return (
    <SettingsWebhook
      userConfirmed={user && user.status === 'confirmed'}
      user={user}
      {...props}
    />
  );
};
