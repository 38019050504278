import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { diffDates } from 'helpers/date.helper';

import { ReactComponent as EditIcon } from 'images/edit-white-icon.svg';
import { ReactComponent as GoalIcon } from 'images/goal-icon.svg';
import {
  IPC_SM_UPDATE_DATA,
  IPC_SM_GET_DATA,
  IPC_SM_GET_DAY_DATA,
} from 'messages/ipc-messages';

import './TodayGoal.scss';

const { ipcRenderer } = window.require('electron');

class TodayGoal extends React.Component {
  static propTypes = {
    isEditable: PropTypes.bool,
    isPlanningTomorrow: PropTypes.bool,
  };

  state = {
    goal: '',
    editMode: false,
    previousGoal: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isPlanningTomorrow !== nextProps.isPlanningTomorrow) {
      const { goal, tomorrowGoal } = prevState;
      const goalToSet = nextProps.isPlanningTomorrow ? tomorrowGoal : goal;

      return {
        goal: goalToSet,
        isPlanningTomorrow: nextProps.isPlanningTomorrow,
      };
    }

    return null;
  }

  componentDidMount() {
    const { date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
    const { goal } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
    this.setState({
      goal,
      date,
      previousGoal: goal,
    });
  }

  handleClick = () => {
    this.setState({ editMode: true });
  };

  handleChange = e => {
    this.setState({ goal: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { goal } = this.state;

    if (goal.length === 0) return;
    this.setState({
      editMode: false,
      previousGoal: goal,
    });

    ipcRenderer.sendSync(IPC_SM_UPDATE_DATA, {
      goal,
    });
  };

  checkSubmit = e => {
    const { goal, previousGoal } = this.state;
    if (goal.length === 0) {
      this.setState({
        goal: previousGoal,
        editMode: false,
      });
    } else {
      this.handleSubmit(e);
    }
  };

  render() {
    const { goal, editMode, date } = this.state;

    const renderTitle = () => {
      const { isToday, isTomorrow } = diffDates(date);

      let content;

      if (isToday) {
        content = 'TODAY\u0027S';
      } else if (isTomorrow) {
        content = 'TOMORROW\u0027S';
      } else {
        content = `${moment(date).format('DD/MM/YYYY')}`;
      }

      return <h3>{content} GOAL</h3>;
    };

    return (
      <div className="TodayGoal">
        {renderTitle()}
        <div className="TodayGoal__goal">
          {editMode ? (
            <form className="TodayGoal__edit" onSubmit={this.handleSubmit}>
              <input
                onBlur={this.checkSubmit}
                autoFocus
                type="text"
                maxLength="500"
                value={goal}
                onChange={this.handleChange}
              />
            </form>
          ) : (
            <React.Fragment>
              <GoalIcon className="TodayGoal__icon TodayGoal__icon--goal" />
              <div className="TodayGoal__text" onClick={this.handleClick}>
                {goal}
              </div>
              {this.props.isEditable && (
                <EditIcon
                  onClick={this.handleClick}
                  className="TodayGoal__icon TodayGoal__icon--edit"
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default TodayGoal;
