import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { WARP_ZONE_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import {
  CURRENT_SESSION,
  NAV_DASHBOARD,
  NAV_SESSIONS,
  NAV_SETTINGS,
  ONBOARDING,
  ONBOARDING_BLOCK_SITES,
  ONBOARDING_BLOCK_APPS,
  ONBOARDING_SUCCESS,
  SET_DAY_GOAL,
  SERENE_PROTOCOL_SUCCESS_VIEW,
  TEAM_WELCOME_VIEW,
  TEAM_INVITES_VIEW,
  TEAM_ONBOARDING,
  NAV_TEAM,
  NAV_TEAM_DASHBOARD,
} from 'constants/routingPaths';

import { UserContext } from 'contexts/UserProvider';
import { TeamContext } from 'contexts/TeamProvider';
import { SettingsContext } from 'contexts/SettingsProvider';
import { SessionsContext } from 'contexts/SessionsProvider';

import './Shortcuts.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

const Shortcuts = () => {
  useEffect(() => {
    log(INFO, 'What are you doing here, hmmmm?', WARP_ZONE_VIEW);
  }, []);

  const {
    state: { user: userState },
  } = useContext(UserContext);

  const {
    state: { team: teamState },
  } = useContext(TeamContext);

  const { state: settingsState, toggleTeamsModalState } = useContext(
    SettingsContext,
  );

  const { state: sessionsState } = useContext(SessionsContext);

  return (
    <div className="Shortcuts">
      <div className="Shortcuts__links">
        <h2>WELCOME TO WARP ZONE</h2>
        <ul>
          <li>
            <Link to={ONBOARDING}>Onboarding</Link>

            <ul>
              <li>
                <Link to={SET_DAY_GOAL}>Set Day Goal</Link>
              </li>
              <li>
                <Link to={ONBOARDING_BLOCK_APPS}>Onboarding Block Apps</Link>
              </li>
              <li>
                <Link to={ONBOARDING_BLOCK_SITES}>Onboarding Block Sites</Link>
              </li>
              <li>
                <Link to={ONBOARDING_SUCCESS}>Onboarding Success</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to={CURRENT_SESSION}>CurrentSession</Link>
          </li>

          <li>
            <Link to={NAV_SESSIONS}>Sessions</Link>
          </li>
          <li>
            <Link to={NAV_SETTINGS}>Settings</Link>
          </li>

          <li>
            <Link to={NAV_DASHBOARD}>Dashboard</Link>
          </li>

          <li>
            <Link to={SERENE_PROTOCOL_SUCCESS_VIEW}>Protocol Success</Link>
          </li>

          <ul>
            <h3>Team</h3>
            <ul>
              <li>
                <Link to={NAV_TEAM}>Team</Link>
              </li>
              <li>
                <Link to={TEAM_WELCOME_VIEW}>Team Welcome</Link>
              </li>
              <li>
                <Link to={TEAM_INVITES_VIEW}>Team Invites</Link>
              </li>
              <li>
                <Link to={TEAM_ONBOARDING}>Team Onboarding</Link>
              </li>
              <li>
                <Link to={NAV_TEAM_DASHBOARD}>Team Dashboard</Link>
              </li>
            </ul>
          </ul>
        </ul>
      </div>

      <div className="Shortcuts__contexts">
        <div>
          <h2>
            Firebase Settings - <span>(UserContext)</span>
          </h2>
          <div>Email: {userState.email}</div>
          <div>Segment: {userState.segment}</div>
          <div>Weekly: {userState.weekly ? 'true' : 'false'}</div>
          <div>invitedBy: {userState.invitedBy ? 'true' : 'false'}</div>
          <div>FirebaseUID: {userState.firebaseUserId}</div>
          <div>Team ID: {userState.team}</div>
        </div>

        <div>
          <h2>
            Firebase Team Settings <span>(TeamContext)</span>
          </h2>
          {teamState ? (
            <React.Fragment>
              <div>Name: {teamState.name}</div>
              <div>
                Logo:{' '}
                {teamState.logo && (
                  <img
                    src={teamState.logo}
                    alt={teamState.name}
                    height="75"
                    width="75"
                  />
                )}
              </div>
              <div>Is Team Owner: {teamState.owner ? 'true' : false}</div>
            </React.Fragment>
          ) : (
            <div>no team...</div>
          )}
        </div>

        <div>
          <h2>
            Electron Settings <span>(SettingsContext)</span>
          </h2>
          <div>IsOnline: {settingsState.isOnline ? 'true' : 'false'}</div>
          <div>User Currency: {settingsState.currency}</div>
          <div>
            Show Team Modal {settingsState.showTeamsModal ? 'true' : 'false'}
          </div>
          <button onClick={toggleTeamsModalState}>Show Team Modal</button>
        </div>

        <div>
          <h2>Sessions Context</h2>
          <div>Day Id: {sessionsState.currentDayId}</div>
          <div>
            Today&rsquo;s Session Number : {sessionsState.sessions.length}
          </div>
          <div>Session Manager State: {sessionsState.sessionManagerState}</div>
        </div>
      </div>

      <div className="Shortcuts__tube">
        <img src="images/warpzone.png" alt="" />
      </div>
    </div>
  );
};

export default Shortcuts;
