import React from 'react';
import PropTypes from 'prop-types';

import SessionList from './SessionList.component';

const NextSessionList = props => {
  const sessions = props.sessions.filter(session => !session.isFinished) || [];
  return <SessionList {...props} sessions={sessions} />;
};

NextSessionList.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      startingFrom: PropTypes.string,
      isActive: PropTypes.bool,
      isFinished: PropTypes.bool,
    }).isRequired,
  ),
};

export default NextSessionList;
