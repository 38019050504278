import axios from 'axios';

import { AXIOS_REQUEST } from 'constants/loggerGroups';
import { ERROR, INFO } from 'constants/logingLevels';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

export const initLogingForAxios = () => {
  axios.interceptors.response.use(
    response => {
      const { config, data } = response;

      log(
        INFO,
        `Response from ${config.url} - ${JSON.stringify(data)}`,
        AXIOS_REQUEST,
      );

      return response;
    },
    err => {
      log(
        ERROR,
        `Response error from ${err.config.url} - ${err}`,
        AXIOS_REQUEST,
      );
      return Promise.reject(err);
    },
  );

  axios.interceptors.request.use(request => {
    const { url, data, method } = request;
    let message = `Sending request to ${url}`;
    if (method === 'post') {
      message = `${message} with data ${JSON.stringify(data)}`;
    }
    log(INFO, message, AXIOS_REQUEST);
    return request;
  });
};
