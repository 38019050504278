import ua from 'universal-analytics';

import { GOOGLE_ACCOUNT_ID } from 'config';

const { IPC_GET_SETTINGS } = require('../messages/ipc-messages');
const { USER_SEGMENT, UUID } = require('../messages/available-settings');

const { ipcRenderer } = window.require('electron');

const getVisitor = async () => {
  try {
    const { uid, userSegment } = await ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      UUID,
      USER_SEGMENT,
    ]);

    const visitor = ua(GOOGLE_ACCOUNT_ID, {
      uid,
      cd2: userSegment,
    });
    return visitor;
  } catch (error) {
    console.error('getVisitor error', error);
  }
};

const trackPageView = async (path, label) => {
  try {
    const visitor = await getVisitor();
    visitor
      .pageview({
        dp: path,
        dt: label,
      })
      .send();
  } catch (error) {
    console.error('Error in trackPageView', error);
  }
};

const trackEvent = async (category, action, label, value) => {
  try {
    const visitor = await getVisitor();
    visitor
      .event({
        ec: category,
        ea: action,
        el: label,
        ev: value,
      })
      .send();
  } catch (error) {
    console.error('Error in trackPageView', error);
  }
};

export default {
  trackEvent,
  trackPageView,
};
