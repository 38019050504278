import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import Content from 'components/Shared/Layout/Content/Content.component';
import Layout from 'components/Shared/Layout/Layout.component';
import Menu from 'components/Shared/Layout/Menu/Menu.component';
import CompletedSessionsList from 'components/Shared/SessionList/CompletedSessionList.component';
import CompletedSessionStack from 'components/Shared/SessionList/CompletedSessionStack.component';
import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';
import StateRadioGroup from 'components/StateRadioGroup/StateRadioGroup.component';
import ProductivityRadioGroup from 'components/ProductivityRadioGroup/ProductivityRadioGroup.component';
import { REVIEW_DAY_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { DONE, HALFDONE, NOTDONE } from 'constants/sessionStates';

import {
  IPC_SM_UPDATE_DATA,
  IPC_SM_FINISH_DAY,
} from '../../../../messages/ipc-messages';

import './DaySummary.scss';

const electron = window.require('electron');
const { ipcRenderer, remote } = electron;
const { log } = remote.require('../src/helpers/Logger/logger.helper');

const DaySummary = ({ onDayReviewed, sessions }) => {
  const [achievedGoal, setAchievedGoal] = useState('');
  const [productivity, setProductivity] = useState('');
  const [view, setView] = useState('achieveView');

  const onButtonClick = () => {
    log(
      INFO,
      `Clicked day reviewed: achievedGoal ${achievedGoal}, productivity ${productivity}`,
      REVIEW_DAY_VIEW,
    );

    onDayReviewed(achievedGoal, productivity);
    ipcRenderer.send(IPC_SM_UPDATE_DATA, { achievedGoal, productivity });
    ipcRenderer.send(IPC_SM_FINISH_DAY, true);
  };

  const onStateChange = state => {
    setAchievedGoal(state);
  };

  const onProductivityChange = value => {
    setProductivity(value);
  };

  const onAchieveButtonClick = () => {
    setView('productiveView');
  };

  return (
    <Layout>
      <Menu hasNavigation>
        <TodayGoal />
        <h3> COMPLETED </h3>
        <CompletedSessionsList sessions={sessions} />
      </Menu>
      <Content>
        <div className="DaySummary">
          <div className="DaySummary__session-stack-container">
            <div className="DaySummary__session-stack">
              <CompletedSessionStack sessions={sessions} />
            </div>
          </div>
          {view === 'achieveView' ? (
            <div className="DaySummary__day-state">
              <h2 className="DaySummary__title DaySummary__title--goal">
                Did you achieve your goal for the day?
              </h2>
              <StateRadioGroup
                onChange={onStateChange}
                availableOptions={[DONE, HALFDONE, NOTDONE]}
                isLabelVisible
              />
              <Button
                name="finishYourDay"
                className="btn--big"
                onClick={onAchieveButtonClick}
                primary
                disabled={achievedGoal === ''}
              >
                Continue
              </Button>
            </div>
          ) : (
            <React.Fragment>
              {
                // show this after user has pressed StateRadioGroup
              }

              <div className="DaySummary__productivity-factor">
                <h2 className="DaySummary__title">
                  How productive were you today?
                </h2>
                <ProductivityRadioGroup
                  value={productivity}
                  onChange={onProductivityChange}
                />
              </div>
              <Button
                name="finishYourDay"
                className="btn--big"
                onClick={onButtonClick}
                primary
                disabled={productivity === ''}
              >
                Finish your day
              </Button>
            </React.Fragment>
          )}
        </div>
      </Content>
    </Layout>
  );
};

DaySummary.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      counterStartTime: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      task: PropTypes.string.isRequired,
      startingFrom: PropTypes.string,
      isActive: PropTypes.bool,
      isFinished: PropTypes.bool,
    }).isRequired,
  ),
  onDayReviewed: PropTypes.func,
};

export default DaySummary;
