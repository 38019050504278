import React, { Component } from 'react';
import classnames from 'classnames';

import { Toggle } from '@ventureharbour/serene-shared-components';

import BlockApps from 'components/Blockapps/Blockapps.component';
import { LAB_BLOCK_APPS_SETTINGS } from 'constants/analytics';
import { NAV_SETTINGS_APPS } from 'constants/routingPaths';
import Analytics from 'helpers/universal-analytics.helper';
import {
  IS_BLOCKING_APPS,
  REOPEN_BLOCKED_APPS,
} from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS, IPC_GET_SETTINGS } from 'messages/ipc-messages';

import SettingsContent from '../SettingsContent.component';

import './Block.scss';

const { ipcRenderer } = window.require('electron');

class SettingsBlockApps extends Component {
  state = {
    isBlockingApps: false,
    reopenBlockedApps: false,
  };

  componentDidMount() {
    const { isBlockingApps, reopenBlockedApps } = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      [IS_BLOCKING_APPS, REOPEN_BLOCKED_APPS],
    );

    this.setState({
      isBlockingApps,
      reopenBlockedApps,
    });

    Analytics.trackPageView(NAV_SETTINGS_APPS, LAB_BLOCK_APPS_SETTINGS);
  }

  handleToggle = (name, value) => {
    const key =
      name === IS_BLOCKING_APPS ? 'isBlockingApps' : 'reopenBlockedApps';

    this.setState({ [key]: value });
    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name,
      value,
    });
  };

  render() {
    const { isBlockingApps, reopenBlockedApps } = this.state;

    return (
      <SettingsContent
        className="SettingsBlocks"
        header="Block apps"
        tagline="These apps will be blocked when you start a focus session or Go Serene"
      >
        <Toggle
          small
          active={isBlockingApps}
          onChange={val => this.handleToggle(IS_BLOCKING_APPS, val)}
        >
          Block distracting apps
        </Toggle>
        <div className="SettingsBlocks__content">
          <div className="SettingsBlocks__white-box">
            <BlockApps settingsView />
          </div>
          <div
            className={classnames('content__toggle-wrapper', {
              'content__toggle-wrapper--disabled': !isBlockingApps,
            })}
          >
            <Toggle
              small
              active={reopenBlockedApps}
              onChange={val => this.handleToggle(REOPEN_BLOCKED_APPS, val)}
            >
              Re-open apps at the end of a session
            </Toggle>
          </div>
        </div>
      </SettingsContent>
    );
  }
}

export default SettingsBlockApps;
