const sessionManagerStates = {
  IDLE: 'IDLE',
  REVIEW: 'REVIEW',
  BREAK: 'BREAK',
  SESSION: 'SESSION',
  SERENE: 'SERENE',
  DAY_FINISHED: 'DAY_FINISHED',
};

module.exports = sessionManagerStates;
