import React, { useContext, useMemo } from 'react';

import {
  NAV_TEAM_DASHBOARD,
  TEAM_ONBOARDING,
  NAV_PLANS_TEAM,
} from 'constants/routingPaths';

import { Redirect } from 'react-router-dom';

import { TeamContext } from 'contexts/TeamProvider';
import { SubscriptionContext } from 'contexts/SubscriptionProvider';
import Loader from 'components/Shared/Loader/Loader.component';

const Team = () => {
  const { activeSubscription, loading } = useContext(SubscriptionContext);
  const {
    state: { loading: teamLoading, team },
  } = useContext(TeamContext);
  const teamSubscription = useMemo(
    () => activeSubscription && activeSubscription.type === 'team',
    [activeSubscription],
  );
  if (loading || teamLoading) {
    return <Loader />;
  } else if (teamSubscription && !team) {
    return <Redirect to={TEAM_ONBOARDING} />;
  } else if (team) {
    return <Redirect to={NAV_TEAM_DASHBOARD} />;
  }
  return <Redirect to={NAV_PLANS_TEAM} />;
};

export default Team;
