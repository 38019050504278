import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, InputText } from '@ventureharbour/serene-shared-components';
import ScreenTitle from 'components/ScreenTitle';
import ScreenSubTitle from 'components/ScreenSubTitle';
import Screen from 'components/Screen';

import theme from 'style/theme';

import { PATH_TEAMS, LAB_TEAM_MEMBER_WELCOME_VIEW } from 'constants/analytics';
import { NAV_TEAM_DASHBOARD } from 'constants/routingPaths';

import Analytics from 'helpers/universal-analytics.helper';

import { IS_TEAM_FIRST_VISIT_DONE } from 'messages/available-settings';
import { IPC_CHANGE_SETTINGS } from 'messages/ipc-messages';

const { ipcRenderer } = window.require('electron');

const TeamsWelcome = ({ history }) => {
  const [memberName, setMemberName] = useState('');
  const [isInputValid, setIsInputValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    Analytics.trackPageView(PATH_TEAMS, LAB_TEAM_MEMBER_WELCOME_VIEW);
  }, []);

  const handleInputChange = e => {
    const name = e.target.value;

    if (name === '') {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
    }

    setMemberName(name);
  };

  const handleFormSubmit = async e => {
    setFormSubmitted(true);
    e.preventDefault();

    // Handle the submitted data.
    if (isInputValid) {
      // await updateTeamMember({ name: memberName }); // When function to updateTeamMember has been created

      // AHTODO
      // Set firstTeamVisit Electron setting to false. When I've done that in next ticket.
      await ipcRenderer.send(IPC_CHANGE_SETTINGS, [
        {
          name: IS_TEAM_FIRST_VISIT_DONE,
          value: true,
        },
      ]);

      history.push({
        pathname: NAV_TEAM_DASHBOARD,
      });
    }
  };

  return (
    <Screen centered>
      <ScreenTitle>Welcome to your team</ScreenTitle>

      <ScreenSubTitle>
        Please enter your name for your profile on your team account.
      </ScreenSubTitle>

      <form onSubmit={handleFormSubmit}>
        <label htmlFor="personName">Full name</label>

        <InputText
          id="personName"
          placeholder="Your name"
          onChange={handleInputChange}
          value={memberName}
          invalid={!isInputValid && formSubmitted === true}
          dark
        />

        <Button
          name="Join my team"
          className="btn--big"
          primary
          disabled={!isInputValid}
        />
      </form>
      <style jsx>
        {`
          label {
            font-size: 16px;
            line-height: 1.2;
            display: block;
            width: 100%;
            text-align: left;
            color: ${theme.color.white};
            margin-top: 50px;
            font-weight: bold;
            margin-bottom: 12px;
            cursor: pointer;
          }

          :global(.Input) {
            background-color: rgba(0, 0, 0, 0.1);
            color: ${theme.color.white};
          }

          :global(.Input:focus, .Input:hover) {
            background-color: rgba(0, 0, 0, 0.18);
            box-shadow: 0 0 11px -2px #eb7070;
          }

          :global(h2) {
            margin-top: 20px;
          }

          :global(.Input, .Input__wrapper, .Button) {
            width: 100%;
            max-width: 600px;
          }

          :global(.Input__wrapper) {
            margin-bottom: 55px;
          }

          form :global(.Button:disabled) {
            opacity: 0.6;
          }

          :global(.Input::placeholder) {
            color: ${theme.color.placeholderWhite};
          }
        `}
      </style>
    </Screen>
  );
};

TeamsWelcome.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default TeamsWelcome;
