import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as CloseIcon } from 'images/close-icon.svg';

import './Modal.scss';

const ModalComponent = ({
  open,
  title,
  caption,
  children,
  onCloseModal,
  noWhitebox,
  wide,
}) => {
  const modalClasses = classnames({
    'Modal--wide': wide,
    'Modal--opened': open,
    Modal: true,
  });
  return (
    <div className={modalClasses}>
      {onCloseModal && (
        <div className="Modal__close-btn" onClick={onCloseModal}>
          <CloseIcon />
        </div>
      )}
      <div className="Modal__content">
        <div className="Modal__title">{title}</div>
        {caption ? <div className="Modal__caption">{caption}</div> : null}

        <div className="Modal__white-box-container">
          {noWhitebox ? (
            children
          ) : (
            <div className="Modal__white-box">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool,
  noWhitebox: PropTypes.bool,
  title: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onCloseModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  wide: PropTypes.bool,
};

export default ModalComponent;
