export default {
  TERMS: 'https://sereneapp.com/terms-of-use',
  PRIVACY: 'https://sereneapp.com/privacy',
  CHROME_EXTENSION:
    'https://chrome.google.com/webstore/detail/serene-chrome-extension/nbealbhmmmaiiiddmianmjoecmipnjij/',
  FAVICON_GRABBER: 'https://favicongrabber.com/api/grab',
  FIREFOX_EXTENSION:
    'https://addons.mozilla.org/en-GB/firefox/addon/serene-extension/',
  WEBHOOK_INFORMATION: 'https://sereneapp.com/category/tutorials',
  WEBHOOK_DO_NOT_DISTURB: 'https://sereneapp.com/webhook-do-not-disturb',
  WEBHOOK_LIGHTS: 'https://sereneapp.com/webhook-control-lights',
  WEBHOOK_SLACK: 'https://sereneapp.com/webhook-update-slack-status',
  SERENE_TEAMS: 'https://sereneapp.com/teams',
};
