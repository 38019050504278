import React from 'react';
import PropTypes from 'prop-types';

import { REVIEW_SESSION } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';

import DistractionItem from './DistractionItem.component';

import './Distractions.scss';

const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

class Distractions extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  state = {
    distractions: [],
    distractionText: '',
  };

  onRemoveDistraction = index => {
    this.setState(prevState => {
      log(
        INFO,
        `Removing distraction ${prevState.distractions[index]}`,
        REVIEW_SESSION,
      );

      const distractions = [
        ...prevState.distractions.filter((distraction, idx) => idx !== index),
      ];

      this.props.onChange(distractions);
      return {
        distractions,
      };
    });
  };

  handleDistractionTextChange = event => {
    this.setState({
      distractionText: event.target.value,
    });
  };

  handleKeyPress = event => {
    if (event.key !== 'Enter' || !this.state.distractionText) {
      return;
    }

    this.setState(prevState => {
      const distractionText = prevState.distractionText.replace(/ /g, '\u00a0');
      const distractions = [...prevState.distractions, distractionText];

      log(INFO, `Adding distraction ${distractionText}`, REVIEW_SESSION);

      this.props.onChange(distractions);
      return {
        distractions,
        distractionText: '',
      };
    });
  };

  render() {
    const { distractions, distractionText } = this.state;
    const isInputDisabled = distractions.length >= 5;

    return (
      <div className="Distractions">
        <div className="Distractions__items">
          {distractions.map((distraction, index) => (
            <DistractionItem
              key={index}
              index={index}
              distraction={distraction}
              onRemoveClick={this.onRemoveDistraction}
            />
          ))}
        </div>
        <input
          type="text"
          id="distraction"
          maxLength="50"
          className="Distractions__input"
          onChange={this.handleDistractionTextChange}
          value={distractionText}
          disabled={isInputDisabled}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

export default Distractions;
