import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import AssessingView from './AssessingView/AssessingView.component';
import BreakView from './BreakView/BreakView.component';

import Analytics from 'helpers/universal-analytics.helper';
import { diffDates } from 'helpers/date.helper';

import { LAB_GO_SERENE_VIEW } from 'constants/analytics';
import {
  CURRENT_SESSION,
  NAV_GO_SERENE,
  NAV_SESSIONS,
} from 'constants/routingPaths';
import {
  BREAK,
  DAY_FINISHED,
  REVIEW,
  SESSION,
  IDLE,
} from 'constants/sessionManagerStates';
import {
  IPC_SM_EDIT_SESSION,
  IPC_SM_END_SESSION,
  IPC_SM_GET_DATA,
  IPC_SM_SESSIONS_UPDATED,
  IPC_SM_STATE_CHANGED,
  IPC_SM_CHANGE_DAY,
  IPC_SM_DATE_CHANGED,
} from 'messages/ipc-messages';

const { ipcRenderer } = window.require('electron');

const findFinishedSession = sessions => {
  const finishedSessions = sessions
    .sort((sessionA, sessionB) => sessionA.number - sessionB.number)
    .filter(session => session.isFinished);

  return finishedSessions[finishedSessions.length - 1];
};

class GoSerene extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  constructor(props) {
    super(props);

    this.state = {
      sessionManagerState: IDLE,
      sessions: [],
      latestSession: [],
    };

    this.onSessionUpdated = this.onSessionUpdated.bind(this);
    this.onStateChanged = this.onStateChanged.bind(this);
  }

  componentDidMount() {
    ipcRenderer.on(IPC_SM_STATE_CHANGED, this.onStateChanged);
    ipcRenderer.on(IPC_SM_SESSIONS_UPDATED, this.onSessionUpdated);

    const { state, currentDayData, date } = ipcRenderer.sendSync(
      IPC_SM_GET_DATA,
    );

    const { isToday } = diffDates(date);

    if (isToday) {
      this.loadSessionData(currentDayData, state);
      return;
    }

    ipcRenderer.send(IPC_SM_CHANGE_DAY, { date: new Date() });
    ipcRenderer.on(IPC_SM_DATE_CHANGED, () => {
      const { state, currentDayData } = ipcRenderer.sendSync(IPC_SM_GET_DATA);

      this.loadSessionData(currentDayData, state);
    });
  }

  componentWillUnmount() {
    ipcRenderer.removeListener(IPC_SM_SESSIONS_UPDATED, this.onSessionUpdated);
    ipcRenderer.removeListener(IPC_SM_STATE_CHANGED, this.onStateChanged);
  }

  loadSessionData(currentDayData, state) {
    const { sessions } = currentDayData;
    const isDayFinished = sessions.every(session => !!session.isFinished);

    const finishedSessions = sessions.filter(session => session.isFinished);
    const latestSession = finishedSessions[finishedSessions.length - 1];

    Analytics.trackPageView(NAV_GO_SERENE, LAB_GO_SERENE_VIEW);

    this.setState({
      sessionManagerState: state,
      sessions,
      isDayFinished,
      latestSession,
    });
  }

  onSessionStateChange = sessionState => {
    const finishedSession = findFinishedSession(this.state.sessions);

    ipcRenderer.send(IPC_SM_EDIT_SESSION, {
      sessionId: finishedSession.number,
      state: sessionState,
    });
  };

  onButtonClick = distractions => {
    const finishedSession = findFinishedSession(this.state.sessions);

    ipcRenderer.send(IPC_SM_EDIT_SESSION, {
      sessionId: finishedSession.number,
      distractions,
    });

    // go to break view or end day view
    ipcRenderer.send(IPC_SM_END_SESSION);
  };

  onSessionUpdated = (event, data) => {
    const isDayFinished = data.every(session => !!session.isFinished);

    const finishedSessions = data.filter(session => session.isFinished);
    const latestSession = finishedSessions[finishedSessions.length - 1];

    this.setState({
      sessions: data,
      isDayFinished,
      latestSession,
    });
  };

  onStateChanged = (event, data) => {
    this.setState({
      sessionManagerState: data,
    });
  };

  render() {
    const {
      sessionManagerState,
      sessions,
      isDayFinished,
      latestSession,
    } = this.state;
    const finishedSession = findFinishedSession(sessions);
    const sessionState = finishedSession ? finishedSession.state : '';

    return (
      <div className="GoSerene">
        {sessionManagerState === DAY_FINISHED && <Redirect to={NAV_SESSIONS} />}
        {sessionManagerState === SESSION && <Redirect to={CURRENT_SESSION} />}
        {sessionManagerState === REVIEW && finishedSession && (
          <AssessingView
            isDayFinished={isDayFinished}
            sessions={sessions}
            onSessionStateChange={this.onSessionStateChange}
            onButtonClick={this.onButtonClick}
            sessionState={sessionState}
            latestSession={latestSession}
          />
        )}
        {sessionManagerState === BREAK && (
          <BreakView sessions={sessions} history={this.props.history} />
        )}
      </div>
    );
  }
}

export default GoSerene;
