import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal.component';
import Counter from 'components/Counter/Counter.component';
import MusicPlayer from 'components/MusicPlayer/MusicPlayer.component';
import ConditionalLink from 'components/Shared/ConditionalLink/ConditionalLink.component';
import Modal from 'components/Shared/Modal/Modal.component';
import Tooltip from 'components/Shared/Tooltip/Tooltip.component';
import {
  ACT_CLICK,
  CAT_GONE_SERENE,
  LAB_CURRENT_SESSION_VIEW,
  LAB_EXIT_SERENE_MODE,
} from 'constants/analytics';
import { CURRENT_SESSION_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { tooltipTexts, tooltipClasses } from 'constants/currentSessionTooltip';
import {
  CURRENT_SESSION,
  NAV_DASHBOARD,
  NAV_GO_SERENE,
  SHORTCUTS,
} from 'constants/routingPaths';
import { MIN_30 } from 'constants/sessionTimes';
import displayCombination from 'helpers/displayCombination.helper';
import Analytics from 'helpers/universal-analytics.helper';
import { ReactComponent as LogoApp } from 'images/logo-app.svg';
import { ReactComponent as EndSession } from 'images/end-session-icon.svg';
import { ReactComponent as EndSessionHover } from 'images/end-session-hover-icon.svg';
import { ReactComponent as RestartSession } from 'images/restart-session-icon.svg';
import { ReactComponent as RestartSessionHover } from 'images/restart-session-hover-icon.svg';
import { ReactComponent as PauseSession } from 'images/pause-session-icon.svg';
import { ReactComponent as StartSession } from 'images/start-session-icon.svg';
import { ReactComponent as PauseSessionHover } from 'images/pause-hover-icon.svg';
import { ReactComponent as StartSessionHover } from 'images/start-hover-icon.svg';
import {
  HOTKEYS,
  SESSION_TIME,
  IS_MUSIC_ACTIVE,
} from 'messages/available-settings';

import socket from '../../socket';

import './CurrentSession.scss';

const { ipcRenderer } = window.require('electron');
const isDev = window.require('electron-is-dev');
const { log } = window
  .require('electron')
  .remote.require('../src/helpers/Logger/logger.helper');

const {
  IPC_HOTKEY_GO_SERENE,
  IPC_STOP_SESSION,
  IPC_GET_SETTINGS,
  IPC_RESTART_SESSION,
  IPC_PAUSE_SESSION,
  IPC_UNPAUSE_SESSION,
  IPC_HOTKEY_START_OR_END_SESSION,
  IPC_SM_GET_DAY_DATA,
  IPC_GO_SERENE,
} = require('messages/ipc-messages');

const {
  SESSION_UPDATED,
  SESSION_STOPPED,
  SESSION_PAUSED,
  SESSION_UNPAUSED,
  SESSION_RESTARTED,
} = require('messages/messages');

const {
  END_SESSION_EARLY,
  RESTART_SESSION,
  PAUSE_SESSION,
  UNPAUSE_SESSION,
  MUTE_MUSIC,
  UNMUTE_MUSIC,
} = tooltipClasses;

const emptyModal = {
  isOpen: false,
  title: '',
  okTitle: '',
  type: '',
};

class CurrentSession extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  constructor(props) {
    super(props);

    this.musicPlayerComponent = React.createRef();

    this.state = {
      goal: '',
      session: null,
      defaultSessionDuration: MIN_30,
      currentTime: MIN_30,
      endSessionHotkey: [],
      tooltipType: null,
      sessionPaused: false,
      isMusicActive: null,
      shouldCloseModal: false,
      modal: emptyModal,
    };
  }

  componentDidMount() {
    const {
      hotkeys: { startOrEndSession },
      sessionTime: defaultSessionDuration,
      isMusicActive,
    } = ipcRenderer.sendSync(IPC_GET_SETTINGS, [
      SESSION_TIME,
      HOTKEYS,
      IS_MUSIC_ACTIVE,
    ]);
    const { goal, sessions } = ipcRenderer.sendSync(IPC_SM_GET_DAY_DATA);
    const startedSession = sessions.find(session => session.isStarted);
    const currentTime = startedSession
      ? startedSession.counterStartTime
      : defaultSessionDuration;

    this.setState({
      goal,
      session: startedSession,
      currentTime,
      defaultSessionDuration,
      endSessionHotkey: startOrEndSession,
      isMusicActive,
    });

    this.isSessionStoppedAlready = false;
    ipcRenderer.emit(IPC_GO_SERENE);

    Analytics.trackPageView(CURRENT_SESSION, LAB_CURRENT_SESSION_VIEW);

    socket.on(SESSION_UPDATED, data => {
      this.setState(({ shouldCloseModal, modal }) => ({
        currentTime: data,
        shouldCloseModal: shouldCloseModal && false,
        modal: shouldCloseModal ? emptyModal : modal,
      }));
    });

    socket.on(SESSION_STOPPED, () => {
      ipcRenderer.send(IPC_STOP_SESSION);
      ipcRenderer.emit(IPC_STOP_SESSION);

      this.isSessionStoppedAlready = true;
      if (this.state.session) {
        this.props.history.push(NAV_GO_SERENE);
        return;
      }

      this.props.history.push(NAV_DASHBOARD);
    });

    socket.on(SESSION_PAUSED, () => {
      const sessionPaused = true;
      this.setState({
        sessionPaused,
        tooltipType: null,
      });

      this.onSessionPaused(sessionPaused);
    });

    socket.on(SESSION_UNPAUSED, () => {
      const sessionPaused = false;
      this.setState({
        sessionPaused,
        tooltipType: null,
      });

      this.onSessionPaused(sessionPaused);
    });

    socket.on(SESSION_RESTARTED, () => {
      const sessionPaused = false;
      this.setState({
        shouldCloseModal: true,
        sessionPaused,
      });

      this.onSessionPaused(sessionPaused);
    });

    ipcRenderer.on(IPC_HOTKEY_START_OR_END_SESSION, () => {
      this.showConfirmationModal(END_SESSION_EARLY);
    });

    window.addEventListener('beforeunload', this.onWindowUnload);
  }

  componentWillUnmount() {
    this.onWindowUnload();
    window.removeEventListener('beforeunload', this.onWindowUnload);
  }

  onSessionPaused = sessionPaused => {
    this.musicPlayerComponent.current.toggleMusicOnSessionPause(sessionPaused);
  };

  showConfirmationModal = type => {
    let modal = {
      isOpen: true,
      type,
    };

    switch (type) {
      case END_SESSION_EARLY: {
        modal = {
          ...modal,
          title: 'Are you sure you want to end the session early?',
          okTitle: 'End session',
        };
        break;
      }
      case RESTART_SESSION: {
        modal = {
          ...modal,
          title: 'Are you sure you want to restart the session?',
          okTitle: 'Restart session',
        };
        break;
      }
      default:
    }

    log(INFO, `Show ${type} confirmation modal`, CURRENT_SESSION_VIEW);

    this.setState({ modal });
  };

  handleOkBtn = async () => {
    const { type, currentTime } = this.state.modal;

    switch (type) {
      case END_SESSION_EARLY:
        ipcRenderer.send(IPC_STOP_SESSION);
        ipcRenderer.emit(IPC_STOP_SESSION);
        this.isSessionStoppedAlready = true;

        Analytics.trackEvent(
          CAT_GONE_SERENE,
          ACT_CLICK,
          LAB_EXIT_SERENE_MODE,
          currentTime,
        );
        break;
      case RESTART_SESSION:
        ipcRenderer.send(IPC_RESTART_SESSION);
        break;
      default:
    }

    log(INFO, `Clicked ok on ${type} confirmation modal`, CURRENT_SESSION_VIEW);
  };

  handleCancelBtn = () => {
    log(
      INFO,
      `Clicked cancel on ${this.state.modal.type} confirmation modal`,
      CURRENT_SESSION_VIEW,
    );

    this.setState({
      modal: emptyModal,
    });
  };

  onWindowUnload = () => {
    if (!this.isSessionStoppedAlready) {
      ipcRenderer.send(IPC_STOP_SESSION);
      ipcRenderer.emit(IPC_STOP_SESSION);
    }

    ipcRenderer.removeAllListeners(IPC_HOTKEY_GO_SERENE);
    ipcRenderer.removeAllListeners(IPC_HOTKEY_START_OR_END_SESSION);
    socket.off(SESSION_UPDATED);
    socket.off(SESSION_STOPPED);
    socket.off(SESSION_PAUSED);
    socket.off(SESSION_UNPAUSED);
    socket.off(SESSION_RESTARTED);
  };

  onIconHover = type => {
    this.setState({ tooltipType: type });
  };

  hideTooltip = () => {
    this.setState({ tooltipType: null });
  };

  handlePauseSession = () => {
    const { sessionPaused } = this.state;
    ipcRenderer.send(sessionPaused ? IPC_UNPAUSE_SESSION : IPC_PAUSE_SESSION);
    log(
      INFO,
      `Clicked on ${sessionPaused ? 'unpause' : 'pause'} session button`,
      CURRENT_SESSION_VIEW,
    );
  };

  handleMusicToggle = () => {
    this.setState(prevState => ({
      isMusicActive: !prevState.isMusicActive,
      tooltipType: null,
    }));
  };

  render() {
    const {
      session,
      defaultSessionDuration,
      currentTime,
      endSessionHotkey,
      tooltipType,
      sessionPaused,
      isMusicActive,
      modal,
      shouldCloseModal,
    } = this.state;

    const { isOpen, title, okTitle } = modal;
    const { number, task, startingFrom, counterStartTime } = { ...session };

    const startTime = session ? counterStartTime : defaultSessionDuration;

    const header = session ? (
      <div className="main__header">
        <div className="main__session-number"> SESSION {number + 1} </div>
        <div className="main__title">{task}</div>
        <div className="main__session-starting">{startingFrom}</div>
      </div>
    ) : (
      <div className="main__title">All distractions have been blocked!</div>
    );

    const counterClass = classNames('main__counter', {
      'main__counter--session': !!session,
    });

    const startSessionIcon =
      tooltipType === UNPAUSE_SESSION ? (
        <StartSession />
      ) : (
        <StartSessionHover />
      );

    const pauseSessionIcon =
      tooltipType === PAUSE_SESSION ? <PauseSessionHover /> : <PauseSession />;

    return (
      <div className="CurrentSession">
        <div className="container">
          <div className="CurrentSession__header">
            <ConditionalLink to={SHORTCUTS} shouldRenderLink={isDev}>
              <LogoApp className="header__logo" />
            </ConditionalLink>
          </div>
          <div className="CurrentSession__main">
            {header}
            <div className={counterClass}>
              {startTime && (
                <Counter startTime={startTime} currentTime={currentTime} />
              )}

              <div className="main__counter-remaining">Remaining</div>
            </div>
          </div>
          <div className="CurrentSession__footer">
            <div className="footer__info">
              All your chosen apps and websites are currently being blocked.
            </div>
            <div
              className={classNames('footer__buttons', {
                'footer__buttons--higher': session && session.task.length > 80,
              })}
              onMouseLeave={this.hideTooltip}
            >
              <div
                className="buttons__btn"
                onMouseEnter={() => this.onIconHover(END_SESSION_EARLY)}
                onMouseLeave={this.hideTooltip}
                onClick={() => this.showConfirmationModal(END_SESSION_EARLY)}
              >
                {tooltipType === END_SESSION_EARLY ? (
                  <EndSessionHover />
                ) : (
                  <EndSession />
                )}
              </div>

              <div
                className="buttons__btn"
                onMouseEnter={() => this.onIconHover(RESTART_SESSION)}
                onMouseLeave={this.hideTooltip}
                onClick={() => this.showConfirmationModal(RESTART_SESSION)}
              >
                {tooltipType === RESTART_SESSION ? (
                  <RestartSessionHover />
                ) : (
                  <RestartSession />
                )}
              </div>

              <div
                className="buttons__btn"
                onMouseEnter={() =>
                  this.onIconHover(
                    sessionPaused ? UNPAUSE_SESSION : PAUSE_SESSION,
                  )
                }
                onMouseLeave={this.hideTooltip}
                onClick={this.handlePauseSession}
              >
                {sessionPaused ? startSessionIcon : pauseSessionIcon}
              </div>

              <div
                className={classNames('buttons__btn', {
                  'buttons__btn--session-paused': sessionPaused,
                })}
                onMouseEnter={() =>
                  !sessionPaused &&
                  this.onIconHover(isMusicActive ? MUTE_MUSIC : UNMUTE_MUSIC)
                }
                onMouseLeave={this.hideTooltip}
              >
                {isMusicActive !== null && (
                  <MusicPlayer
                    ref={this.musicPlayerComponent}
                    isMusicActive={isMusicActive}
                    tooltipType={tooltipType}
                    handleMusicToggle={this.handleMusicToggle}
                    sessionNumber={session && session.number + 1}
                  />
                )}
              </div>

              {!!tooltipType && (
                <Tooltip type={tooltipType}>
                  {tooltipTexts[tooltipType]}
                </Tooltip>
              )}
            </div>
            <div className="footer__shortcut">
              <p>{displayCombination(endSessionHotkey)}</p>
            </div>
          </div>

          <Modal open={isOpen} onCloseModal={this.handleCancelBtn}>
            <ConfirmationModal
              loading={shouldCloseModal}
              open={isOpen}
              title={title}
              okTitle={okTitle}
              okAction={this.handleOkBtn}
              cancelAction={this.handleCancelBtn}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default CurrentSession;
