import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { isEmail } from 'validator';

import { Button } from '@ventureharbour/serene-shared-components';

import InviteEmailList from 'components/InviteEmailList';

import ScreenTitle from 'components/ScreenTitle';
import ScreenSubTitle from 'components/ScreenSubTitle';
import Screen from 'components/Screen';

import { PATH_TEAMS, LAB_TEAM_INVITES_VIEW } from 'constants/analytics';
import { NAV_DASHBOARD } from 'constants/routingPaths';

import { inviteUsers } from 'helpers/firebase.helper';
import Analytics from 'helpers/universal-analytics.helper';

import { UserContext } from 'contexts/UserProvider';

import theme from 'style/theme';

const InviteTeam = ({
  history,
  showBackground = true,
  darkTheme = false,
  isScreen = false,
  ...props
}) => {
  const numberOfEmails = 3;

  const [emails, setEmails] = useState([
    ...new Array(numberOfEmails).fill().map(() => ({
      value: '',
      isValid: true,
    })),
  ]);

  const {
    state: { user: userState },
  } = useContext(UserContext);

  useEffect(() => {
    Analytics.trackPageView(PATH_TEAMS, LAB_TEAM_INVITES_VIEW);
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    const emailsList = emails
      .map(email => email.value)
      .filter(value => value !== '');

    const teamId = userState.team;

    inviteUsers(teamId, emailsList);

    if (history) {
      history.push({
        pathname: NAV_DASHBOARD, // CHANGE TO Invite Confirmation View when that's ready.
      });
    } else {
      console.error('no history, need another way of redirecting');

      setEmails([
        ...new Array(numberOfEmails).fill().map(() => ({
          value: '',
          isValid: true,
        })),
      ]);
    }
  };

  const handleAddedEmail = async () => {
    await setEmails([
      ...emails,
      {
        value: '',
        isValid: true,
      },
    ]);
  };

  const handleInputChange = (inputEmails, i) => {
    emails[i].isValid = isEmail(inputEmails[i].value);
    if (inputEmails[i].value === '') {
      emails[i].isValid = true;
    }

    setEmails([...emails]);
  };

  const checkIfCanSubmit = () => {
    const emailsListLength = emails
      .map(email => email.value)
      .filter(value => value !== '').length;

    const emailsInvalidLength = emails
      .map(email => email.isValid)
      .filter(isValid => isValid !== true).length;

    if (emailsListLength > 0 && emailsInvalidLength === 0) {
      return false;
    }

    return true;
  };

  const handleLaterLink = e => {
    e.preventDefault();

    history.push({
      pathname: NAV_DASHBOARD, // CHANGE TO Invite Confirmation View when that's ready.
    });
  };

  return (
    <Screen centered showShortcut={false} showBackground={showBackground}>
      <ScreenTitle darkTheme={darkTheme}>
        <span role="img" aria-label="email-inbox">
          📨
        </span>{' '}
        Invite your team
      </ScreenTitle>

      <ScreenSubTitle darkTheme={darkTheme}>
        Enter the emails of your first team members to get them using Serene
        today!
      </ScreenSubTitle>

      <form onSubmit={handleSubmit}>
        <div className="inviteEmailList">
          <InviteEmailList
            emails={emails}
            maxEmails={10}
            handleAddedEmail={handleAddedEmail}
            handleInputChange={handleInputChange}
            darkTheme={darkTheme}
            isScreen={isScreen}
          />
        </div>

        {isScreen && (
          <ScreenSubTitle>
            You can invite more once on the Team dashboard.
          </ScreenSubTitle>
        )}

        <Button
          name="Send Invites"
          className="btn--big"
          primary
          disabled={checkIfCanSubmit()}
        />

        {isScreen && (
          <a href="#" className="invite-later" onClick={handleLaterLink}>
            Invite the team later &gt;
          </a>
        )}
      </form>
      <style jsx>
        {`
          form {
            margin-top: 40px;
          }

          form :global(.Button) {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            width: 100%;
          }

          .inviteEmailList {
            width: 350px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
            margin-top: 2em;
          }

          .invite-later {
            color: ${theme.color.white};
            margin-top: 15px;
            display: inline-block;
          }

          form :global(.Button:disabled) {
            opacity: 0.6;

            ${darkTheme === true
              ? `background: linear-gradient(to right, #ffd782, #eb7070);`
              : ''}
          }
        `}
      </style>
      <style jsx global>
        {`
          .Screen {
            padding-top: 60px;
          }

          h1 {
            margin-bottom: 15px;
          }
        `}
      </style>
    </Screen>
  );
};

InviteTeam.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  showBackground: PropTypes.bool,
  darkTheme: PropTypes.bool,
  isScreen: PropTypes.bool,
};

export default InviteTeam;
