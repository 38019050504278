import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmail } from 'validator';
import classNames from 'classnames';

import { Button, InputText } from '@ventureharbour/serene-shared-components';

import { ReactComponent as CloseIcon } from 'images/close-icon-small.svg';

import Checkbox from 'components/Shared/Checkbox/Checkbox.component';
import ConditionalLink from 'components/Shared/ConditionalLink/ConditionalLink.component';
import Select from 'components/Shared/Input/InputSelect.component';
import Loader from 'components/Shared/Loader/Loader.component';
import Tooltip from 'components/Shared/Tooltip/Tooltip.component';
import {
  ACT_CLICK,
  ACT_SUBMIT_ONBOARDING_FORM,
  CAT_ONBOARDING,
  LAB_JOIN_MAILING_LIST,
  LAB_ONBOARDING_VIEW,
} from 'constants/analytics';
import LINKS from 'constants/links';
import { ONBOARDING_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { ONBOARDING, SET_DAY_GOAL, SHORTCUTS } from 'constants/routingPaths';
import segmentOptions from 'constants/segmentsOptions';
import Analytics from 'helpers/universal-analytics.helper';
import { ReactComponent as Logo } from 'images/serene-header-icon.svg';

import './Onboarding.scss';

import {
  saveOnboardingDataOnServer,
  saveOnboardingDataToSettings,
  saveServerStatusToSettings,
} from '../../helpers/saveOnboardingData.helper';
import SettingsAccountConfirmation from 'views/Navigation/Settings/SettingsAccount/SettingsAccountConfirmation/SettingsAccountConfirmation.component';

const { shell, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');
const isDev = window.require('electron-is-dev');

const openTermsLink = event => {
  event.preventDefault();
  shell.openExternal(LINKS.TERMS);
};

const openPrivacyLink = event => {
  event.preventDefault();
  shell.openExternal(LINKS.PRIVACY);
};

class Onboarding extends Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
  };

  state = {
    email: '',
    emailValid: false,
    isSavingData: false,
    segment: segmentOptions.defaultSegment.toLowerCase(),
    formSubmitted: false,
    termsAndConditions: false,
    weekly: false,
    isTooltipVisible: false,
    otherSegment: '',
    showEmailConfirm: false,
  };

  componentDidMount() {
    Analytics.trackPageView(ONBOARDING, LAB_ONBOARDING_VIEW);
  }

  toggleCheckbox = label => {
    if (label === 'weekly') {
      Analytics.trackEvent(
        CAT_ONBOARDING,
        ACT_CLICK,
        LAB_JOIN_MAILING_LIST,
        this.state.weekly ? 0 : 1,
      );
    }

    this.setState(prevState => ({ [label]: !prevState[label] }));
  };

  handleFormSubmit = async formSubmitEvent => {
    formSubmitEvent.preventDefault();

    this.setState({
      formSubmitted: true,
    });

    const {
      emailValid,
      email,
      otherSegment,
      segment,
      termsAndConditions,
      weekly,
    } = this.state;

    let onboardingSegment = segment;
    if (segment === 'other' && otherSegment !== '') {
      onboardingSegment = otherSegment;
    }

    if (termsAndConditions && emailValid) {
      try {
        this.setState({ isSavingData: true });

        Analytics.trackEvent(
          CAT_ONBOARDING,
          ACT_SUBMIT_ONBOARDING_FORM,
          onboardingSegment,
        );

        const dataSavedSuccessfully = await saveOnboardingDataOnServer(
          {
            email,
            segment,
            weekly,
          },
          {
            showFailureToast: true,
          },
        );

        await saveOnboardingDataToSettings({
          email,
          segment,
          weekly,
        });

        saveServerStatusToSettings(dataSavedSuccessfully);
        if (dataSavedSuccessfully === 'User-login') {
          this.setState({
            showEmailConfirm: true,
            isSavingData: false,
          });
        } else {
          log(INFO, `Submitted onboarding form for ${email}`, ONBOARDING_VIEW);

          this.props.history.push({
            pathname: SET_DAY_GOAL,
            state: { fromOnboarding: true },
          });
        }
      } catch (err) {
        this.setState({ isSavingData: false });
      }
    }
  };

  handleEmailInput = input => {
    const email = input.target.value;

    this.setState({
      email,
      emailValid: isEmail(email),
    });
  };

  handleOtherInput = input => {
    const otherSegment = input.target.value;

    this.setState({
      otherSegment,
    });
  };

  handleSelectChange = e => {
    const segment = e.target.value;
    this.setState(
      {
        segment,
      },
      () => {
        if (segment === 'other') {
          this.otherInputRef.focus();
        }
      },
    );
  };

  closeOtherInput = () => {
    this.setState(
      {
        segment: '',
      },
      () => {
        this.selectRef.focus();
      },
    );
  };

  showTooltip = () => {
    this.setState({ isTooltipVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipVisible: false });
  };

  successfullyConfirmed = () => {
    this.props.history.push({
      pathname: SET_DAY_GOAL,
      state: { fromOnboarding: true },
    });
  };

  confirmClosed = (success = false) => {
    if (success === true) {
      this.successfullyConfirmed();
    } else {
      this.setState({
        showEmailConfirm: false,
        isSavingData: false,
      });
    }
  };

  render() {
    const {
      emailValid,
      formSubmitted,
      termsAndConditions,
      weekly,
      isSavingData,
      isTooltipVisible,
      otherSegment,
      segment,
      showEmailConfirm,
      email,
    } = this.state;

    const tandCClass = classNames({
      invalid: termsAndConditions === false && formSubmitted === true,
    });

    const emailClass = classNames({
      invalid: emailValid === false && formSubmitted === true,
    });

    if (isSavingData) {
      return <Loader />;
    }

    return (
      <div className="onboarding">
        <SettingsAccountConfirmation
          onClose={this.confirmClosed}
          show={showEmailConfirm}
          user={{ email }}
        />
        <ConditionalLink to={SHORTCUTS} shouldRenderLink={isDev}>
          <Logo className="onboarding__logo" />
        </ConditionalLink>
        <h1 className="onboarding__title">Welcome to Serene</h1>
        <h2 className="onboarding__sub-title">Get Your Focus Back</h2>

        <form onSubmit={this.handleFormSubmit}>
          <div className="onboarding__choices">
            I&apos;m a{' '}
            <div className="onboarding__select-wrapper">
              <Select
                options={segmentOptions.segmentsList}
                className="onboarding__select"
                handleSelectChange={this.handleSelectChange}
                value={segment}
                innerRef={select => {
                  this.selectRef = select;
                }}
              />
              {this.state.segment === 'other' && (
                <React.Fragment>
                  <InputText
                    id="otherSegment"
                    placeholder="Profession"
                    onChange={this.handleOtherInput}
                    ref={input => {
                      this.otherInputRef = input;
                    }}
                    value={otherSegment}
                  />
                  <div
                    className="onboarding__close-other"
                    onClick={() => this.closeOtherInput()}
                  >
                    <CloseIcon />
                  </div>
                </React.Fragment>
              )}
            </div>{' '}
            and my <br /> email address is{' '}
            <InputText
              id="emailAddress"
              placeholder="me@email.com"
              onChange={this.handleEmailInput}
              className={emailClass}
              value={email}
            />
            <div
              className="onboarding__question"
              onMouseEnter={this.showTooltip}
              onMouseLeave={this.hideTooltip}
            >
              Why do we ask this?
            </div>
            <Tooltip isVisible={isTooltipVisible}>
              This helps us improve your Serene experience by suggesting
              websites and apps commonly blocked by users like you. We&apos;ll
              only use your email for essential communication about the app and
              your account — you won&apos;t get any marketing emails unless you
              opt in through the check-boxes below.
            </Tooltip>
          </div>

          <div className="onboarding__row onboarding__row-checkbox">
            <Checkbox
              id="weekly"
              handleCheckboxChange={this.toggleCheckbox}
              className=""
              checked={weekly}
            >
              I&rsquo;d like to receive Serene&rsquo;s latest research &amp;
              tips on increasing focus.
            </Checkbox>
          </div>

          <div className="onboarding__row onboarding__row-checkbox">
            <Checkbox
              id="termsAndConditions"
              required
              handleCheckboxChange={this.toggleCheckbox}
              className={tandCClass}
              checked={termsAndConditions}
            >
              I agree to the{' '}
              <span className="onboarding__row-link" onClick={openTermsLink}>
                Terms of Service
              </span>{' '}
              and{' '}
              <span className="onboarding__row-link" onClick={openPrivacyLink}>
                Privacy Policy
              </span>
            </Checkbox>
          </div>

          <div className="onboarding__row">
            <Button
              name="Get Started"
              className="btn--big onboarding__button"
              primary
            />
          </div>
        </form>
      </div>
    );
  }
}

export default Onboarding;
