import React from 'react';

import { Button } from '@ventureharbour/serene-shared-components';

import Modal from 'components/Shared/Modal/Modal.component';

const ctaLink = 'https://sereneapp.com/survey/';

const { shell } = window.require('electron');

const Reporting = () => {
  const handleModalClick = () => {
    shell.openExternal(ctaLink);
  };

  return (
    <div className="Reporting">
      <Modal open onCloseModal={false}>
        <div className="Reporting-modal">
          <h1>
            Get early access to Serene Reporting!
            <span aria-label="graph uptick" role="img">
              📈
            </span>
          </h1>

          <p>
            We&rsquo;d love to know what reports you&rsquo;d like to see -
            complete this short survey to get early access to Serene Reporting.
          </p>

          <Button
            onClick={handleModalClick}
            name="Start the survey (5 mins)"
            className="Reporting-cta"
            primary
            wide
          />
        </div>
      </Modal>
      <style jsx>
        {`
          .Reporting {
            min-height: 100vh;
            background-image: url('../../images/reporting-blur.png');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 95%;
          }

          h1 {
            font-size: 28px;
          }

          p {
            font-size: 22px;
            line-height: 26px;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
          }

          .Reporting-modal {
            padding: 53px 50px;
          }

          .Reporting :global(.Modal) {
            background-color: transparent;
            width: 50%;
            top: 25%;
            left: 20%;
            height: 50%;
          }

          .Reporting :global(.Modal__content) {
            min-width: 700px;
          }

          .Reporting :global(.Reporting-cta) {
            width: 90%;
            font-size: 20px;
          }
        `}
      </style>
    </div>
  );
};

export default Reporting;
