import React from 'react';
import PropTypes from 'prop-types';

import { secondsFormatter, secondsToMinutes } from 'helpers/time.helper';

import './Counter.scss';

const minCounter = 100;
const maxCounter = 257;

const svgPaths = [
  {
    d: 'M 0,-100 A 100,100 0 0,1 100,0',
    stroke: 'url(#grad_1)',
  },
  {
    d: 'M 100,0 A 100,100 0 0,1 0,100',
    stroke: 'url(#grad_2)',
  },
  {
    d: 'M 0,100 A 100,100 0 0,1 -100,0',
    stroke: 'url(#grad_3)',
  },
];

const setCounter = (prevState, timeInSeconds) => {
  if (timeInSeconds <= 0) {
    timeInSeconds = 0;
  }

  timeInSeconds = prevState.startTime - timeInSeconds;

  const svgPaths = prevState.svgPaths;
  const numberOfSvgPaths = svgPaths.length;
  svgPaths.forEach(path => (path.display = true));

  let currentSvgPath =
    Math.ceil(timeInSeconds / (prevState.startTime / numberOfSvgPaths)) - 1;

  for (let i = currentSvgPath + 1; i < numberOfSvgPaths; i++) {
    if (svgPaths[i]) {
      svgPaths[i].display = false;
    }
  }

  if (currentSvgPath < 0) {
    currentSvgPath = 0;
    return svgPaths;
  }

  const intervalSize = prevState.startTime / numberOfSvgPaths;
  const normalizedValue =
    timeInSeconds - (prevState.startTime / numberOfSvgPaths) * currentSvgPath;
  const percentage = normalizedValue / intervalSize;

  svgPaths[currentSvgPath].strokeDashoffset =
    maxCounter - Math.floor((maxCounter - minCounter) * percentage);

  return svgPaths;
};

class Counter extends React.Component {
  static propTypes = {
    startTime: PropTypes.number.isRequired,
    currentTime: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    svgPaths.forEach(path => {
      path.display = false;
      path.strokeDashoffset = 0;
    });

    this.state = {
      startTime: props.startTime,
      svgPaths,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let svgPaths = setCounter(prevState, nextProps.currentTime);

    if (nextProps.startTime !== prevState.startTime) {
      prevState.startTime = nextProps.startTime;
      svgPaths = setCounter(prevState, nextProps.startTime);
    }

    return {
      ...prevState,
      svgPaths,
    };
  }

  render() {
    const { currentTime } = this.props;
    const minutes = secondsToMinutes(currentTime, true, false);
    const seconds = secondsFormatter(currentTime);

    return (
      <div className="Counter">
        <svg
          className="svgCounter"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="60%"
          height="60%"
          viewBox="-120 -120 250 250"
        >
          <defs>
            <linearGradient
              id="grad_1"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop offset="0%" stopColor="#fdb17a" />
              <stop offset="100%" stopColor="#f39e79" />
            </linearGradient>
            <linearGradient
              id="grad_2"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="0%" stopColor="#f39e79" />
              <stop offset="100%" stopColor="#e47f77" />
            </linearGradient>
            <linearGradient
              id="grad_3"
              gradientUnits="objectBoundingBox"
              x1="1"
              y1="1"
              x2="0"
              y2="0"
            >
              <stop offset="0%" stopColor="#e47f77" />
              <stop offset="100%" stopColor="#cf5775" />
            </linearGradient>
          </defs>

          <circle
            className="svgCounter__background"
            fill="none"
            stroke="rgba(255, 255, 255, 0.78)"
            cx="0"
            cy="0"
            r="100"
            strokeLinecap="round"
            strokeWidth="22"
            strokeDashoffset="29"
            strokeDasharray="500"
          />

          <g fill="none" strokeWidth="15" strokeLinecap="round">
            {this.state.svgPaths.map((path, index) => (
              <path
                key={index}
                display={path.display ? 'block' : 'none'}
                d={path.d}
                stroke={path.stroke}
                strokeDashoffset={path.strokeDashoffset - 1}
                strokeDasharray={maxCounter}
              />
            ))}
          </g>
        </svg>
        <div className="Counter__time">{`${minutes}:${seconds}`}</div>
      </div>
    );
  }
}

export default Counter;
