import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const BadgePill = ({ text }) => (
  <div className={`Badge ${text ? 'show' : ''}`}>{text}</div>
);

BadgePill.propTypes = {
  text: PropTypes.string,
};

export default BadgePill;
