import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './ResizableTextArea.scss';

class ResizableTextArea extends React.PureComponent {
  static propTypes = {
    rows: PropTypes.number.isRequired,
    maxRows: PropTypes.number.isRequired,
    padding: PropTypes.number.isRequired,
    lineHeight: PropTypes.number.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    autofocus: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    transparent: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
    };
  }

  handleChange = ({ target }) => {
    const { maxRows, lineHeight, padding } = this.props;
    const previousRows = target.rows;
    const currentRows = Math.floor(
      (target.scrollHeight - padding) / lineHeight,
    );

    let rows;
    if (currentRows === previousRows || currentRows < maxRows) {
      rows = currentRows;
    } else if (currentRows >= maxRows) {
      rows = maxRows;
    }

    this.props.onValueChange(target.value);

    this.setState({
      rows,
    });
  };

  checkSubmit = e => {
    if (e.key === 'Enter') {
      if (!this.props.submitDisabled) {
        this.props.onSubmit(e.target.value);
      }
      e.preventDefault();
    }
  };

  render() {
    const { rows } = this.state;
    const {
      className,
      autofocus,
      placeholder,
      value,
      padding,
      lineHeight,
      transparent,
    } = this.props;

    const classNames = classnames('ResizableTextArea', className, {
      'ResizableTextArea--transparent': transparent,
    });

    return (
      <textarea
        className={classNames}
        style={{
          padding: `${padding}px`,
          lineHeight: `${lineHeight}px`,
        }}
        autoFocus={autofocus}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={this.handleChange}
        maxLength="500"
        onKeyPress={this.checkSubmit}
      />
    );
  }
}

export default ResizableTextArea;
