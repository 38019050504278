import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ventureharbour/serene-shared-components';

import LINKS from 'constants/links';

import './PlanCard.scss';

const { shell } = window.require('electron');

const TeamPlanCard = ({
  openCheckout,
  currency,
  monthlyCharge,
  isOnline,
  isHighlighted = false,
}) => {
  const handleMoreClick = e => {
    e.preventDefault();

    shell.openExternal(LINKS.SERENE_TEAMS);
  };

  const className = isHighlighted ? 'Card Card--highlighted' : 'Card';

  return (
    <div className={className}>
      <div className="Card__header Card__header-team" />
      <div className="Card__content">
        <h1 className="content__title">Team</h1>
        <div className="content__price">
          <span className="price__value">{monthlyCharge}</span>
          <div className="price__info">for Beta users</div>
        </div>
        <div className="content__benefits">
          <ul className="benefits__list">
            <li className="list__item">Invite all your team</li>
            <li className="list__item">Members all get PRO plans</li>
            <li className="list__item">
              Block mobile &amp; more{' '}
              <span>
                (
                <a href="#" onClick={handleMoreClick}>
                  more
                </a>
                )
              </span>
            </li>
            <li className="list__item">Plan tomorrow/week</li>
          </ul>
        </div>
        <div className="content__cta">
          <Button
            onClick={openCheckout}
            disabled={!isOnline}
            name="Upgrade to Team"
            primary
          />
        </div>
      </div>
    </div>
  );
};

TeamPlanCard.propTypes = {
  openCheckout: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  monthlyCharge: PropTypes.number.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool,
};

export default TeamPlanCard;
