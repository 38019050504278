import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import GraphPoint from './GraphPoint.component';

import { newSubarrayOfVisiblePoints } from 'helpers/Graph/visiblePointsLoader.helper';

import { IPC_SM_GET_DATA, IPC_SM_RELOAD_DAY } from 'messages/ipc-messages';

import './Graph.scss';

const { ipcRenderer } = window.require('electron');

const getFirstAndLastDayOfSelectedWeek = selectedDate => {
  let weekDay = selectedDate.day() - 1;
  weekDay = weekDay === -1 ? 6 : weekDay;

  const beginDate = selectedDate
    .clone()
    .subtract(weekDay, 'days')
    .format('DD/MM');

  const endDate = selectedDate
    .clone()
    .add(6 - weekDay, 'days')
    .format('DD/MM');

  return {
    beginDate,
    endDate,
  };
};

const Graph = ({ points, onPointClick }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [graphData, setGraphData] = useState([]);
  const [translate, setTranslate] = useState(0);
  const [prevButtonActive, setPrevButtonActive] = useState(true);
  const [nextButtonActive, setNextButtonActive] = useState(true);

  useEffect(() => {
    const setDate = () => {
      const { date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
      setSelectedDate(moment(date));
    };

    ipcRenderer.on(IPC_SM_RELOAD_DAY, setDate);

    return () => {
      ipcRenderer.removeListener(IPC_SM_RELOAD_DAY, setDate);
    };
  }, []);

  useEffect(() => {
    const currentDayPointIndex = points.findIndex(
      point =>
        moment(point.date)
          .startOf('day')
          .diff(selectedDate, 'days') === 0,
    );

    const newArray = newSubarrayOfVisiblePoints(currentDayPointIndex, points);
    setTranslate(0);
    setGraphData(newArray);
  }, [selectedDate, points]);

  useEffect(() => {
    const firstDate = moment(points[0].date);
    const lastDate = moment(points[points.length - 1].date);

    const hasMoreFuturePoints = lastDate.diff(selectedDate, 'days') < 7;
    const hasMorePastPoints = selectedDate.diff(firstDate, 'days') < 14;

    setPrevButtonActive(!hasMorePastPoints);
    setNextButtonActive(!hasMoreFuturePoints);
  }, [selectedDate, points]);

  const selectNextWeek = useCallback(() => {
    if (!nextButtonActive) {
      return;
    }

    setTranslate(1);
    setTimeout(() => {
      setSelectedDate(selectedDate.clone().add(7, 'days'));
    }, 400);
  }, [nextButtonActive, selectedDate]);

  const selectPrevWeek = useCallback(() => {
    if (!prevButtonActive) {
      return;
    }

    setTranslate(-1);
    setTimeout(() => {
      setSelectedDate(selectedDate.clone().subtract(7, 'days'));
    }, 400);
  }, [prevButtonActive, selectedDate]);

  const { beginDate, endDate } = getFirstAndLastDayOfSelectedWeek(selectedDate);

  const leftArrowClasses = classnames('Graph__arrow', 'Graph__arrow--left', {
    'Graph__arrow--disabled': !prevButtonActive,
  });

  const rightArrowClasses = classnames('Graph__arrow', 'Graph__arrow--right', {
    'Graph__arrow--disabled': !nextButtonActive,
  });

  return (
    <div className="Graph">
      <div className="Graph__dates">
        {beginDate} - {endDate}
      </div>
      <div className={leftArrowClasses} onClick={selectPrevWeek} />
      <div className="Graph__points">
        {graphData.map((point, index) => (
          <GraphPoint
            key={point.date}
            onClick={onPointClick}
            index={index}
            translate={translate}
            {...point}
          />
        ))}
      </div>
      <div className={rightArrowClasses} onClick={selectNextWeek} />
    </div>
  );
};

Graph.propTypes = {
  onPointClick: PropTypes.func,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      achievedGoal: PropTypes.string.isRequired,
      isToday: PropTypes.bool,
      dayState: PropTypes.string,
    }),
  ).isRequired,
};

export default Graph;
