import { toast } from 'components/Shared/Toast/Toast.component';
import { createAccount } from 'helpers/firebase.helper';
import {
  APP_OPENS_AT_LOGIN,
  IS_DATA_SENT_AFTER_FIRST_VISIT,
  IS_FIRST_VISIT_DONE,
  USER_EMAIL,
  USER_SEGMENT,
  USER_WEEKLY,
} from 'messages/available-settings';
import {
  IPC_CHANGE_SETTINGS,
  IPC_GET_SETTINGS,
  IPC_SAVE_DEFAULT_BLOCKED_APPS_AND_WEBSITES,
} from 'messages/ipc-messages';

const { ipcRenderer } = window.require('electron');

export const saveOnboardingDataOnServer = async (
  { email, segment, weekly },
  { showSuccessToast, showFailureToast },
) => {
  try {
    await createAccount(email);

    if (showSuccessToast) {
      toast({
        title: `Yay! 🏆`,
        content: `We've saved the onboarding data on the server`,
      });
    }

    return true;
  } catch (error) {
    if (showFailureToast && error.code) {
      toast({
        title: `We've got you covered 👍`,
        content: `We were unable to save data on the server, but no worries! We'll send them next time`,
      });
    }

    return error.message;
  }
};

export const saveOnboardingDataToSettings = async ({
  email,
  segment,
  weekly,
}) => {
  await ipcRenderer.send(IPC_CHANGE_SETTINGS, [
    {
      name: IS_FIRST_VISIT_DONE,
      value: true,
    },
    {
      name: USER_EMAIL,
      value: email,
    },
    {
      name: USER_SEGMENT,
      value: segment,
    },
    {
      name: USER_WEEKLY,
      value: weekly,
    },
    {
      name: APP_OPENS_AT_LOGIN,
      value: true,
    },
  ]);

  await ipcRenderer.send(IPC_SAVE_DEFAULT_BLOCKED_APPS_AND_WEBSITES);
};

export const checkIfUserDataSavedOnServer = () => {
  const isDataSavedOnServer = ipcRenderer.sendSync(
    IPC_GET_SETTINGS,
    IS_DATA_SENT_AFTER_FIRST_VISIT,
  );
  return isDataSavedOnServer;
};

export const saveServerStatusToSettings = async dataSavedSuccessfully => {
  await ipcRenderer.send(IPC_CHANGE_SETTINGS, [
    {
      name: IS_DATA_SENT_AFTER_FIRST_VISIT,
      value: dataSavedSuccessfully,
    },
  ]);
};
