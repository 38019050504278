import React from 'react';
import PropTypes from 'prop-types';

import { Button, InputText } from '@ventureharbour/serene-shared-components';

import GoSereneButton from 'components/Shared/GoSereneButton/GoSereneButton.Component';
import SessionList from 'components/Shared/SessionList/SessionList.component';
import TimeDropdown from 'components/Shared/TimeDropdown/TimeDropdown.component';
import TodayGoal from 'components/Shared/TodayGoal/TodayGoal.component';

import {
  ACT_CLICK,
  ACT_SAVE_SESSION,
  CAT_ADD_SESSION,
  CAT_SESSION_LIST,
  LAB_ADDED_SESSION,
  LAB_ADD_SESSION_VIEW,
  LAB_EDITED_SESSION,
  LAB_EDIT_SESSION_VIEW,
  LAB_ENTER_SERENE,
  LAB_SESSION_TASK,
  PATH_ADD_SESSION,
  PATH_EDIT_SESSION,
} from 'constants/analytics';
import { PLAN_SESSIONS_VIEW } from 'constants/loggerGroups';
import { INFO } from 'constants/logingLevels';
import { BREAK, REVIEW } from 'constants/sessionManagerStates';
import Analytics from 'helpers/universal-analytics.helper';
import { diffDates } from 'helpers/date.helper';
import { ReactComponent as EditBlackIcon } from 'images/edit-black-icon.svg';
import { IPC_SM_ADD_SESSION, IPC_SM_GET_DATA } from 'messages/ipc-messages';

import './AddSessionView.scss';

const { ipcRenderer, remote } = window.require('electron');
const { log } = remote.require('../src/helpers/Logger/logger.helper');

class AddSessionView extends React.Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    sessionToEdit: PropTypes.shape({
      number: PropTypes.number.isRequired,
      startingFrom: PropTypes.string.isRequired,
      counterStartTime: PropTypes.number.isRequired,
      task: PropTypes.string.isRequired,
    }),
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        counterStartTime: PropTypes.number.isRequired,
        number: PropTypes.number.isRequired,
        state: PropTypes.string.isRequired,
        task: PropTypes.string.isRequired,
        startingFrom: PropTypes.string,
        isActive: PropTypes.bool,
        isFinished: PropTypes.bool,
      }).isRequired,
    ).isRequired,
    saveEditedSession: PropTypes.func.isRequired,
    removeSession: PropTypes.func.isRequired,
    reindexSession: PropTypes.func.isRequired,
    maxSessions: PropTypes.number.isRequired,
    defaultSessionDuration: PropTypes.number.isRequired,
  };

  state = {
    sessionNumber: 1,
    task: '',
    startingFrom: '',
    counterStartTime: this.props.defaultSessionDuration,
    editMode: false,
  };

  componentDidMount() {
    const { sessionToEdit } = this.props;
    if (sessionToEdit) {
      this.startEditingSession(sessionToEdit);
      Analytics.trackPageView(PATH_EDIT_SESSION, LAB_EDIT_SESSION_VIEW);
    } else {
      Analytics.trackPageView(PATH_ADD_SESSION, LAB_ADD_SESSION_VIEW);
    }
    this.taskInput.focus();
  }

  saveSession = () => {
    const {
      task,
      startingFrom,
      counterStartTime,
      sessionNumber,
      editMode,
    } = this.state;
    const { saveEditedSession } = this.props;

    if (this.isAddingSessionDisabled()) {
      return;
    }

    const session = {
      task,
      startingFrom,
      counterStartTime,
    };

    if (editMode) {
      Analytics.trackEvent(
        CAT_SESSION_LIST,
        ACT_SAVE_SESSION,
        LAB_EDITED_SESSION,
        session,
      );
      saveEditedSession({
        sessionId: sessionNumber,
        ...session,
      });
    } else {
      Analytics.trackEvent(
        CAT_SESSION_LIST,
        ACT_SAVE_SESSION,
        LAB_ADDED_SESSION,
        session,
      );
      ipcRenderer.send(IPC_SM_ADD_SESSION, session);
    }

    log(INFO, `Save session ${JSON.stringify(session)}`, PLAN_SESSIONS_VIEW);

    Analytics.trackEvent(
      CAT_ADD_SESSION,
      ACT_SAVE_SESSION,
      LAB_SESSION_TASK,
      task,
    );

    this.stopEditingSession();

    // re-focus on the taskInput
    this.taskInput.focus();
  };

  stopEditingSession = () => {
    this.setState({
      task: '',
      startingFrom: '',
      counterStartTime: this.props.defaultSessionDuration,
      editMode: false,
    });
  };

  startEditingSession = ({
    number: sessionNumber,
    startingFrom,
    counterStartTime,
    task,
  }) => {
    this.setState({
      editMode: true,
      sessionNumber,
      startingFrom,
      counterStartTime,
      task,
    });
    this.taskInput.focus();
  };

  onRemoveSession = sessionId => {
    this.setState({
      editMode: false,
      task: '',
      startingFrom: '',
      counterStartTime: this.props.defaultSessionDuration,
    });
    this.taskInput.focus();
    this.props.removeSession(sessionId);
  };

  onReindexSession = (targetNumber, element) => {
    this.props.reindexSession(targetNumber, element);
  };

  handleTaskTextChange = e => {
    this.setState({
      task: e.target.value,
    });
  };

  handleStartedTextChange = e => {
    this.setState({
      startingFrom: e.target.value,
    });
  };

  handleTimeUpdate = counterStartTime => {
    this.setState({
      counterStartTime,
    });
  };

  handleKeyPress = e => {
    if (e.key !== 'Enter') {
      return;
    }
    this.saveSession();
  };

  isAddingSessionDisabled = () =>
    !this.state.task ||
    (this.props.sessions.length >= this.props.maxSessions &&
      !this.state.editMode);

  render() {
    const {
      task,
      editMode,
      sessionNumber,
      counterStartTime,
      startingFrom,
    } = this.state;

    const { state, date } = ipcRenderer.sendSync(IPC_SM_GET_DATA);
    const { sessions } = this.props;
    const { isFutureDay } = diffDates(date);

    const isFirstSession = sessions.length === 0;

    const formTitle = editMode
      ? `Edit session ${sessionNumber + 1}`
      : `Session #${sessions.length + 1}`;

    const taskLabel = isFirstSession
      ? 'In this session I will...'
      : 'My task is to';
    const startedByLabel = isFirstSession
      ? `I'm going to start by...`
      : 'I will get started by';
    const taskPlaceholder = isFirstSession
      ? 'Finish designing the new check out process'
      : 'Finish designing the My Account page';
    const startedByPlaceholder =
      'Reviewing the feedback from the team on previous designs';

    const btnName = editMode ? 'Save changes' : 'Add another session';
    const isButtonVisible =
      sessions.length > 0 &&
      !isFutureDay &&
      (state !== REVIEW && state !== BREAK);

    return (
      <div className="SessionsOverview SessionsOverview--add">
        <div className="SessionsOverview__menu">
          <div className="SessionsOverview__menu-top">
            <div className="SessionsOverview__menu-day-plan">
              <TodayGoal isEditable />
            </div>
            <h3>PLANNED SESSIONS</h3>
          </div>
          <SessionList
            sessions={sessions}
            onRemoveSession={this.onRemoveSession}
            onReindexSession={this.onReindexSession}
            onEditSession={this.startEditingSession}
            onStopEditingSession={this.stopEditingSession}
          />
        </div>
        <div className="SessionsOverview__content">
          <div className="AddSessionView">
            {isFirstSession && (
              <div className="AddSessionView__tip">
                <div className="AddSessionView__tip-heading">
                  Awesome{' '}
                  <span aria-label="hands-up" role="img">
                    🙌
                  </span>
                </div>
                <p>
                  Now break your goal into clearly-defined (and manageable)
                  focus sessions. We&apos;ll prompt you to take breaks in
                  between to keep you on your best form.
                </p>
              </div>
            )}
            <h1 className="AddView-title">
              {editMode && <EditBlackIcon className="edit-black" />}
              {formTitle}
              <TimeDropdown
                default={counterStartTime}
                handleTimeUpdate={this.handleTimeUpdate}
              />
            </h1>

            <label htmlFor="task">{taskLabel}</label>

            <InputText
              type="text"
              id="task"
              placeholder={taskPlaceholder}
              maxLength={500}
              onChange={this.handleTaskTextChange}
              onKeyPress={this.handleKeyPress}
              value={task}
              ref={input => {
                this.taskInput = input;
              }}
              wide
            />

            <label htmlFor="startedBy">{startedByLabel}</label>
            <InputText
              type="text"
              id="startedBy"
              placeholder={startedByPlaceholder}
              maxLength={500}
              onChange={this.handleStartedTextChange}
              value={startingFrom}
              onKeyPress={this.handleKeyPress}
              wide
            />

            <Button
              disabled={this.isAddingSessionDisabled()}
              onClick={this.saveSession}
              name={btnName}
              primary
              wider
            />

            {isButtonVisible && (
              <div className="AddView__footer">
                <div>Finished planning and want to get cracking?</div>
                <GoSereneButton
                  showIcon
                  startDay
                  buttonText="Go Serene"
                  analyticsEvent={[
                    CAT_ADD_SESSION,
                    ACT_CLICK,
                    LAB_ENTER_SERENE,
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddSessionView;
