import React from 'react';
import PropTypes from 'prop-types';

import './PlanCard.scss';

const FreePlanCard = ({ currency, isHighlighted = false }) => {
  const className = isHighlighted ? 'Card Card--highlighted' : 'Card';

  return (
    <div className={className}>
      <div className="Card__header Card__header-basic" />
      <div className="Card__content">
        <h1 className="content__title">Lite</h1>
        <div className="content__price">
          <span className="price__currency">{currency}</span>
          <span className="price__value">0</span>
          <span className="price__period"> /mo</span>
        </div>
        <div className="content__benefits">
          <ul className="benefits__list">
            <li className="list__item">3 x blocked websites</li>
            <li className="list__item">3 x blocked apps</li>
            <li className="list__item">Simple daily planning</li>
            <li className="list__item">Deep working music</li>
          </ul>
        </div>
        <div className="content__cta">
          <div className="cta__current">Current plan</div>
        </div>
      </div>
    </div>
  );
};

FreePlanCard.propTypes = {
  currency: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
};

export default FreePlanCard;
