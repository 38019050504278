import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Toggle } from '@ventureharbour/serene-shared-components';

import BlockApps from 'components/Blockapps/Blockapps.component';

import { ONBOARDING_BLOCK_SITES } from 'constants/routingPaths';
import { REOPEN_BLOCKED_APPS } from 'messages/available-settings';
import { IPC_GET_SETTINGS, IPC_CHANGE_SETTINGS } from 'messages/ipc-messages';

import './OnboardingBlockApps.scss';

const { ipcRenderer } = window.require('electron');

const OnboardingBlockApps = props => {
  const [reopenBlockedApps, setReopenBlockedApps] = useState(false);

  useEffect(() => {
    const reopenBlockedApps = ipcRenderer.sendSync(
      IPC_GET_SETTINGS,
      REOPEN_BLOCKED_APPS,
    );

    setReopenBlockedApps(reopenBlockedApps);
  }, []);

  const handleToggle = value => {
    setReopenBlockedApps(value);

    ipcRenderer.send(IPC_CHANGE_SETTINGS, {
      name: REOPEN_BLOCKED_APPS,
      value,
    });
  };

  const nextStep = () => {
    props.history.push(ONBOARDING_BLOCK_SITES);
  };

  return (
    <div className="OnboardingBlockApps">
      <div className="container">
        <h1 className="OnboardingBlockApps__title">
          What apps should be blocked when you Go Serene?
        </h1>
        <div className="OnboardingBlockApps__caption">
          Customise the list by adding any other apps that distract you when
          you&apos;re trying to focus
        </div>
        <BlockApps />
        <div className="OnboardingBlockApps__toggle-wrapper">
          <Toggle small active={reopenBlockedApps} onChange={handleToggle}>
            Re-open apps at the end of a session
          </Toggle>
        </div>
        <Button
          onClick={nextStep}
          name="Continue"
          className="OnboardingBlockApps__btn"
          primary
        />
      </div>
    </div>
  );
};

OnboardingBlockApps.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default OnboardingBlockApps;
