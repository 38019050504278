import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

const SettingsNavItem = ({ caption, link, pathname }) => {
  const isActive = classNames('Settings__nav-item', {
    'Settings__nav-item--active': link === pathname,
  });

  return (
    <Link to={link} className={isActive}>
      <p className="Settings__nav-item-caption">{caption}</p>
    </Link>
  );
};

SettingsNavItem.propTypes = {
  caption: PropTypes.string,
  link: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

export default SettingsNavItem;
