import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ProPlanCard from './ProPlanCard.component';
import FreePlanCard from './FreePlanCard.component';

import {
  IPC_GET_SETTINGS,
  IPC_OPEN_PADDLE_CHECKOUT,
  IPC_SETTING_CHANGED,
} from 'messages/ipc-messages';

import { USER_CURRENCY, IS_ONLINE } from 'messages/available-settings';

import './UpgradePlan.scss';

const { PADDLE_CURRENCY, PADDLE_MONTHLY_PRO_RATE } = require('../../config');

const { ipcRenderer } = window.require('electron');

const UpgradePlan = ({ onCloseModal }) => {
  const [currency, setCurrency] = useState(PADDLE_CURRENCY);
  const [isOnline, setOnline] = useState(true);

  const handleKeyDown = useCallback(
    event => {
      const { key } = event;

      if (key === 'Escape') {
        onCloseModal && onCloseModal();
      }
    },
    [onCloseModal],
  );

  useEffect(() => {
    ipcRenderer.on(IPC_SETTING_CHANGED, handleSettingChange);

    const isOnline = ipcRenderer.sendSync(IPC_GET_SETTINGS, IS_ONLINE);

    setOnline(isOnline);
    return () => {
      ipcRenderer.removeListener(IPC_SETTING_CHANGED, handleSettingChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const countryCode = ipcRenderer.sendSync(IPC_GET_SETTINGS, USER_CURRENCY);
    if (countryCode !== '') {
      setCurrency(countryCode);
    }
  }, []);

  const handleSettingChange = (event, { setting, newValue }) => {
    if (setting === IS_ONLINE) {
      setOnline(newValue);
    }
  };

  const onCheckoutClicked = () => {
    ipcRenderer.send(IPC_OPEN_PADDLE_CHECKOUT);
  };

  return (
    <div className="UpgradePlan">
      <div className="container">
        <FreePlanCard currency={currency} />
        <ProPlanCard
          openCheckout={onCheckoutClicked}
          currency={currency}
          monthlyCharge={PADDLE_MONTHLY_PRO_RATE}
          isOnline={isOnline}
        />
      </div>
      {onCloseModal && (
        <div className="UpgradePlan__skip">
          <span onClick={onCloseModal}>Not just yet</span>
        </div>
      )}
    </div>
  );
};

UpgradePlan.propTypes = {
  onCloseModal: PropTypes.func,
};

export default UpgradePlan;
