export default {
  color: {
    bodyColor: '#484848',
    coralSerene: '#ff6763',
    white: '#fff',
    placeholderWhite: 'rgba(255, 255, 255, 0.6)',
    errorRed: '#d0021b',
    bgGradientDark: '#eb7070',
    bgGradientLight: '#ffd782',
  },
  borderRadii: {
    medium: '8px',
  },
  fontSize: {
    large: '50px',
    subTitle: '20px',
  },
};
